.w-lightbox-item, hr {
    box-sizing: content-box
}

legend, td, th {
    padding: 0
}

.w-clearfix:after, .w-container:after, .w-embed:after, .w-radio:after, .w-row:after, .w-slider {
    clear: both
}

.ci_faq, .nav_stickymenu {
    -webkit-align-content: flex-start;
    align-content:flex-start;
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%
}

body {
    margin: 0;
    min-height: 100%;
    background-color: #fff;
    overflow-x: hidden;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden], template {
    display: none
}

a {
    background-color: transparent
}

a:active, a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b, optgroup, strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    margin: .67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block
}

svg:not(:root) {
    overflow: hidden
}

hr {
    height: 0
}

pre, textarea {
    overflow: auto
}

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button, select {
    text-transform: none
}

button[disabled], html input[disabled] {
    cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: none
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

legend {
    border: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

@font-face {
    font-family: webflow-icons;
    src: url("data:application/x-font-ttf;charset=utf-8;base64,") format('truetype');
    font-weight: 400;
    font-style: normal
}

[class*=" w-icon-"], [class^=w-icon-] {
    font-family: webflow-icons !important;
    
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.w-icon-slider-right:before {
    content: "\e600"
}

.w-icon-slider-left:before {
    content: "\e601"
}

.w-icon-nav-menu:before {
    content: "\e602"
}

.w-icon-arrow-down:before, .w-icon-dropdown-toggle:before {
    content: "\e603"
}

.w-icon-file-upload-remove:before {
    content: "\e900"
}

.w-icon-file-upload-icon:before {
    content: "\e903"
}

.w-clearfix:after, .w-clearfix:before, .w-embed:after, .w-embed:before, .w-radio:after, .w-radio:before {
    content: " ";
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html.w-mod-touch * {
    background-attachment: scroll !important
}

.w-block {
    display: block
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.w-clearfix:after, .w-clearfix:before {
    display: table
}

.w-hidden {
    display: none
}

.w-button {
    display: inline-block;
    padding: 15px 15px;
    background-color: #3898EC;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0
}

input.w-button {
    -webkit-appearance: button
}

html[data-w-dynpage] [data-w-cloak] {
    color: transparent !important
}

.w-webflow-badge, .w-webflow-badge * {
    position: static;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
    display: block;
    visibility: visible;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
    float: none;
    clear: none;
    border: 0 transparent;
    border-radius: 0;
    background: 0 0;
    background-size: auto auto;
    background-origin: padding-box;
    background-clip: border-box;
    box-shadow: none;
    opacity: 1;
    transform: none;
    transition: none;
    direction: ltr;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    list-style-type: disc;
    text-shadow: none;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal
}

.w-webflow-badge, .w-webflow-badge > img {
    display: inline-block !important;
    visibility: visible !important;
    opacity: 1 !important
}

.w-form-done, .w-slider, .w-widget-map .gm-style-iw, .w-widget-twitter-count-shim, figcaption {
    text-align: center
}

.w-webflow-badge {
    position: fixed !important;
    z-index: 2147483647 !important;
    top: auto !important;
    right: 12px !important;
    bottom: 12px !important;
    left: auto !important;
    color: #AAADB0 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-decoration: none !important;
    transform: none !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
    cursor: pointer
}

blockquote, figure {
    margin: 0 0 10px
}

ol, p, ul {
    margin-top: 0
}

.w-background-video, .w-file-upload-input, .w-slider-mask, .w-widget-gplus, .w-widget-twitter {
    overflow: hidden
}

.w-webflow-badge > img {
    vertical-align: middle !important
}

h6 {
    margin-top: 10px
}

blockquote {
    padding: 10px 20px;
    border-left: 5px solid #E2E2E2;
    font-size: 18px;
    line-height: 22px
}

figcaption {
    margin-top: 5px
}

ol, ul {
    margin-bottom: 10px;
    padding-left: 40px
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none
}

.w-embed:after, .w-embed:before {
    display: table
}

.w-video {
    width: 100%;
    position: relative;
    padding: 0
}

.w-video embed, .w-video iframe, .w-video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

button, html input[type=button], input[type=reset] {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

.w-form {
    margin: 0 0 15px
}

.w-form-done {
    display: none;
    padding: 20px;
    background-color: #ddd
}

.w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 1rem;
    color: #595d64;
}

.w-input, .w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    
    background-color: #fff;
    border: 1px solid #ccc
}

.w-select[multiple], textarea.w-input, textarea.w-select {
    height: auto
}

.w-input:-moz-placeholder, .w-select:-moz-placeholder {
    color: #999
}

.w-input::-moz-placeholder, .w-select::-moz-placeholder {
    color: #999;
    opacity: 1
}

.w-input:-ms-input-placeholder, .w-select:-ms-input-placeholder {
    color: #999
}

.w-input::-webkit-input-placeholder, .w-select::-webkit-input-placeholder {
    color: #999
}

.w-input:focus, .w-select:focus {
    border-color: #3898EC;
    outline: 0
}

.w-input[disabled], .w-input[readonly], .w-select[disabled], .w-select[readonly], fieldset[disabled] .w-input, fieldset[disabled] .w-select {
    cursor: not-allowed;
    background-color: #eee
}

.w-select {
    background-color: #f3f3f3
}

.w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0
}

.w-radio {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-radio:after, .w-radio:before {
    display: table
}

.w-radio-input {
    margin: 3px 0 0 -20px;
    margin-top: 1px \9;
    line-height: normal;
    float: left
}

.w-file-upload {
    display: block;
    margin-bottom: 10px
}

.w-file-upload-input {
    width: .1px;
    height: .1px;
    opacity: 0;
    position: absolute;
    z-index: -100
}

.w-file-upload-default, .w-file-upload-success, .w-file-upload-uploading {
    display: inline-block;
    color: #333
}

.w-file-upload-error {
    display: block;
    margin-top: 10px
}

.w-file-upload-default.w-hidden, .w-file-upload-error.w-hidden, .w-file-upload-success.w-hidden, .w-file-upload-uploading.w-hidden {
    display: none
}

.w-file-upload-file, .w-file-upload-uploading-btn {
    display: flex;
    margin: 0;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-file-upload-uploading-btn {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    padding: 8px 12px
}

.w-file-upload-file {
    flex-grow: 1;
    justify-content: space-between;
    padding: 8px 9px 8px 11px
}

.w-file-upload-file-name {
    font-size: 14px;
    font-weight: 400;
    display: block
}

.w-file-remove-link {
    margin-top: 3px;
    margin-left: 10px;
    width: auto;
    height: auto;
    padding: 3px;
    display: block;
    cursor: pointer
}

.w-icon-file-upload-remove {
    margin: auto;
    font-size: 10px
}

.w-file-upload-error-msg {
    display: inline-block;
    color: #ea384c;
    padding: 2px 0
}

.w-file-upload-info {
    display: inline-block;
    line-height: 38px;
    padding: 0 12px
}

.w-file-upload-label {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-icon-file-upload-icon, .w-icon-file-upload-uploading {
    display: inline-block;
    margin-right: 8px;
    width: 20px
}

.w-container:after, .w-container:before, .w-row:after, .w-row:before {
    display: table;
    content: " ";
    grid-column-end: 2;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-row-start: 1
}

.w-icon-file-upload-uploading {
    height: 20px
}

.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0
}

.w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.w-col .w-col {
    padding-left: 0;
    padding-right: 0
}

.w-col-1 {
    width: 8.33333333%
}

.w-col-2 {
    width: 16.66666667%
}

.w-col-3 {
    width: 25%
}

.w-col-4 {
    width: 33.33333333%
}

.w-col-5 {
    width: 41.66666667%
}

.w-col-6 {
    width: 50%
}

.w-col-7 {
    width: 58.33333333%
}

.w-col-8 {
    width: 66.66666667%
}

.w-col-9 {
    width: 75%
}

.w-col-10 {
    width: 83.33333333%
}

.w-col-11 {
    width: 91.66666667%
}

.w-col-12 {
    width: 100%
}

.w-hidden-main {
    display: none !important
}

@media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px
    }


    .w-hidden-main {
        display: inherit !important
    }

    .w-hidden-medium {
        display: none !important
    }

    .w-col-medium-1 {
        width: 8.33333333%
    }

    .w-col-medium-2 {
        width: 16.66666667%
    }

    .w-col-medium-3 {
        width: 25%
    }

    .w-col-medium-4 {
        width: 33.33333333%
    }

    .w-col-medium-5 {
        width: 41.66666667%
    }

    .w-col-medium-6 {
        width: 50%
    }

    .w-col-medium-7 {
        width: 58.33333333%
    }

    .w-col-medium-8 {
        width: 66.66666667%
    }

    .w-col-medium-9 {
        width: 75%
    }

    .w-col-medium-10 {
        width: 83.33333333%
    }

    .w-col-medium-11 {
        width: 91.66666667%
    }

    .w-col-medium-12 {
        width: 100%
    }

    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto
    }
}

@media screen and (max-width: 767px) {
    .w-hidden-main, .w-hidden-medium {
        display: inherit !important
    }

    .w-hidden-small {
        display: none !important
    }

    .w-container .w-row, .w-row {
        margin-left: 0;
        margin-right: 0
    }

    .w-col {
        width: 100%;
        left: auto;
        right: auto
    }

    .w-col-small-1 {
        width: 8.33333333%
    }

    .w-col-small-2 {
        width: 16.66666667%
    }

    .w-col-small-3 {
        width: 25%
    }

    .w-col-small-4 {
        width: 33.33333333%
    }

    .w-col-small-5 {
        width: 41.66666667%
    }

    .w-col-small-6 {
        width: 50%
    }

    .w-col-small-7 {
        width: 58.33333333%
    }

    .w-col-small-8 {
        width: 66.66666667%
    }

    .w-col-small-9 {
        width: 75%
    }

    .w-col-small-10 {
        width: 83.33333333%
    }

    .w-col-small-11 {
        width: 91.66666667%
    }

    .w-col-small-12 {
        width: 100%
    }
}

@media screen and (max-width: 479px) {
    .w-container {
        max-width: none
    }

    .w-hidden-main, .w-hidden-medium, .w-hidden-small {
        display: inherit !important
    }

    .w-hidden-tiny {
        display: none !important
    }

    .w-col {
        width: 100%
    }

    .w-col-tiny-1 {
        width: 8.33333333%
    }

    .w-col-tiny-2 {
        width: 16.66666667%
    }

    .w-col-tiny-3 {
        width: 25%
    }

    .w-col-tiny-4 {
        width: 33.33333333%
    }

    .w-col-tiny-5 {
        width: 41.66666667%
    }

    .w-col-tiny-6 {
        width: 50%
    }

    .w-col-tiny-7 {
        width: 58.33333333%
    }

    .w-col-tiny-8 {
        width: 66.66666667%
    }

    .w-col-tiny-9 {
        width: 75%
    }

    .w-col-tiny-10 {
        width: 83.33333333%
    }

    .w-col-tiny-11 {
        width: 91.66666667%
    }

    .w-col-tiny-12 {
        width: 100%
    }
}

.w-widget {
    position: relative
}

.w-widget-map {
    width: 100%;
    height: 400px
}

.w-widget-map label {
    width: auto;
    display: inline
}

.w-widget-map img {
    max-width: inherit
}

.w-widget-map .gm-style-iw > button {
    display: none !important
}

.w-widget-twitter-count-shim {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 28px;
    height: 20px;
    background: #fff;
    border: 1px solid #758696;
    border-radius: 3px
}

.w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-dropdown-toggle, .w-slider-arrow-left, .w-slider-arrow-right {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    position: relative;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    color: #999;
    font-family: serif
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    position: relative;
    display: block
}

.w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px
}

.w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px
}

.w-widget-twitter-count-shim:not(.w--vertical):after, .w-widget-twitter-count-shim:not(.w--vertical):before {
    top: 50%;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
    border-color: rgba(117, 134, 150, 0);
    border-right-color: #5d6c7b;
    border-width: 4px;
    margin-left: -9px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-left: -10px;
    margin-top: -5px
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 4px;
    margin-left: -8px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-left: -9px;
    margin-top: -5px
}

.w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px
}

.w-widget-twitter-count-shim.w--vertical:after, .w-widget-twitter-count-shim.w--vertical:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim.w--vertical:before {
    border-color: rgba(117, 134, 150, 0);
    border-top-color: #5d6c7b;
    border-width: 5px;
    margin-left: -5px
}

.w-widget-twitter-count-shim.w--vertical:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px
}

.w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px
}

.w-background-video {
    position: relative;
    height: 500px;
    color: #fff
}

.w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100
}

.w-background-video > video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none
}

.w-slider {
    position: relative;
    height: 300px;
    background: #ddd;
    -webkit-tap-highlight-color: transparent;
   
}

.w-slide, .w-slider-mask {
    position: relative;
    height: 100%
}

.w-slider-mask {
    display: block;
    z-index: 1;
    left: 0;
    right: 0;
    white-space: nowrap
}

.w-dropdown, .w-dropdown-toggle, .w-slide, .w-slider-dot {
    display: inline-block
}

.w-slide {
    vertical-align: top;
    width: 100%;
    white-space: normal;
    text-align: left
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
   
}

.w-slider-nav.w-round > div {
    border-radius: 100%
}

.w-slider-nav.w-num > div {
    width: auto;
    height: auto;
    padding: .2em .5em;
    font-size: inherit;
    line-height: inherit
}

.w-slider-nav.w-shadow > div {
    box-shadow: 0 0 3px rgba(51, 51, 51, .4)
}

.w-slider-nav-invert {
    color: #fff
}

.w-slider-nav-invert > div {
    background-color: rgba(34, 34, 34, .4)
}

.w-slider-nav-invert > div.w-active {
    background-color: #222
}

.w-slider-dot {
    position: relative;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, .4);
    cursor: pointer;
    margin: 0 3px .5em;
    transition: background-color .1s, color .1s
}

.w-slider-dot.w-active {
    background-color: #fff
}

.w-slider-arrow-left, .w-slider-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    color: #fff;
    font-size: 40px;
    -webkit-tap-highlight-color: transparent;
    
    user-select: none
}

.w-slider-arrow-left [class*=" w-icon-"], .w-slider-arrow-left [class^=w-icon-], .w-slider-arrow-right [class*=" w-icon-"], .w-slider-arrow-right [class^=w-icon-] {
    position: absolute
}

.w-dropdown, .w-dropdown-btn, .w-dropdown-link, .w-dropdown-toggle {
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-right: auto
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto
}

.w-icon-slider-left, .w-icon-slider-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1em;
    height: 1em
}

.w-dropdown {
    z-index: 900
}

.w-dropdown-btn, .w-dropdown-link, .w-dropdown-toggle {
    vertical-align: top;
    text-decoration: none;
    color: #222;
    padding: 20px;
    white-space: nowrap
}

.w-dropdown-toggle {
    user-select: none;
    padding-right: 40px
}

.w-lightbox-backdrop, .w-nav-button {
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w-icon-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 20px auto auto;
    width: 1em;
    height: 1em
}

.w-dropdown-list {
    position: absolute;
    background: #ddd;
    display: none;
    min-width: 100%
}

.w-dropdown-list.w--open {
    display: block
}

.w-dropdown-link {
    padding: 10px 20px;
    display: block;
    color: #222
}

.w-dropdown-link.w--current {
    color: #0082f3
}

@media screen and (max-width: 767px) {
    .w-nav-brand {
        padding-left: 10px
    }
}

.w-lightbox-backdrop {
    cursor: auto;
    font-style: normal;
    font-variant: normal;
    letter-spacing: normal;
    list-style: disc;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    font-family: "Helvetica Neue", Helvetica, Ubuntu, "Segoe UI", Verdana, sans-serif;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    background: rgba(0, 0, 0, .9);
    z-index: 2000;
    outline: 0;
    opacity: 0;
  
}

.w-lightbox-caption, .w-lightbox-strip {
    bottom: 0;
    right: 0;
    white-space: nowrap;
    left: 0
}

.w-lightbox-backdrop, .w-lightbox-container {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.w-lightbox-content {
    position: relative;
    height: 100vh;
    overflow: hidden
}

.w-lightbox-view {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0
}

.w-lightbox-view:before {
    content: "";
    height: 100vh
}

.w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
    height: 86vh
}

.w-lightbox-frame, .w-lightbox-view:before {
    display: inline-block;
    vertical-align: middle
}

.w-nav-link, .w-tab-link {
    vertical-align: top;
    text-decoration: none
}

.w-lightbox-figure {
    position: relative;
    margin: 0
}

.w-lightbox-group .w-lightbox-figure {
    cursor: pointer
}

.w-lightbox-img {
    width: auto;
    height: auto;
    max-width: none
}

.w-lightbox-image {
    display: block;
    float: none;
    max-width: 100vw;
    max-height: 100vh
}

.w-lightbox-group .w-lightbox-image {
    max-height: 86vh
}

.w-lightbox-caption {
    position: absolute;
    padding: .5em 1em;
    background: rgba(0, 0, 0, .4);
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden
}

.w-lightbox-embed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-lightbox-control {
    position: absolute;
    top: 0;
    width: 4em;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s
}

.w-lightbox-left {
    display: none;
    bottom: 0;
    left: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==)
}

.w-lightbox-right {
    display: none;
    right: 0;
    bottom: 0;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+)
}

.w-lightbox-close {
    right: 0;
    height: 2.6em;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=);
    background-size: 18px
}

.w-lightbox-strip {
    position: absolute;
    padding: 0 1vh;
    line-height: 0;
    overflow-x: auto;
    overflow-y: hidden
}

.w-lightbox-noscroll, .w-richtext ol, .w-richtext ul {
    overflow: hidden
}

.w-lightbox-item {
    display: inline-block;
    width: 10vh;
    padding: 2vh 1vh;
    cursor: pointer;
   /* -webkit-transform: translate3d(0, 0, 0)*/
}

.w-lightbox-active {
    opacity: .3
}

.w-lightbox-thumbnail {
    position: relative;
    height: 10vh;
    background: #222;
    overflow: hidden
}

.w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0
}

.w-lightbox-thumbnail .w-lightbox-tall {
    top: 50%;
    width: 100%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.w-lightbox-thumbnail .w-lightbox-wide {
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.w-lightbox-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border: 5px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
    -webkit-animation: spin .8s infinite linear;
    animation: spin .8s infinite linear
}

.w-richtext figure.w-richtext-align-center, .w-richtext figure.w-richtext-align-fullwidth {
    clear: both;
    margin-right: auto;
    margin-left: auto
}

.w-lightbox-spinner:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 3px solid transparent;
    border-bottom-color: #fff;
    border-radius: 50%
}

.w-lightbox-hide {
    display: none
}

@media (min-width: 768px) {
    .w-lightbox-content {
        height: 96vh;
        margin-top: 2vh
    }

    .w-lightbox-view, .w-lightbox-view:before {
        height: 96vh
    }

    .w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
        height: 84vh
    }

    .w-lightbox-image {
        max-width: 96vw;
        max-height: 96vh
    }

    .w-lightbox-group .w-lightbox-image {
        max-width: 82.3vw;
        max-height: 84vh
    }

    .w-lightbox-left, .w-lightbox-right {
        display: block;
        opacity: .5
    }

    .w-lightbox-close {
        opacity: .8
    }

    .w-lightbox-control:hover {
        opacity: 1
    }
}

.w-lightbox-inactive, .w-lightbox-inactive:hover {
    opacity: 0
}

.w-richtext:after, .w-richtext:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-richtext:after {
    clear: both
}

.w-richtext[contenteditable=true]:after, .w-richtext[contenteditable=true]:before {
    white-space: initial
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div, .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after, .w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after, .w-richtext .w-richtext-figure-selected[data-rt-type=image] div {
    outline: #2895f7 solid 2px
}

.w-richtext figure.w-richtext-figure-type-video > div:after, .w-richtext figure[data-rt-type=video] > div:after {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.w-nav:after, .w-nav:before, .w-tabs:after, .w-tabs:before {
    content: " ";
    grid-column-end: 2;
    grid-row-end: 2
}

.w-richtext figure {
    position: relative;
    max-width: 60%
}

.w-richtext figure > div:before {
    cursor: default !important
}

.w-richtext figure img {
    width: 100%
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6
}

.tl_footernavigation:hover, .tl_nav_link:hover {
    opacity: 1;
}

.w-richtext figure div {
    font-size: 0;
    color: transparent
}

.w-richtext figure.w-richtext-figure-type-image, .w-richtext figure[data-rt-type=image] {
    display: table
}

.w-richtext figure.w-richtext-figure-type-image > div, .w-richtext figure[data-rt-type=image] > div {
    display: inline-block
}

.w-richtext figure.w-richtext-figure-type-image > figcaption, .w-richtext figure[data-rt-type=image] > figcaption {
    display: table-caption;
    caption-side: bottom
}

.w-richtext figure.w-richtext-figure-type-video, .w-richtext figure[data-rt-type=video] {
    width: 60%;
    height: 0
}

.w-richtext figure.w-richtext-figure-type-video iframe, .w-richtext figure[data-rt-type=video] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-nav, .w-nav-brand, .w-nav-link, .w-nav-menu {
    position: relative
}

.w-richtext figure.w-richtext-figure-type-video > div, .w-richtext figure[data-rt-type=video] > div {
    width: 100%
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image > div, .w-richtext figure.w-richtext-align-center[data-rt-type=image] > div {
    max-width: 100%
}

.w-richtext figure.w-richtext-align-normal {
    clear: both
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    display: block
}

.w-richtext figure.w-richtext-align-fullwidth > div {
    display: inline-block;
    padding-bottom: inherit
}

.w-richtext figure.w-richtext-align-fullwidth > figcaption {
    display: block
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    margin-right: 15px;
    clear: none
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    margin-left: 15px;
    clear: none
}

.w-nav:after, .w-tabs:after {
    clear: both
}

.w-nav {
    background: #ddd;
    z-index: 1000
}

.w-nav:after, .w-nav:before {
    display: table;
    grid-column-start: 1;
    grid-row-start: 1
}

.w-nav-brand {
    float: left;
    text-decoration: none;
    color: #333
}

.w-nav-link {
    display: inline-block;
    color: #222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto
}

.w-nav-link.w--current {
    color: #0082f3
}

.w-nav-menu {
    float: right
}

[data-nav-menu-open] {
    display: block !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #C8C8C8;
    text-align: center;
    overflow: visible;
    min-width: 200px
}

.w--nav-link-open {
    display: block;
    position: relative
}

.w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%
}

.w-nav[data-animation=over-left] .w-nav-overlay, .w-nav[data-animation=over-right] .w-nav-overlay {
    width: auto
}

.w-nav-overlay [data-nav-menu-open] {
    top: 0
}

.w-nav[data-animation=over-left] .w-nav-overlay, .w-nav[data-animation=over-left] [data-nav-menu-open] {
    right: auto;
    z-index: 1;
    top: 0
}

.w-nav[data-animation=over-right] .w-nav-overlay, .w-nav[data-animation=over-right] [data-nav-menu-open] {
    left: auto;
    z-index: 1;
    top: 0
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
   
    user-select: none
}

.w-nav-button.w--open {
    background-color: #C8C8C8;
    color: #fff
}

.w-nav[data-collapse=all] .w-nav-menu {
    display: none
}

.w--nav-dropdown-open, .w--nav-dropdown-toggle-open, .w-nav[data-collapse=all] .w-nav-button {
    display: block
}

.w--nav-dropdown-list-open {
    position: static
}

.w-tab-content, .w-tab-link, .w-tab-menu, .w-tab-pane, .w-tabs {
    position: relative
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse=medium] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=medium] .w-nav-button {
        display: block
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse=small] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=small] .w-nav-button {
        display: block
    }

    .w-nav-brand {
        padding-left: 10px
    }
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse=tiny] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=tiny] .w-nav-button {
        display: block
    }
}

.w-checkbox::after, .w-checkbox::before, .w-tabs:after, .w-tabs:before {
    display: table;
    grid-column-start: 1;
    grid-row-start: 1
}

.w-tab-link {
    display: inline-block;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd
}

.w-tab-link.w--current {
    background-color: #C8C8C8
}

.w-tab-content {
    display: block;
    overflow: hidden
}

.w-tab-pane {
    display: none
}

.w--tab-active {
    display: block
}

@media screen and (max-width: 479px) {
    .w-tab-link {
        display: block
    }
}

.w-ix-emptyfix:after {
    content: ""
}

.w-checkbox::after, .w-checkbox::before {
    content: ' ';
    -ms-grid-column-span: 1;
    -ms-grid-column: 1;
    grid-column-end: 2;
    grid-row-end: 2;
    grid-column: 1;
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

.w-dyn-empty {
    padding: 10px;
    background-color: #ddd
}

.w-condition-invisible, .w-dyn-bind-empty, .w-dyn-hide {
    display: none !important
}

.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}

.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-checkbox::before {
    -ms-grid-row-span: 1;
    -ms-grid-row: 1;
    grid-row: 1;
}

.w-checkbox::after {
    -ms-grid-row-span: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    clear: both
}

.c_page, .w-pagination-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

#w-node-1119ec05be74-abc16c15, #w-node-2e93488f68e0-abc16c15, #w-node-b0292e66ad53-5cb00601, #w-node-f9a4d6293485-3a368f7a {
    -ms-grid-column: span 2;
    grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-74d4ee9bc855-5cb00601, #w-node-907d6837e851-3a368f7a, #w-node-f9a4d6293485-3a368f7a {
    grid-row-end: span 1;
    -ms-grid-row: span 1;
    grid-row: span 1;
    grid-row-start: span 1
}

#w-node-0b2fe3b960fd-1417f64f, #w-node-787161e3b336-5cb00601, #w-node-8226eb520370-3a368f7a, #w-node-90ea46874bf2-c530f919, #w-node-aa8a9b8400eb-c530f919, #w-node-aa8a9b8400ed-c530f919, #w-node-fbaab485af98-c530f919 {
    -ms-grid-row: span 2;
    grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column: span 1;
    grid-column-start: span 1
}

.w-checkbox-input {
    float: left;
    line-height: normal;
    margin: 4px 0 0 -20px
}

h1, h2, li {
    line-height: 120%
}

h1, h2, h3 {
    margin-top: 0
}

h3, h5 {
    margin-bottom: 15px
}

h1, h2, p {
    margin-bottom: 30px;
}

.w-checkbox-input--inputType-custom {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #ccc;
    border-style: solid;
    border-radius: 2px
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    border-color: #3898ec
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

.w-pagination-wrapper {
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.w-pagination-next, .w-pagination-previous {
    display: block;
    color: #333;
    margin-left: 10px;
    background-color: #fafafa;
    padding: 9px 20px;
    border-radius: 2px;
    border-width: 1px;
    border-color: #ccc;
    border-style: solid;
    font-size: 14px
}

.w-pagination-previous {
    margin-right: 10px
}

.w-pagination-previous-icon {
    margin-right: 4px
}

.w-pagination-next {
    margin-right: 10px
}

a, body, h1 {
    color: #000
}

.w-pagination-next-icon {
    margin-left: 4px
}

.c_page, .n_mainnavbar {
    position: relative;
    margin-right: auto;
    margin-left: auto
}



.w-embed-youtubevideo:empty {
    min-height: 75px;
    padding-bottom: 56.25%
}

.w-form-formradioinput--inputType-custom {
    width: 12px;
    height: 12px;
    border-width: 1px;
    border-color: #ccc;
    border-style: solid;
    border-radius: 50%
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
    border-width: 4px;
    border-color: #3898ec
}

body {
    font-family: Flama, sans-serif;
    font-size: 14px;
    line-height: 142%
}

h1 {
    font-size: 4em;
    font-weight: 500
}

h2 {
    font-size: 3em;
    font-weight: 500
}

h3, h4 {
    line-height: 140%;
    font-weight: 400
}

h3 {
    font-size: 2em
}

h4 {
    max-width: 600px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 1.5em
}

h5, h6 {
    font-weight: 500;
    line-height: 140%
}

h5 {
    margin-top: 0;
    font-size: 1.1em;
}

h6 {
    margin-bottom: 10px;
    font-size: .8em
}

li, p {
    font-weight: 300
}

p {
    font-size: 1.1em;
    line-height: 140%;
    text-align: justify;
}

a {
    text-decoration: none;
    font-weight: 400;
}

li {
    /* padding-top: 10px; */
    padding-bottom: 0px;
    font-size: 1em;
}

.c_inner.cc_placeholderheight, .c_map.cc_placeholderheight, .c_page.cc_placeholderheight {
    padding-bottom: 500px
}

.c_page {
    display: flex;
    width: 100%;
    max-width: 1440px;
    padding-right: 40px;
    padding-left: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    font-weight: 500
}

.c_page.cc-contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0;
    padding-right: 0;
    padding-left: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.c_page.cc-healthyarch, .w_nav {
    -webkit-box-orient: horizontal
}

.c_page.cc-contact.cc_indv_case_study {
    position: relative
}

.c_page.cc-contact.cc_hidden {
    display: none
}

.c_page.cc-space {
    margin-top: 10%
}

.c_page.cc-highz {
    z-index: 9
}

.c_page.cc-healthyarch {
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
}

.c_page.cc-generic {
    max-width: none;
    padding-right: 0;
    padding-left: 0
}

.n_mainnavbar {
    z-index: 999;
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: 1px solid #e1e1e1
}

.w_nav {
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.s_alternated_cards, .w_alternatedgroup.cc_goup6 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_navleftsidelinks {
    /* margin-left: 2.5vw */
    margin: auto;
}

.tl_nav_link {
    position: static;
    margin-right: 12px;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: 15px;
    line-height: 25px;
    font-weight: 300;
}

.tl_nav_link.w--current {
    color: #000;
    font-weight: 500
}

.tl_nav_link.cc_smallermargin {
    margin-right: 34px
}

.tl_nav_link.cc_smallermargin.cc_navmediulink {
    margin-right: 13px;
    font-weight: 500;
}

.s_residential_hero {
    display: none;
    width: 100%;
    margin-top: 13.9%;
    margin-right: 0;
    margin-left: auto;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_homehero {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_homeleft {
    z-index: 10;
    max-width: 58%
}

.tb_herobigheading {
    z-index: 10;
    font-size: 5.5em;
    line-height: 105%;
    font-weight: 600;
    white-space: normal
}

.tb_herobigheading.cc-residential {
    max-width: 1200px
}

.p_heroparagraph {
    margin-top: 22px;
    margin-bottom: 20px;
    font-size: 1.7em;
    line-height: 120%;
    font-weight: 300
}

.p_heroparagraph.cc-homeherop {
    max-width: 526px;
    margin-top: 0
}

.p_heroparagraph.cc-mbspace {
    margin-bottom: 50px
}

.s_alternated_cards {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 10%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.s_alternated_cards.cc-install {
    margin-top: 5%
}

.w_residentialmiddletoptext {
    max-width: 800px;
    margin-right: auto;
    margin-left: 0;
    text-align: left
}

.p_homemiddletopp {
    margin-top: 11px;
    color: #333;
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 400
}

.tb_sideways_text {
    direction: rtl;
    font-size: 1.857em;
    line-height: 153%;
    font-weight: 600;
    white-space: nowrap
}

.p_alternatinggroups, .p_quality {
    font-weight: 300
}

.sp_beige_text {
    color: #d0bbb3
}
.sp_blue_text {
    color: #d0bbb3
}

.w_alternatingscards {
    width: 100%
}

.w_alternatingscards.cc_commecial_page {
    padding-right: 10.95%;
    padding-left: 10%
}

.d_connectorline {
    position: relative;
    z-index: 2;
    display: none;
    width: 22.5%;
    height: 1px;
    margin-top: 52px;
    margin-left: -80px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #3b5266
}

.d_connectorline.cc_group2 {
    width: 1px;
    height: 262px;
    margin-top: -238px;
    margin-bottom: 4.4%;
    margin-left: 36.1%
}

.d_connectorline.cc_group3 {
    margin-top: 42px;
    margin-right: 21px;
    margin-left: -20.8%
}

.d_connectorline.cc_group4 {
    margin-top: 8.4%;
    margin-right: -16.2%;
    margin-left: 15px
}

.d_connectorline.cc_group5 {
    margin-top: 16.7%;
    margin-right: 21px;
    margin-left: -16.6%
}

.d_connectorline.cc_group6 {
    width: 1px;
    height: 0;
    margin-top: -13.8%;
    margin-left: 40.5%;
    padding-bottom: 22.5%
}

.w_alternatingcardstext {
    width: 36%;
    max-width: 360px;
    margin-top: 43px;
    margin-left: 5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_alternatingcardstext.cc_group2 {
    width: 100%;
    margin-top: 8%;
    margin-right: 5%;
    margin-left: auto
}

.w_alternatingcardstext.cc_group3 {
    width: 32.8%;
    max-width: 381px;
    margin-top: 33px;
    margin-left: 5%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_alternatingcardstext.cc_group4 {
    width: 42.7%;
    max-width: 496px;
    margin-top: 7.7%;
    margin-right: 5%;
    margin-left: 9.9%
}

.w_alternatingcardstext.cc_group5 {
    width: 31.03%;
    margin-top: 11%;
    margin-right: 8.6%;
    margin-left: 5%
}

.w_alternatingcardstext.cc_group6 {
    width: 44.2%;
    max-width: 513px;
    margin-top: 5%;
    margin-left: 40.5%
}

.p_alternatinggroups.cc_max_width_360p {
    overflow: visible;
    max-width: 360px
}

.lb_alternatingcards {
    position: relative;
    display: inline-block;
    margin-top: 14px;
    margin-left: -17px;
    padding: 11px 20px 12px
}

.w_alternatingimagegroup {
    position: relative;
    overflow: hidden
}

.w_alternatingimagegroup.cc_group4 {
    max-width: 400px;
    margin-right: 8.6%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_alternatingimagegroup.cc_group5 {
    margin-left: 8.6%
}

.w_alternatingimagegroup.cc_group6 {
    max-width: 84.7%
}

.w_alternatedgroup {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 20%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_alternatedgroup.cc_group4 {
    margin-top: 20%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.w_alternatedgroup.cc_group5 {
    margin-top: 20%
}

.w_alternatedgroup.cc_goup6 {
    margin-top: 16.12%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.w_alternatedgroup.cc_group1 {
    margin-top: 0;
    margin-bottom: 20%;
    padding-left: 16.32%
}

.w_alternatedgroup.cc_group2 {
    margin-top: 0;
    margin-left: 18%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
}

.s_qualities, .w_quality {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.g_qualities {
    display: -ms-grid;
    display: grid;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    justify-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    grid-auto-flow: row;
    grid-auto-columns: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.d_quality_rotation, .w_content_accoustic_div, .w_footercontent {
    grid-auto-columns: 1fr;
    grid-column-gap: 16px
}

.w_quality {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    text-align: left
}

.d_vertical_line {
    width: 1px;
    height: 294px;
    margin-top: 21.5px;
    background-color: #000
}

.d_vertical_line.cc_line6 {
    display: none;
    height: 123px
}

.d_quality_rotation, .s_qualities {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.h3_quality_name {
    white-space: nowrap
}

.d_quality_rotation {
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    background-color: #fff
}

.p_quality {
    font-size: 1.071em;
    line-height: 133%
}

.s_footercta {
    display: block;
    overflow: hidden;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-top: 1px solid #e1e1e1
}

.s_qualities {
    display: flex;
    margin-top: 5%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.btn {
   /* margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 36.5px;
    border: 1px solid #000;
    background-color: #fff;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: #000;
    font-size: 1em;
    line-height: 147%;
    font-weight: 600;
    text-align: center*/
}

.btn:hover {
   /* background-color: #000;
    color: #fff*/
}

.btn.cc_faq_cta {
    min-width: 57%;
    margin-top: 25px;
    text-transform: capitalize
}

.btn.cc_panel_inst_cta {
    width: 100%;
    max-width: 272px
}

.btn.cc_btn_prod_i_tabs_2nd {
    margin-left: 0;
    padding-right: 30.5px;
    padding-left: 30.5px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2.5px;
    background-color: #1c5291;
    color: #fff
}

.btn.cc_btn_prod_i_tabs_2nd:hover {
    border-style: solid;
    border-color: #1c5291;
    background-color: #fff;
    color: #304659
}

.btn.cc_tech_data_bottom {
    padding: 8px 18px 7px
}

.btn.cc_products_btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 30px;
    margin-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row
}

.btn.cc_indv_prod_success_form {
    background-color: transparent
}

.btn.cc_indv_prod_success_form:hover {
    background-color: #000
}

.btn.cc_pagination {
    margin-right: 15px;
    margin-left: 15px
}

.btn.cc_go_back_portoflio {
    padding: 5px 15.5px;
    border-left-width: 0
}

.btn.cc_indv_prod {
    margin-top: 0;
    margin-left: 0;
    padding: 16px 30.5px;
    border-style: solid;
    border-color: transparent;
    border-radius: 2.5px;
    background-color: #1c5291;
    color: #fff
}

.btn.cc_indv_prod:hover {
    border-style: solid;
    border-color: #1c5291;
    background-color: #fff;
    color: #304659
}

.btn.cc_product_page {
    padding: 13px 31.5px;
    border-radius: 2.5px;
    background-color: #000;
    color: #fff
}

.btn.cc_product_page.cc_smaller {
    min-width: 99px;
    padding: 9px 15px
}

.w_textures_text {
    max-width: 375px;
    margin-bottom: 6.5%;
    margin-left: 9.3%
}

.w_textures_text.cc_w_in_commercial_page {
    position: relative;
    z-index: 3;
    max-width: 438px;
    margin-bottom: 0;
    margin-left: 12.94%
}

.p_residential_textures {
    line-height: 125%;
    font-weight: 300
}

.s_testimonials {
    width: 100%;
    margin-top: 26%;
    margin-bottom: 26.25%;
    padding-left: 9.4%
}

.s_testimonials.cc_on_commercial_page {
    display: none;
    margin-top: 25.51%;
    margin-bottom: 17.5%;
    padding-left: 0
}

.h1_testimonies.cc_title_on_commercial_page {
    margin-left: 5.73%
}

.w_residential_testimonies {
    display: none;
    margin-top: 6.3%
}

.w_testimony {
    max-width: 528px;
    margin-left: 8.6%
}

.w_testimony.cc_commercial_page_tesimony_left_side {
    display: block;
    max-width: none;
    margin-right: auto;
    margin-bottom: 121px;
    margin-left: auto
}

.w_testimony.cc_commercial_page_tesimony_left_side.cc_no_bottom_margin {
    margin-bottom: 0
}

.w_testimony.cc_commercial_page_tesimony_right_side {
    max-width: 561px;
    margin-bottom: 158px;
    margin-left: 44.11%
}

.c_navbar_mobile, .d_centeredtextandline.cc-center, .d_centeredtextandline.cc-centerline, .w_footercontent {
    margin-right: auto;
    margin-left: auto
}

.tb_testimony_author {
    margin-top: 27px;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 600
}

.fbi_footeremail, .p_footerp {
    font-size: 17px;
    font-weight: 300
}

.tb_testimony_author.cc_zero_top_margin {
    margin-top: 0;
    text-align: right
}

/* .f_mainfooter {
    border-top: 1px solid #e1e1e1;
    background-color: #fff
} */

.w_footercontent {
    display: -ms-grid;
    display: grid;
    width: 100%;
    justify-items: stretch;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-row-gap: 65px;
    -ms-grid-columns: 30.130000000000003% 1.25fr 1.25fr 2fr;
    grid-template-columns: 30.130000000000003% 1.25fr 1.25fr 2fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.s_homehero, .w_footerlinks {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.fb_footeremail {
    display: none;
    margin-bottom: 0
}

.fbi_footeremail {
    margin-bottom: 0;
    padding: 10px 15px;
    border: 1px transparent;
    background-color: #e1e9f0;
    color: #000;
    line-height: 25px
}

.fbi_footeremail::-webkit-input-placeholder {
    color: #000
}

.fbi_footeremail:-ms-input-placeholder {
    color: #000
}

.fbi_footeremail::-ms-input-placeholder {
    color: #000
}

.fbi_footeremail::placeholder {
    color: #000
}

.w_footerleftsidecontent {
    padding-top: 50px;
    padding-bottom: 30px
}

.p_footerp {
    max-width: 357px;
    margin-top: 40px;
    line-height: 147%
}

.w_footerlinks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 50px;
    padding-bottom: 50px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.h4_footerlinkgroup {
    margin-top: 0;
    margin-bottom: 30px
}

.tl_footernavigation {
    position: static;
    margin-bottom: 20px;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: 15px;
    font-weight: 300
}

.tl_footernavigation.cc-hide.w--current {
    display: block
}

.c_navbar_mobile, .d_navrightside {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.h2_alternating_card {
    position: static;
    left: 7.07%;
    top: auto;
    right: auto;
    bottom: 5.25%;
    z-index: 10;
    margin-top: -9px;
    margin-bottom: 15px;
    font-size: 2.1em;
    line-height: 114%;
    font-weight: 500;
}

.d_navrightside {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.n_mainmobilenavbar {
    display: block;
    width: 100%;
    background-color: transparent
}

.fem_footerform, .fsm_footerform {
    background-color: #e1e9f0;
    font-weight: 400
}

.c_navbar_mobile {
    display: flex;
    width: 100%;
    max-width: 1440px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.d_accoustic_image_text_item, .nav-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.nav-menu {
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.d_mobilenavdivider {
    display: none
}

.fsm_footerform {
    color: #3b5266
}

.fem_footerform {
    color: #f58383
}

.s_homehero {
    position: relative;
    z-index: 99;
    display: block;
    height: 1600px; 
    height: 500px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-perspective: 1000px;
    perspective: 1000px;
}
.s_homeheroMedical{
    height: 1250px; 
}
.s_homeheroMedical .w_homeherotext{
    padding-top: 5%;
}
.s_homeheroMedical .w_headerimages{
    top:35px
}

.d_centeredtextandline, .w_homeherotext {
    position: relative;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_homeherotext {
    z-index: 9999;
    display: block;
    height: 100vh;
    padding-top: 10%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.d_centeredtextandline {
    z-index: 9;
    display: inline-block;
    width: 15px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    text-align: left;
    letter-spacing: 1px;
    text-transform: uppercase
}

.d_centeredtextandline.cc-center {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: -33%
}

.d_centeredtextandline.cc-blogblack {
    color: #000
}

.scrollline {
    width: 2px;
    height: 100%;
    background-color: #3b5266;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%
}

.scrollline.cc-light {
    background-color: #000
}

.w_homesectiontext {
    position: relative;
    z-index: 2;
    display: inline-block;
    border-radius: 10px
}

.w_homesectiontext.cc-hide, .w_homesectiontext.cc_accoustics_section.cc-hide {
    display: none
}

.w_homesectiontext.cc_accoustics_section {
    max-width: 460px;
    margin-bottom: 9.2%
}

.w_homesectiontext.cc_layers_section {
    max-width: 500px;
    text-align: right
}

.w_homesectiontext.cc-darktext {
    position: relative;
    color: #000
}

.h2_sectionbroadsubject {
    margin-bottom: 8px;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.h1_sectiontitle {
    margin-bottom: 48px;
    font-weight: 400
}

.h1_sectiontitle.cc_margin_bottom_20px {
    margin-bottom: 20px
}

.p_section_text {
    font-weight: 300
}

.p_section_text.cc_max-w-372 {
    max-width: 372px
}

.p_section_text.cc_accoustic_p {
    max-width: 276px;
    margin-top: 15px;
    margin-bottom: 40px;
    font-size: 1.214em;
    line-height: 147%
}

.p_section_text.cc_tech_spec_p {
    width: 80%;
    margin-top: 30px
}

.p_section_text.cc-skinnyp {
    max-width: 450px
}

.w_homewidesrotatedtext {
    overflow: hidden;
    margin-top: 37px;
    margin-right: 16px;
    margin-left: 14px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    white-space: nowrap
}

/*.tb_images_section, .w_healthy_arch_rotated_text {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg)

}*/

.tb_sideways_home_wide_s {
    z-index: 10;
    margin-right: 0;
    font-size: 1.3em;
    line-height: 153%;
    font-weight: 600
}

.h2_home_tech_specs, .h3_accoustic_item_title, .h3_homecardtitle {
    font-size: 1.786em;
    line-height: 120%
}

.w_homecardandtext {
    position: relative;
    z-index: 9;
    margin-left: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_hometextbtn {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.c_homecard {
    position: relative;
    display: none;
    overflow: hidden;
    margin-top: 40px;
    padding-bottom: 128%
}

.img_home_card {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.img_accoustic_item, .img_home_layers {
    -o-object-fit: cover;
    object-fit: cover;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0
}

.h3_homecardtitle {
    margin-bottom: 30px;
    font-weight: 400
}

.p_home_text_and_btn {
    margin-bottom: 49px;
    font-weight: 300
}

.s_home_accoustics {
    position: relative;
    overflow: hidden
}

.d_accoustic_image_text_item {
    position: relative;
    z-index: 2;
    display: flex;
    margin-top: 10%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_accoustic_image_text_item.cc_second_item {
    margin-top: 10%;
    margin-bottom: 10%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.d_accoustic_image_text_item.cc_techdata {
    margin-top: 5%
}

.w_content_accoustic_div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 350px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.g_contact_bottom_images, .s_contact_bottom_images {
    grid-row-gap: 30px;
    grid-template-columns: 3.9fr 8.1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-auto-columns: 1fr
}

.w_content_accoustic_div.cc_second_div {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: right
}

.w_content_accoustic_div.cc-alightright {
    margin-right: 5%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-align: right
}

.w_content_accoustic_div.cc-alignleft {
    max-width: 350px;
    margin-left: 5%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.h3_accoustic_item_title {
    margin-bottom: 0;
    font-weight: 400
}

.w_accoustic_img {
    position: relative;
    display: block;
    overflow: hidden;
    width: 56.48%;
    height: 500px;
    margin-left: -.6%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.s_home_layers, .s_home_tech_specs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_accoustic_img.cc_inverted_position {
    height: 700px;
    margin-left: 0
}

.w_accoustic_img.cc-left {
    overflow: hidden;
    height: 700px
}

.w_accoustic_img.cc-left.cc_techdata {
  /*  background-image: url();*/
    background-position: 50% 50%;
    background-size: 1000px;
    background-repeat: no-repeat
}

.img_accoustic_item {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover
}

.s_home_layers {
    position: relative;
    z-index: 9;
    display: flex;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    padding: 20% 10%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: none;
    background-position: 0 0;
    color: #fff
}

.s_home_tech_specs, .w_home_spects_title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_layers_img {
    position: relative;
    display: none;
    width: 53%;
    max-width: 80.14%;
    margin-left: -16.62%;
    padding-bottom: 80.15%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.img_home_layers {
    position: absolute;
    object-fit: cover
}

.img_award, .img_baswa_services_types {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%
}

.w_layers_text {
    position: relative;
    z-index: 9
}

.s_home_tech_specs {
    position: relative;
    display: flex;
    margin-top: 0;
    padding: 15% 9.63% 10% 8.529%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    background-color: #fff
}

.w_home_spects_title, .w_home_tech_specs_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_home_spects_title {
    display: flex;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.h2_home_tech_specs {
    font-weight: 400
}

.w_home_tech_specs_content {
    position: relative;
    display: flex;
    margin-top: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.w_home_award, .w_home_awards {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_tech_spec_details {
    padding-right: 31px;
    padding-left: 31px;
    text-align: center
}

.w_tech_spec_details.cc_tech_spec_name_no_bottom_margin {
    margin-bottom: 0
}

.tb_tech_spec_number {
    margin-bottom: 8px;
    font-size: 2em;
    line-height: 109%;
    white-space: nowrap
}

.h3_tech_spec_name {
    font-size: 1em;
    line-height: 150%;
    font-weight: 400
}

.s_home_awards {
    margin-top: 24.11%
}

.w_home_awards_content {
    width: 67%;
    margin-left: 22.1%
}

.w_home_award {
    display: flex;
    margin-top: 0;
    margin-left: 43.9%
}

.w_home_award.cc_award-1 {
    margin-right: 10%;
    margin-left: 0;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.w_home_award.cc_award-2 {
    margin-top: 12.1%;
    margin-bottom: 15.16%;
    margin-left: 0
}

.w_home_award.cc_award-3 {
    margin-left: 0;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.w_award_text, .w_home_awards {
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_home_awards {
    display: flex;
    margin-top: 30.1%;
    -ms-flex-direction: column;
    flex-direction: column
}

.s_baswa_types, .w_award_text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_award_img {
    width: 160px;
    height: 160px;
    margin-right: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.img_award {
    object-fit: cover
}

.w_award_text {
    display: flex;
    max-width: 230px;
    padding-bottom: 7px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.h4_award_name {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.p_healthy_architecture, .p_home_services_types {
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 300
}

.p_award_description {
    margin-top: 11px;
    font-weight: 300
}

.s_baswa_types {
    display: flex;
    margin-bottom: 9%;
    padding-top: 10%
}

.w_types_card.cc_middle_card {
    margin-right: 3.2%;
    margin-left: 3.2%;
    padding-top: 7.8%;
    padding-bottom: 17px
}

.w_types_card.cc_last_card {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.p_home_services_types {
    max-width: 100%;
    margin-top: 25px;
    margin-bottom: 40px
}

.img_baswa_services_types {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    object-fit: cover
}

.w_services_types_img {
    position: relative;
    overflow: hidden;
    padding-bottom: 120%
}

.w_services_types_img.cc_middle_card_img {
    margin-bottom: 10%
}

.s_home_video {
    margin-top: 14.77%;
    margin-bottom: 11.39%
}

.h1_home_video_title {
    max-width: 354px;
    margin-left: 7.35%
}

.w_home_video_holder {
    position: relative;
    width: 55.88%;
    margin-top: 13.82%;
    margin-left: 23.52%
}


@media screen and (max-width: 991px){

    .img_placeholder {
        position: absolute;
        left: 0;
        top: 0;
        right: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
    }
}

@media screen and (min-width: 992px){

    .img_placeholder {
        position: absolute;
        left: 0;
        top: 0;
        right: auto;
        bottom: 0;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover
    }
}

.c_full_vieport_container {
    max-width: 100%
}

.c_full_vieport_container.cc_individual_case {
    overflow: visible;
    max-width: 100%
}

.w_commercial_alternating_cards {
    max-width: 478px;
    margin-right: 2.97%;
    margin-left: 10%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.w_commercial_alternating_cards.cc_text_group_2 {
    margin-right: 10%;
    margin-left: 0;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    text-align: left;
}

.w_commercial_alternating_cards.cc_text_group_3 {
    max-width: none;
    margin-top: 10%;
    margin-right: 5%;
    margin-left: 55%
}

.w_commercial_alternating_cards.cc_text_group_4 {
    min-width: 37%;
    margin-right: 0;
    margin-left: 10%;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center
}

.w_commercial_alternating_cards.cc_text_group_5 {
    margin-top: 5.13%;
    margin-right: 10%;
    margin-left: 0;
    text-align: left;
}

.w_commercial_alternating_cards.cc_text_group_6 {
    min-width: 38.28%;
    margin-top: 8.05%;
    margin-right: 0;
    margin-left: 10%
}

.w_commercial_alternating_cards.cc_text_group_7 {
    max-width: none;
    margin-top: 5%;
    margin-right: 5%;
    margin-left: 5%;
    text-align: left;
}

.w_commercial_alternating_cards.cc_text_group_8 {
    width: 43.06%;
    margin-right: 0
}

.w_commercial_alternating_cards.cc_text_group_9 {
    width: 49.45%;
    max-width: none;
    margin-top: 5.85%;
    margin-right: 10%;
    margin-left: 12.88%;
    text-align: left;
}

.w_commercial_alternating_cards.cc_text_group_10 {
    width: 48.28%;
    margin-top: 10%;
    margin-right: 5%;
    margin-left: 28.46%
}

.w_commercial_alternating_cards.cc_text_group_11 {
    width: 44.14%;
    margin-right: 10%;
    margin-left: 0;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    text-align: right
}

.w_commercial_alternating_cards.cc_text_group_12 {
    width: 37.02%;
    max-width: 411px;
    margin-right: 5.4%
}

.w_commercial_alternating_image {
    position: relative;
    overflow: hidden
}

.w_commercial_alternating_image.cc_group1 {
    overflow: hidden;
    width: 50%;
    padding-bottom: 71.62%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_commercial_alternating_image.cc_group2 {
    width: 41.44%;
    padding-bottom: 56.69%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_commercial_alternating_image.cc_group3 {
    width: 70.63%;
    margin-left: 22.25%;
    padding-bottom: 51.71%;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.w_commercial_alternating_image.cc_group4 {
    width: 59.72%;
    padding-bottom: 50.81%
}

.w_commercial_alternating_image.cc_group5 {
    width: 50%;
    padding-bottom: 61.74%
}

.w_commercial_alternating_image.cc_group6 {
    width: 41.44%;
    margin-left: 9%;
    padding-bottom: 58.69%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_commercial_alternating_image.cc_group7 {
    width: 75%;
    padding-bottom: 44.81%;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.w_commercial_alternating_image.cc_group8 {
    width: 39.45%;
    margin-left: 5.04%;
    padding-bottom: 49.62%
}

.w_commercial_alternating_image.cc_group9 {
    width: 50%;
    padding-bottom: 72.74%
}

.w_commercial_alternating_image.cc_group11 {
    width: 45%;
    padding-bottom: 72.74%
}

.w_commercial_alternating_image.cc_group12 {
    width: 50%;
    margin-left: 7.2%;
    padding-bottom: 48.74%
}

.w_commercial_alternating_image.cc_group_10 {
    width: 71.98%;
    margin-left: 9%;
    padding-bottom: 53.42%
}

.d_commercial_cards_divider {
    position: relative;
    z-index: 2;
    display: none;
    width: 23.6%;
    height: 1px;
    margin-top: 52px;
    margin-left: -22.34%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #3b5266
}

.w_commercial_alternated_group, .w_healthy_architecture_top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_commercial_cards_divider.cc_commercial_divider_2 {
    width: 23.6%;
    height: 1px;
    margin-top: 11.8%;
    margin-left: -6.93%
}

.d_commercial_cards_divider.cc_commercial_divider_3 {
    width: 1px;
    height: auto;
    margin-top: -15.4%;
    margin-left: 54.9%;
    padding-bottom: 23.6%
}

.d_commercial_cards_divider.cc_commercial_divider_4 {
    width: 23.6%;
    height: 1px;
    margin-top: 3.87%;
    margin-left: -22.16%
}

.d_commercial_cards_divider.cc_commercial_divider_5 {
    width: 23.6%;
    height: 1px;
    margin-top: 5.13%;
    margin-right: -15.49%;
    margin-left: 0
}

.d_commercial_cards_divider.cc_commercial_divider_6 {
    width: 23.6%;
    height: 1px;
    margin-top: 14.05%;
    margin-left: -14.14%
}

.d_commercial_cards_divider.cc_commercial_divider_7 {
    width: 1px;
    height: 0;
    margin-top: -11.17%;
    margin-left: 63.96%;
    padding-bottom: 23.6%
}

.d_commercial_cards_divider.cc_commercial_divider_8 {
    width: 23.6%;
    height: 1px;
    margin-top: 32.97%;
    margin-left: -22.07%
}

.d_commercial_cards_divider.cc_commercial_divider_9 {
    width: 23.6%;
    height: 1px;
    margin-top: 5.85%;
    margin-right: -14.49%;
    margin-left: 0
}

.d_commercial_cards_divider.cc_commercial_divider_10 {
    width: 1px;
    height: 0;
    margin-top: -9.63%;
    margin-left: 28.46%;
    padding-bottom: 23.6%
}

.d_commercial_cards_divider.cc_commercial_divider_11 {
    width: 23.6%;
    height: 1px;
    margin-top: 5.13%;
    margin-right: -15.49%;
    margin-left: 2.2%
}

.d_commercial_cards_divider.cc_commercial_divider_12 {
    width: 23.6%;
    height: 1px;
    margin-top: 31.8%;
    margin-left: -15.49%
}

.w_commercial_alternated_group {
    display: flex;
   /* margin-top: 10.35%;*/
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_commercial_alternated_group.cc_group1 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_commercial_alternated_group.cc_group2 {
    margin-top: 20%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_commercial_alternated_group.cc_group1 {
    margin-top: 0
}

.w_commercial_alternated_group.cc_group3 {
    margin-top: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.w_commercial_alternated_group.cc_group4 {
    margin-top: 20%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_commercial_alternated_group.cc_group5 {
    margin-top: 20%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_commercial_alternated_group.cc_group6 {
    margin-top: 20%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_commercial_alternated_group.cc_group7 {
    margin-top: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_commercial_alternated_group.cc_group8, .w_commercial_alternated_group.cc_group9 {
    margin-top: 20%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_commercial_alternated_group.cc_group10 {
    margin-top: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_commercial_alternated_group.cc_group11, .w_commercial_alternated_group.cc_group12, .w_commercial_alternated_group.cc_group13 {
    margin-top: 10%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.w_commercial_alternated_group.cc_group14 {
    margin-top: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.s_commercial_healthy_architecture {
    margin-top: 17.5%
}

.w_healthy_architecture_top {
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.s_portfolio_search_controls, .w_portfolio_filter_toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_healthy_architecture_bottom {
    position: relative;
    margin-top: 45px;
    margin-right: 11.14%;
    margin-left: 58.82%
}

.w_healthy_arch_rotated_text {
    margin-right: 10px;
    margin-left: 3.6%;
    transform: rotate(180deg)
}

.w_healthy_arch_img {
    position: relative;
    width: 85.36%;
    padding-bottom: 30.58%
}

.p_healthy_architecture {
    max-width: 422px
}

.w_testimony_info {
    margin-top: auto;
    margin-bottom: auto;
    padding-right: 0;
    text-align: left
}

.tb_testimony_author_subtext {
    font-size: .929em;
    line-height: 153%;
    font-weight: 500;
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase
}

.h6_testimony_location {
    max-width: none;
    margin-top: 7px;
    margin-bottom: 0;
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300;
    text-align: right
}

.tb_portfolio_hero_date {
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.h1_portfolio_hero_name {
    margin-top: 10px;
    margin-bottom: 38px;
    font-size: 5em;
    line-height: 107%;
    font-weight: 600
}

.h1_portfolio_hero_name.cc_individual_portfolio_hero_h1 {
    margin-top: 18px;
    margin-bottom: 44px
}

.h3_portfolio_hero_subtitle {
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.s_portfolio_search_controls {
    display: flex;
    overflow: hidden;
    padding: 20px 12.29% 15px 9.65%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f6f6f6
}

.w_portfolio_filter_toggle {
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: .929em;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.w_form, .w_portfolio_search {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.tb_portfolio_filter {
    margin-left: 20px;
    font-size: 1em
}

.w_portfolio_search {
    display: flex;
    margin-left: auto;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.s_portfolio_filters_groups {
    border-bottom: 1px solid #dae3eb;
    background-color: #fff
}

.w_portfolio_filters_groups {
    max-width: 1200px;
    min-width: 790px;
    margin: 30px auto
}

.fb_portfolio_filters, .fbl_portfolio_filter {
    margin-bottom: 0
}

.w_form {
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    background-color: transparent
}

.cli_portfolio_checkbox, .s_indv_port_img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.fbc_portfolio_filter {
    width: 11px;
    height: 11px;
    margin-top: 0;
    margin-right: 16px;
    margin-left: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-color: #000;
    border-radius: 50%;
    background-color: transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease
}

.fbc_portfolio_filter.w--redirected-checked {
    border-color: #000;
    border-radius: 120%;
    background-color: transparent;
    background-image: radial-gradient(circle closest-corner at 50% 50%, #000 40%, #dae3eb 0)
}

.fbc_portfolio_filter.w--redirected-focus {
    box-shadow: 0 0 0 0 #3898ec
}

.d_color_option.cc_selected_option, .d_finish_option.cc_selected_option {
    box-shadow: 0 0 0 5px #1c5291
}

.cli_portfolio_checkbox {
    display: flex;
    padding-left: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 20%;
    -ms-flex: 0 20%;
    flex: 0 20%;
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 300
}

.s_portfolio_items {
    position: relative;
    min-height: 1000px;
    margin-bottom: 96px;
    padding-bottom: 37px
}

.d_portfolio_items_white_gradient {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: none;
    padding-bottom: 48.61%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .01)), to(#fff));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .01), #fff)
}

.s_indv_port_hero {
    padding: 3% 15.34% 50px;
    text-align: center;
}

.s_indv_port_img {
    position: relative;
    display: flex;
    width: 100%;
   /* margin-bottom: 66px;
    padding-bottom: 40.763%;*/
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.tb_indv_port_award, .w_indv_port_img_sec_award {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.img_indv_port_section {
    margin-top: 10%;
   /* position: absolute;*/
    width: 97%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.w_indv_port_img_sec_award {
    top: -79px;
    right: 16.66%;
    z-index: 10;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.tb_indv_port_award {
    top: 29.7%;
    display: flex;
    max-height: 90%;
    max-width: 60%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400
}

.p_indv_port_quote, .w_quote {
    font-size: 35px;
    line-height: 114%
}

.s_indv_port_text_sec {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.s_indv_port_text_sec.cc_second_text_sec {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0
}

.rte_indv_port_text_section {
    max-width: 1000px;
    margin-top: 100px;
    margin-bottom: 15px;
}

.s_ind_port_main_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 66px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_main_content_left {
    width: 48.61%
}

.w_quote {
    position: relative;
    display: block;
    margin-bottom: 150px;
    margin-left: 20%;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-weight: 500
}

.w_quote.cc-rightquote {
    margin-right: 10%;
    margin-left: 10%
}

.p_indv_port_quote {
    display: inline;
    float: left;
    clear: none;
    font-weight: 500
}

.s_scroll_to_next_proj, .w_main_content_right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.tb_quotes {
    position: absolute;
    left: -15px;
    top: -19px;
    right: auto;
    bottom: auto
}

.img_blog_big, .img_content, .w_blog_hero_bg {
    top: 0;
    bottom: 0;
    right: 0
}

.w_main_content_img_left {
    position: relative;
    left: -10.14%;
    width: 110%;
    margin-top: 0;
    padding-bottom: 83.85%
}

.img_main_content_left {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.w_main_content_right {
    position: relative;
    right: -20px;
    display: flex;
    width: 46.5%;
    margin-left: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.tb_rotated_text {
    padding: 15px 9px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 120%;
    font-weight: 500;
    white-space: nowrap
}

.tb_rotated_text.cc_scroll_to_next_proj {
    margin-right: 0;
    margin-bottom: 41px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    white-space: nowrap
}

.w_main_content_img_right {
    position: relative;
    width: 92.5%;
    margin-bottom: 20%;
    padding-bottom: 99.4%
}

.tb_background_text {
    opacity: .44;
    font-size: 110px;
    line-height: 108%;
    font-weight: 300;
    white-space: nowrap
}

.s_indv_port_background_text {
    position: relative;
    z-index: -1;
    overflow: hidden;
    width: 100vw;
    margin-top: -21.25%;
    margin-bottom: 93.27px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    opacity: .49;
    color: #d0bbb3;
    text-align: center
}

.s_scroll_to_next_proj.cc-centered, .w_blog_hero_bg {
    -webkit-align-self: center;
    -ms-grid-row-align: center
}

.s_scroll_to_next_proj {
    display: flex;
    margin-top: 10%;
    margin-bottom: 20%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.s_scroll_to_next_proj.cc-centered {
    -ms-flex-item-align: center;
    align-self: center
}

.d_line_scroll_to_next {
    width: 1px;
    height: 262px;
    background-color: #3b5266
}

.s_blog_hero {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 757px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #000
}

.w_blog_hero_bg {
    position: absolute;
    left: 0;
    -ms-flex-item-align: center;
    align-self: center;
    background-color: #000;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.collection-list-wrapper-2, .w_blog_hero_text {
    -webkit-align-self: stretch;
    -ms-grid-row-align: stretch
}

.img_blog_hero_bg {
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.w_blog_hero_text {
    position: relative;
    display: block;
    max-width: 685px;
    padding: 3%;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    background-color: #fff;
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    color: #000
}

.s_4_images_row, .w_blog_content_images {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.h3_blog_hero {
    font-size: 1.786em;
    font-weight: 400
}

.h1_blog_hero {
    margin-top: 16px;
    margin-bottom: 34px;
    font-size: 5em;
    line-height: 107%;
    font-weight: 600
}

.p_blog_hero {
    max-width: 360px;
    font-weight: 300
}

.s_blog_content {
    position: relative;
    margin-top: 10.7%
}

.rte_blog_content {
    max-width: 660px;
    margin-right: auto;
    margin-left: auto;
    line-height: 147%;
    font-weight: 300
}

.s_4_images_row {
    display: flex;
    width: 88.45%;
    margin-top: 8.1%;
    margin-right: 7.3%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.w_blog_content_images {
    display: flex;
    height: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.d_image_item {
    position: relative;
    width: 22.4%;
    margin-right: 2.6%;
    padding-right: 0;
    padding-bottom: 22.4%;
    padding-left: 0
}

.d_image_item.cc_no_left_margin {
    margin-left: 0
}

.img_content {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%
}

.tb_images_section {
    margin-right: 10.5px;
    margin-left: 6.98%;
    transform: rotate(180deg);
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400;
    white-space: nowrap
}

.s_blog_content_text {
    margin-top: 78px
}

.h2_blog_content {
    max-width: 660px;
    margin-bottom: 117px;
    margin-left: 14.7%;
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.s_blog_big_image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8.3%;
    margin-bottom: 4.2%;
    margin-left: 11%;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.h3_blog_big_img_info {
    margin-right: 10px;
    margin-left: 0;
    padding-right: 0;
    padding-left: 0;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    font-size: 1.857em;
    line-height: 153%;
    font-weight: 600;
    white-space: nowrap
}

.w_big_image {
    position: relative;
    width: 95%;
    padding-bottom: 60%
}

.img_blog_big {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%
}

.s_blog_content_bottom {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: auto;
    right: 0;
    bottom: 4%;
    z-index: 9;
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_blog_bottom_image, .w_blog_bottom_image_info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_blog_bottom_image {
    position: relative;
    display: flex;
    width: 41.17%;
    margin-top: 11.39%;
    padding-bottom: 48.97%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center
}

.w_blog_bottom_image_info {
    position: absolute;
    left: 0;
    top: 20.42%;
    right: auto;
    bottom: auto;
    display: flex;
    -webkit-transform: translate(-105%, 0);
    -ms-transform: translate(-105%, 0);
    transform: translate(-105%, 0)
}

.cl_blog_more_articles, .navddt_blog {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_blog_bottom_image_info.cc_info_group_2 {
    top: auto;
    bottom: 4.8%
}

.w_blog_bottom_image_info.cc_info_group_3 {
    left: auto;
    top: 12.16%;
    right: -31px;
    bottom: auto;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0)
}

.w_blog_bottom_image_info.cc_info_group_4 {
    left: auto;
    top: auto;
    right: -31px;
    bottom: 40%;
    -webkit-transform: translate(100%, 0);
    -ms-transform: translate(100%, 0);
    transform: translate(100%, 0)
}

.p_blog_content_bottom {
    width: 20vw;
    max-width: 293px;
    font-size: 1.1em;
    line-height: 133%
}

.d_blog_content_bottom_info_line {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: auto;
    width: 86%;
    height: 1px;
    background-color: #000;
    -webkit-transform: translate(100%, 8px);
    -ms-transform: translate(100%, 8px);
    transform: translate(100%, 8px)
}

.d_blog_content_bottom_info_line.cc_blog_bottom_line_2 {
    width: 50.51%
}

.d_blog_content_bottom_info_line.cc_blog_bottom_line_3 {
    left: -14.79px;
    top: 0;
    right: auto;
    bottom: auto;
    width: 51.87%;
    -webkit-transform: translate(-100%, 11.5px);
    -ms-transform: translate(-100%, 11.5px);
    transform: translate(-100%, 11.5px)
}

.d_blog_content_bottom_info_line.cc_blog_bottom_line_4 {
    left: -14.79px;
    top: 0;
    right: auto;
    bottom: auto;
    width: 32.08%;
    -webkit-transform: translate(-100%, 10px);
    -ms-transform: translate(-100%, 10px);
    transform: translate(-100%, 10px)
}

.s_blog_more_on_blog {
    margin-top: 33%;
    margin-bottom: 14.5%
}

.h2_blog_more {
    margin-bottom: 40px;
    margin-left: 10.41%;
    font-size: 2.6em;
    line-height: 114%;
    font-weight: 500
}

.cl_blog_more_articles {
    display: flex
}

.cli_blog_more_items {
    width: 55.76%;
    margin-right: 29px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.h4_blog_more_item {
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 1.44em;
    line-height: 125%;
    font-weight: 300
}

.w_blog_more_item_image {
    position: relative;
    padding-bottom: 74.71%;
    background-image: url(../images/icons/Maps-Marker-Closed.svg);
    background-position: 50% 50%;
    background-size: auto
}

.img_blog_more_item {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.s_main_blog_hero {
    margin-top: 10%
}

.h1_page_title {
    margin-bottom: 7px;
    font-size: 7.857em;
    line-height: 105%;
    font-weight: 600
}

.p_main_blog_hero {
    font-weight: 300
}

.w_main_blog_filter {
    display: inline-block;
    margin-top: 10%;
    border-bottom: 1px solid #e1e1e1;
    text-align: left
}

.h4_main_blog_filter_toogle {
    margin-left: 20px;
    float: right;
    font-size: .929em;
    line-height: 149%;
    letter-spacing: 1px;
    text-transform: uppercase
}

.d_dropdown_connector {
    position: absolute;
    left: 0;
    top: -14px;
    right: auto;
    bottom: auto;
    width: 20px;
    height: 14px;
    background-color: #f6f6f6
}

.h4_main_blog_category_filter {
    -webkit-transition: color .2s ease;
    transition: color .2s ease;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300;
    cursor: pointer
}

.h4_main_blog_category_filter:hover {
    color: #999
}

.navddt_blog {
    display: flex;
    padding: 10px 0
}

.ci_blog_post_item, .cl_main_blog_posts {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.dropdown-list {
    overflow: hidden;
    width: 261px;
    padding: 18px 41px 57px 40px;
    background-color: #f6f6f6
}

.dropdown-list.w--open {
    top: 39px;
    width: auto;
    min-width: 178px;
    padding-right: 20px;
    padding-bottom: 25px;
    padding-left: 20px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    background-color: #fff
}

.s_main_blog_listings {
    margin-top: 6%
}


.h5_blog_item_category {
    margin: 0 5.5px 0 0;
    white-space: nowrap
}

.ci_blog_post_item {
    position: relative;
    display: flex;
    width: 45%;
    margin-right: 2.5%;
    margin-bottom: 16.8%;
    margin-left: 2.5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.w_main_blog_post_content, .w_main_blog_post_image {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.cl_main_blog_posts {
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_main_blog_posts_right_side {
    position: static;
    width: 100%
}

.h2_main_blog_post_name {
    max-width: 400px;
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.d_main_blog_link_to_post {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 27px;
    margin-bottom: 27px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 300
}

.d_main_blog_link_line {
    width: 94px;
    height: 1px;
    margin-right: 20px;
    background-color: #979797
}

.w_main_blog_post_image {
    position: relative;
    display: block;
    width: 100%;
    height: 500px;
    padding-top: 5%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}




.img_main_blog_post {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.w_main_blog_post_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.s_contact_hero {
    display: block;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.d_contact_hero_left_content, .w_contact_hero_left {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_contact_hero_left {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 900px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: #e1e9f0;
    background-image: url(../images/gdtc-contact.jpg);
    background-position: 50% 50%;
    background-size: cover;
}

.d_contact_hero_left_content {
    position: static;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    max-width: 653px;
    margin-right: 9%;
    margin-left: 16.66%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.p_contact_hero_left {
    font-size: 1.786em;
    line-height: 120%
}

.h1_contact_page_title {
    margin-top: 20px;
    margin-bottom: 49px;
    font-size: 5em;
    line-height: 104%;
    font-weight: 600
}

.p_contact_hero_text {
    display: none;
    max-width: 360px;
    font-size: 1.21em;
    line-height: 148%;
    font-weight: 300
}

.w_contact_hero_right {
    display: block;
    overflow: auto;
    height: 100%;
    padding: 10%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 1.214em;
    line-height: 148%;
    font-weight: 300
}

.h3_contact_hero_right {
    margin-bottom: 30px;
    font-size: 1.786em;
    font-weight: 400
}

.fb_contact_page {
    margin-top: 33px
}

.fbi_contact_page {
    margin-bottom: 26px;
    border-style: solid;
    border-width: 1px;
    border-color: transparent transparent #000;
    background-color: transparent;
    font-family: Flama, sans-serif;
    color: #000;
    font-size: .9em;
    line-height: 20px;
    font-style: normal;
    font-weight: 400
}

.fbi_contact_page::-webkit-input-placeholder {
    color: #8298ab
}

.fbi_contact_page:-ms-input-placeholder {
    color: #8298ab
}

.fbi_contact_page::-ms-input-placeholder {
    color: #8298ab
}

.fbi_contact_page::placeholder {
    color: #8298ab
}

.fbi_contact_page.cc_contact_form_textarea {
    min-height: 159px;
    margin-bottom: 56px
}

.s_contact_info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 12.72%;
    padding-left: 7.63%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.h2_contact_general_info {
    max-width: 321px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 3.214em;
    line-height: 50px;
    font-weight: 400
}

.w_contact_page_address {
    margin-right: 16px;
    margin-bottom: 35px;
    padding-left: 20px;
    border-left: 2px solid #1c5291;
    line-height: 125%;
    font-weight: 300
}

.w_contact_page_address.cc-footer {
    margin-top: 25px;
    margin-bottom: 0;
    padding-left: 0;
    border-left-style: none;
    font-size: 1.1em;
    line-height: 170%
}

.w_contact_page_social_media {
    display: none;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.lb_contact_page_social_media_icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    margin-top: 15px;
    margin-right: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.img_contact_page_social_media {
    width: 100%;
    height: 100%
}

.w_contact_social_media_icons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.s_contact_map, .s_faq_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.s_contact_map {
    position: relative;
    display: flex;
    margin-top: 7.6%;
    margin-bottom: 3.8%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.h3_contact_map {
    position: static;
    left: 7.35%;
    top: auto;
    right: auto;
    bottom: 40px;
    z-index: 2;
    margin-top: 7.6%;
    margin-bottom: 0;
    font-size: 2.5em;
    line-height: 114%
}

.s_contact_bottom_images {
    position: relative;
    display: block;
    width: 1140px;
    max-width: 83.82%;
    margin: 40px auto 50px;
    padding-bottom: 49.26%;
    grid-column-gap: 30px;
    -ms-grid-columns: 3.9fr 8.1fr
}

.d_contact_page_images {
    position: relative;
    width: 100%;
    height: 100%
}

.g_contact_bottom_images, .img_contact_page_bottom_placeholder {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.g_contact_bottom_images {
    display: -ms-grid;
    display: grid;
    grid-column-gap: 30px;
    -ms-grid-columns: 3.9fr 8.1fr
}

.h2_faq_page_title {
    margin-top: 14%;
    margin-bottom: 15.51%;
    font-size: 5em;
    line-height: 108%;
    font-weight: 600;
    text-align: center
}

.s_faq_content {
    position: relative;
    display: flex;
    margin-bottom: 15%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.d_faq_answer_toggle, .w_faq_navigation_links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_faq_left {
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
    margin-right: 5.7%;
    margin-left: 7.35%
}

.w_faq_right {
    margin-right: 9.9%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.h2_navigation_title {
    font-size: 1.786em;
    font-weight: 400
}

.w_faq_navigation_links {
    display: flex;
    margin-top: 17px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.tb_faq_navigation {
    font-size: .929em;
    line-height: 215%;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase
}

.h2_faq_group_title {
    margin-bottom: 38px;
    font-size: 2.5em;
    line-height: 114%
}

.w_faq_question_group {
    padding-top: 30px
}

.d_faq_answer_toggle {
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    cursor: pointer
}

.ci_faq, .utility-page-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_question_content {
    width: 93%;
    margin-left: auto;
    font-size: 1em
}

.h3_faq_question_title {
    margin-bottom: 18px;
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.rte_faq_question_answer {
    font-size: 1.214em;
    line-height: 147%
}

.ci_faq {
    display: flex;
    margin-bottom: 58px;
    padding-bottom: 30px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    border-bottom: 1px solid #979797
}

.d_question_toogle_line {
    position: absolute;
    width: 24px;
    height: 2px;
    background-color: #979797
}

.d_question_toogle_line.cc_right_line {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg)
}

.w_faq_answer {
    overflow: hidden;
    width: 93%;
    max-width: 93%;
    margin-left: auto
}

.utility-page-wrap {
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.utility-page-content, .utility-page-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.utility-page-content {
    display: flex;
    width: 260px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center
}

.bgv, .v_healthyarch {
    width: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0
}

.utility-page-form {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.v_healthyarch {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.bgv {
    position: absolute;
    height: 100%;
    background-image: none
}

.bgv.cc-nogradient {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), to(rgba(0, 0, 0, .3)));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3))
}

.g_homepage {
    position: relative;
    z-index: 9;
    justify-items: stretch;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    text-align: left
}

.reveal {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: block;
    background-color: #fff;
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.h2_portfolio_item {
    color: #fff;
    line-height: 111%;
    font-weight: 500
}

.h2_portfolio_item.cc_imageless_child {
    color: #000;
    font-size: 2.2em
}

.d_portfolio_item_holder {
    position: relative;
    display: block;
    overflow: hidden;
    height: 100%;
    max-width: 100%;
    padding: 15px 15px 25px 10px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-radius: 5px;
    background-color: #f5f5f5
}

.d_portfolio_item_holder.cc_image_avail {
    padding: 0
}

.w_portfolio_item_text {
    position: absolute;
    bottom: 0;
    z-index: 2;
    margin-right: 35px;
    margin-bottom: 35px;
    margin-left: 35px;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

.w_portfolio_item_text.cc_imageless_child {
    position: static;
    max-width: 350px;
    margin-right: 10px;
    margin-left: 10px
}

.h3_portfolio_item {
    display: inline-block;
    margin-bottom: 15px;
    color: #fff;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.h3_portfolio_item.cc_place_date_divider {
    margin-right: 5px;
    margin-left: 5px
}

.p_pillar_hero, .w_pillar_hero_text {
    margin-right: auto;
    margin-left: auto
}

.h3_portfolio_item.cc_imageless_child {
    color: #000
}

.g_portfolio {
    display: block;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.gi_portfolio {
    display: inline-block
}

.s_pillar_hero, .w_pillar_image_and_features {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 100%
}

.clw_portfolio {
    margin-top: 75px
}

.tb_faq_cta {
    margin-top: 14%;
    font-size: 2.5em;
    line-height: 114%
}

.s_pillar_hero {
    display: flex;
    margin-top: 10.9%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.w_pillar_hero_text {
    max-width: 80%;
    text-align: center
}

.h1_pillar_hero {
    font-size: 5em;
    line-height: 107%;
    font-weight: 600
}

.p_pillar_hero {
    max-width: 556px;
    margin-top: 16px;
    font-size: 1.21em;
    line-height: 147%;
    font-weight: 500
}

.w_pillar_image_and_features {
    display: flex;
    overflow: hidden;
    margin-top: 3.2%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.s_products_hero, .w_panel_i_h_bottom_right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_pillar_hero_image {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.tb_hero_image_rotated {
    margin-right: 9.5px;
    margin-left: auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    font-size: 13px;
    line-height: 154%;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap
}

.c_newsletter, .tabc_leed_v4_credits {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0
}

.d_pillar_image_object_fit {
    position: relative;
    width: 92%;
    max-width: 92%;
    margin-left: 0;
    padding-bottom: 0
}

.img_pillar_hero {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.img_pillar_hero.cc-generic {
    display: block
}

.w_features {
    position: relative;
    z-index: 2;
    display: -ms-grid;
    display: grid;
    width: 80%;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 2.85em;
    grid-row-gap: 5.71em;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.w_feature, .w_panel_inst_hero_bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_feature {
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.h3_feature_name {
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 600
}

.w_feature_text {
    margin-left: 13px
}

.p_feature {
    margin-top: 5px;
    font-size: 1.07em;
    line-height: 133%;
    font-weight: 300
}

.d_line_s_divider {
    width: 90%;
    height: 1px;
    margin: 5% auto;
    background-color: #979797
}

.s_pillar_content {
    width: 80%;
    margin: 8% auto 12%
}

.h2_pillar_content {
    font-size: 3.21em;
    line-height: 111%;
    font-weight: 400
}

.rte_pillar_text {
    max-width: 560px;
    margin-top: 4.7%;
    margin-right: auto;
    margin-left: auto
}

.tb_pillar_content_excerpt {
    max-width: 660px;
    margin-top: 8.6%;
    margin-bottom: 6.6%;
    margin-left: 22%;
    font-size: 2.5em;
    line-height: 114%
}

.s_panel_inst_hero {
    display: none
}

.h1_panel_inst_hero {
    margin-top: 9.8%;
    margin-left: 9%;
    font-size: 7.2em;
    line-height: 116%;
    font-weight: 600
}

.w_panel_inst_hero_bottom {
    display: flex;
    width: 82%;
    margin-top: 10.2%;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.w_panel_i_h_bottom_left {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.w_panel_i_h_bottom_right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 650px;
    padding-left: 15px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.h2_panel_inst_hero {
    max-width: 500px;
    margin-bottom: 49px;
    font-size: 5em;
    line-height: 107%;
    font-weight: 600
}

.p_panel_inst_hero {
    max-width: 360px
}

.p_panel_inst_hero.cc-repform {
    max-width: none;
    margin-top: 15px
}

.s_panel_inst_content {
    margin: 19.9% 7.3% 6.7% 9%
}

.h1_panel_i_content {
    display: none;
    margin-bottom: 12.5%;
    font-size: 3.214em;
    line-height: 111%;
    font-weight: 400;
    text-align: center
}

.w_installation_process_step {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 23%;
    padding-top: 50px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 16px;
    -ms-grid-columns: 47.82% 40%;
    grid-template-columns: 47.82% 40%;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.tabs-menu, .w_tech_d_o_data_inner_content {
    grid-column-gap: 16px;
    grid-auto-columns: 1fr
}

.g_portfolio_no_img, .tabs-menu {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr
}

.p_inst_step {
    max-width: 550px;
    margin-top: 50px
}

.h2_p_inst_step {
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.w_p_i_step_text {
    width: 47.8%;
    padding-right: 40px;
    padding-left: 40px
}

.img_p_inst_step_img {
    max-width: 67%;
    margin-left: auto;
    border-radius: 0
}

.s_panel_inst_cta {
    display: none;
    margin-bottom: 31.25%;
    padding-left: 29%;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.w_panel_i_cta_wrapper {
    max-width: 350px;
    margin-right: 49px
}

.h2_p_i_cta {
    font-size: 3.214em;
    line-height: 111%;
    font-weight: 400
}

.p_panel_inst_cta {
    max-width: 276px;
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 300
}

.img_p_inst_hero {
    display: block;
    width: 700px;
    height: 700px;
    max-width: none;
    margin-left: 39%;
    border-radius: 1000px;
    -o-object-fit: cover;
    object-fit: cover
}

.s_products_hero, .tm_procuts_top_tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.s_products_hero {
    display: flex;
    padding-top: 10%;
    padding-bottom: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.h1_products_hero {
    font-size: 7.857em;
    line-height: 105%;
    font-weight: 600
}

.p_products_hero {
    max-width: 585px;
    margin-top: 1.5%;
    margin-bottom: 3.6%;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.s_products_tabs {
    margin-bottom: 11.83%
}

.tl_products_top_tab {
    bottom: -1px;
    margin-right: 1.5%;
    margin-left: 1.5%;
    padding-right: 14px;
    padding-bottom: 13px;
    padding-left: 14px;
    border-bottom: 2px solid transparent;
    background-color: transparent;
    opacity: .2;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: .929em;
    line-height: 162%
}

.tabl_tech_data, .tl_products_subtab {
    -webkit-transition: all .4s ease;
    background-color: transparent
}

.tl_products_top_tab.w--current {
    border-bottom-color: #1c5291;
    background-color: transparent;
    opacity: 1
}

.tm_procuts_top_tab {
    display: flex;
    margin-bottom: 3.5%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #d3dce5
}

.tl_products_subtab, .tm_products_inner_tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.tl_products_subtab, .w_prod_i_tab_left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column
}

.h5_subtab_heading {
    margin-bottom: 5px;
    font-size: .929em;
    line-height: 154%;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.tl_products_subtab {
    bottom: -1px;
    display: flex;
    max-width: 130px;
    margin-right: 4%;
    margin-left: 4%;
    padding-right: 0;
    padding-bottom: 38.5px;
    padding-left: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-bottom: 2px solid transparent;
    transition: all .4s ease;
    line-height: 127%;
    font-weight: 300
}

.tl_products_subtab.w--current {
    border-bottom-color: #1c5291;
    background-color: transparent
}

.tm_products_inner_tabs {
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 1px solid #e9eef2
}

.tp_products_inner_tab {
    margin-top: 7.16%
}

.w_products_inner_tab_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.w_prod_i_tab_left {
    display: block;
    max-width: 450px;
    margin-right: auto;
    margin-left: 20%;
    padding-top: 0;
    padding-left: 20px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_installation_icons, .w_prod_i_tab_right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_installation_icon, .w_prod_i_tab_right {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_prod_i_tab_right {
    display: flex;
    width: 624px;
    max-width: 46%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.img_products_i_tab {
    max-width: 80%
}

.w_prods_i_tab_installations {
    display: none;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.w_installation_icons {
    display: flex
}

.d_installation_icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 23px;
    height: 27px;
    margin-right: 15px;
    margin-left: 15px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.img_installation_disabled.cc_hidden, .img_installation_enabled.cc_hidden {
    display: none
}

.img_installation_disabled {
    opacity: .39
}

.p_products_inner_tab {
    max-width: 331px;
    font-weight: 300
}

.p_products_inner_tab.cc_baswasonic {
    max-width: 331px
}

.w_products_buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 44px
}

.s_tech_data_hero {
    margin-top: 10%;
    margin-bottom: 10%
}

.h1_tech_data_hero {
    max-width: 600px;
    margin-bottom: 6%;
    font-size: 7.857em;
    line-height: 105%;
    font-weight: 600
}

.c_tech_data {
    display: block;
    width: 100%;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto
}

.h2_tech_data_section_name {
    margin-bottom: 4%;
    font-size: 3.929em;
    line-height: 109%;
    font-weight: 500
}

.d_accoustic_test_results {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 488px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-right: 0 solid #000
}

.d_accoustic_test_results.cc_typea.cc_tooltip_conditioned, .d_accoustic_test_results.cc_typee.cc_tooltip_conditioned {
    display: none
}

.d_accoustic_test_results.cc_typee {
    max-width: 507px;
    margin-left: auto;
    padding-right: 30px;
    padding-left: 30px
}

.d_accoustic_test_results.cc_typea {
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-right-width: 1px;
    border-right-color: #f6f6f6
}

.p_tech_data_test_data {
    display: block;
    margin-bottom: 44.1px;
    font-weight: 300
}

.img_tech_test_data {
    margin-top: auto
}

.s_tech_data_graphs {
    display: block
}

.tabm_tech_data {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 3%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #d3dce5
}

.tabl_tech_data {
    bottom: -2px;
    padding-right: 24px;
    padding-bottom: 25px;
    padding-left: 24px;
    border-bottom: 3px solid transparent;
    transition: all .4s ease;
    font-size: .8em;
    line-height: 154%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase
}

.tabl_tech_data.w--current {
    border-bottom-color: #1c5291;
    background-color: transparent
}

.tabl_tech_data.tabl_graph {
    padding-bottom: 24px;
    border-bottom-width: 3px
}

.s_tech_data_other_data {
    display: block;
    margin-top: 10%
}

.t_other_test_data {
    display: none;
    margin-right: 7%
}

.tabm_tech_data_other_test, .tabm_tech_data_spec_dets {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-pack: justify
}

.tabm_tech_data_other_test {
    display: flex;
    margin-top: 3%;
    margin-bottom: 31px;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #d3dce5
}

.t_tech_d_other_test_inner {
    margin-left: 24px
}

.tabl_tech_data_inner_tab {
    display: inline;
    padding: 5px 0;
    border-bottom: 1px solid transparent;
    background-color: transparent;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300
}

.tabl_tech_data_inner_tab.w--current {
    border-bottom-color: #304659;
    background-color: transparent
}

.tabs-menu {
    display: -ms-grid;
    display: grid;
    margin-right: 24px;
    margin-bottom: 10.17%;
    justify-items: baseline;
    grid-row-gap: 5px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.w_tech_d_o_data_inner_content {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-row-gap: 16px;
    -ms-grid-columns: 3.03fr 6.1fr 2.84fr;
    grid-template-columns: 3.03fr 6.1fr 2.84fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.h2_tech_d_o_inner_content {
    font-size: 3.214em;
    line-height: 111%;
    font-weight: 400
}

.p_tech_d_o_inner_content {
    max-width: 604px;
    font-size: 1.429em
}

.s_tech_data_specs_dets {
    margin-top: 10%
}

.p_tech_d_specs_d_top {
    max-width: 750px;
    margin-top: 25px;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 500
}

.tabm_tech_data_spec_dets {
    display: flex;
    margin-top: 3%;
    margin-bottom: 31px;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #d3dce5
}

.navddt_tech_data_resources, .w_tech_d_spec_d_tab_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_tech_d_spec_d_tab_content {
    display: flex;
    margin-top: 4.2%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left
}

.w_tech_d_spec_d_tab_c_left.cc-techcustomfinish {
    max-width: 100%
}

.h3_tech_s_specs_tab_content {
    font-size: 3.214em;
    line-height: 111%;
    font-weight: 400
}

.h4_tech_s_specs_tab_content {
    margin-top: 11px;
    margin-bottom: 32px;
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.h5_tech_s_specs_tab_content {
    margin-bottom: 8px;
    font-size: 1.429em;
    line-height: 150%;
    font-weight: 400
}

.h5_tech_s_specs_tab_content.cc_second {
    margin-top: 50px
}

.li_tech_data_specs {
    margin-bottom: 9px;
    font-size: 1.1em;
    line-height: 150%;
    font-weight: 400
}

.p_tech_s_specs_tab_content {
    max-width: 460px;
    font-size: 1.429em
}

.tabs-content {
    overflow: visible
}

.h5_tech_s_specs_tab_content_r_side {
    margin-bottom: 6.11%;
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.li_tech_data_ratings {
    margin-bottom: 0;
    font-size: 1.071em;
    line-height: 150%;
    font-weight: 300;
    text-align: right
}

.navddt_tech_data_resources {
    display: flex;
    padding: 10px 50px 10px 0;
    clear: both;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 600
}

.icon-2 {
    position: static;
    margin-left: 5px;
    color: #304659
}

.lb_tech_data_resources {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 15px 16px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #ededed;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-weight: 300
}

.lb_tech_data_resources:hover {
    opacity: .7
}

.lb_tech_data_resources.cc_out_of_drop {
    border-top-color: #ededed
}

.lb_tech_data_resources.cc_out_of_drop.cc_hide {
    display: none
}

.tb_tech_data_resources {
    font-size: .8em;
    line-height: 165%;
    font-weight: 500
}

.navdd_tech_data_resources {
    margin-top: 33px;
    margin-left: 0
}

.s_tech_data_cleaning {
    display: block;
    margin-top: 10%
}

.w_tech_d_cleaning_2row, .w_tech_d_cleaning_3row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_tech_d_cleaning_2row {
    display: flex;
    margin-top: 2.9%;
    margin-bottom: 5.9%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.p_tech_d_cleaning_2row {
    max-width: 52.2%;
    margin-right: 17.7%;
    font-size: 1.429em
}

.w_tech_d_cleaning_3row {
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_additional_video, .w_leed_credits_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_tech_d_cm_3row_content {
    max-width: 300px
}

.w_tech_d_cm_3row_content.cc_center {
    margin-right: 7.9%;
    margin-left: 7.9%
}

.h3_tech_d_cm_3row_content {
    margin-bottom: 6px;
    font-weight: 400
}

.p_tech_data_content {
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300
}

.w_tech_d_cleaning_4row {
    margin-top: 4%;
    margin-bottom: 10%
}

.h2_tech_data_video {
    margin-bottom: 30px;
    font-size: 3.214em;
    line-height: 111%;
    font-weight: 400
}

.w_video_resources_content {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.v_tech_data_resources {
    width: 100%;
    margin-right: 40px;
    margin-bottom: 15px
}

.c_titletechdivider {
    margin-top: 40px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.h3_tech_d_additional_videos {
    margin-bottom: 27px;
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.w_additional_video {
    display: flex
}

.h4_additional_video {
    margin-bottom: 25px;
    font-size: 1.786em;
    line-height: 120%;
    font-weight: 400
}

.p_additional_video {
    max-width: 260px;
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300
}

.img_additional_video_thumb {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin-right: 40px
}

.d_additional_videos_separator, .d_leed_credits_line_divider {
    height: 1px;
    background-color: #e3e3e3
}

.w_additional_video_thumb {
    position: relative;
    width: 160px;
    margin-right: 40px;
    padding-bottom: 87px
}

.d_additional_videos_separator {
    margin-top: 42px;
    margin-bottom: 42px
}

.s_tech_data_leed_credits {
    margin-top: 10%;
    margin-bottom: 10%
}

.p_leed_credits_top {
    margin-top: 26px;
    margin-bottom: 47px;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 500
}

.w_leed_credits_content {
    display: flex;
    margin-top: 5%;
    margin-right: 5%;
    text-align: left
}

.tab_leed_v4_credits, .w_leed_credits_download_certificates {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.p_leed_credits_content {
    width: 660px;
    max-width: 56.89%;
    margin-right: 40px;
    font-size: 1.429em
}

.w_leeds_credits_right {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.p_leed_credits_right {
    margin-bottom: 21px;
    font-size: 1em;
    line-height: 168%;
    font-weight: 300
}

.w_leed_credits_download_certificates {
    display: flex;
    margin-top: 28px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1em;
    line-height: 168%;
    font-weight: 300
}

.tb_leed_credits_download {
    max-width: 284px
}

.tab_leed_v4_credits {
    position: relative;
    display: flex;
    margin-top: 5%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    text-align: left
}

.tabm_leed_v4_credits {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 0;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.tabc_leed_v4_credits {
    display: block;
    width: 65%;
    margin-left: auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.tabl_leed_v4_credits {
    margin-top: 0;
    margin-bottom: 5px;
    padding: 18px 0 0;
    background-color: transparent;
    color: rgba(0, 0, 0, .35);
    font-size: .7em;
    line-height: 132%;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.tabl_leed_v4_credits.w--current {
    background-color: transparent;
    color: #000
}

.h2_leed_v4_credits_tab_content {
    margin-bottom: 4%;
    font-size: 2.9em;
    line-height: 111%;
    font-weight: 400
}

.d_leed_credits_content_item {
    margin-bottom: 25px
}

.h4_leed_c_text_c_subtitle {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 168%;
    font-weight: 500;
    text-transform: uppercase
}

.h4_leed_c_text_c_subtitle.cc_second_sub {
    margin-top: 15px
}

.p_leed_c_text_content {
    line-height: 168%;
    font-weight: 300
}

.s_tech_data_ctas {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 31%;
    margin-bottom: 14.7%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.w_tech_data_cta_item {
    margin-left: 50px
}

.tb_tech_data_cta_item {
    margin-bottom: 13px;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 300
}

.h2_tech_data_cta_item {
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.p_tech_data_cta_item {
    max-width: 460px;
    margin-top: 22px;
    margin-bottom: 40px;
    font-size: 1.214em;
    line-height: 147%;
    font-weight: 300
}

.d_tech_data_ctas_divider {
    width: 1px;
    min-height: 350px;
    margin-top: 58px;
    margin-left: 60px;
    background-color: #e3e3e3
}

.collection-list-wrapper {
    display: none
}

.s_indv_prod_hero {
    position: relative;
    z-index: 1000;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 10%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_indv_prod_hero_right {
    padding-top: 50px
}

.p_indv_prod_hero_right {
    max-width: 362px;
    margin-bottom: 19.6%;
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300
}

.p_indv_prod_hero_right.cc_non_stem {
    margin-bottom: 0
}

.w_indv_prod_settings {
    padding: 20px;
    border: 1px solid #e1e1e1
}

.tb_indv_prod_settings_name {
    display: inline-block;
    margin-right: 7px;
    margin-bottom: 10px;
    font-size: 1.2em;
    line-height: 125%;
    font-weight: 400
}

.navddt_indv_prod_hero_r, .w_options_holder {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_options_holder {
    display: flex;
    margin-top: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_thichness_option {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border: 3px solid #efefef;
    border-radius: 50%;
    background-color: #fff
}

.d_thichness_option.cc_thicness_2 {
    border-width: 5px;
    border-color: #efefef
}

.d_thichness_option.cc_thicness_3 {
    border-width: 7px
}

.d_thichness_option.cc_selected_option {
    margin-right: auto;
    margin-left: auto;
    box-shadow: 0 0 0 5px #1c5291
}

.d_color_option, .d_finish_option {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    border-radius: 50%
}




.tb_indv_prod_option_sub {
    margin-top: -5px;
    margin-bottom: 20px;
    font-size: 1.071em;
    line-height: 133%;
    font-weight: 300
}

.d_color_option {
    background-color: #f6f6f6
}



.d_indv_prod_right_line {
    height: 1px;
    max-width: 100%;
    margin-top: 41px;
    margin-bottom: 51.5px;
    background-color: #e1e1e1
}

.d_indv_prod_right_line.cc_non_stem {
    margin-top: 33px;
    margin-bottom: 33px
}

.navdd_indv_prod_hero_r {
    margin-right: 0;
    margin-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1em;
    line-height: 125%;
    font-weight: 600
}

.s_indv_prod_all_prods, .slider, .w_indv_prod_other_prod, .w_switching_layers.cc_svg {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.navdd_indv_prod_hero_r.cc_hidden {
    display: none
}

.nadddl_indv_prod {
    display: none;
    padding-top: 35px;
    background-color: transparent
}

.nadddl_indv_prod.w--open {
    padding-top: 0;
    border-bottom-left-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    background-color: #fff
}

.navddt_indv_prod_hero_r {
    display: flex;
    height: 100%;
    margin-right: 0;
    margin-left: 0;
    padding: 16px 30.5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #000;
    border-radius: 2.5px;
    color: #000;
    line-height: 147%;
    font-weight: 600;
    cursor: none
}

.s_indv_prod_all_prods, .tm_indv_prod_tab {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.s_indv_prod_all_prods {
    display: flex;
    margin-top: 6.25%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center
}

.tm_indv_prod_tab {
    display: flex;
    margin-top: 93px;
    padding-right: 34px;
    padding-left: 34px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_indv_prod_horiz_scroll, .w_indv_prod_other_prod {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_indv_prod_horiz_scroll {
    display: flex;
    overflow: auto;
    margin-right: auto;
    margin-left: auto;
    padding-top: 60px;
    padding-right: 23px;
    padding-left: 23px;
    text-align: center
}

.w_indv_prod_other_prod {
    display: flex;
    padding-right: 13px;
    padding-left: 13px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-perspective: 1000px;
    perspective: 1000px
}

.h3_indv_prod_other_prod {
    margin-top: 19px;
    margin-bottom: 13px;
    font-size: 1.429em;
    line-height: 125%;
    font-weight: 600
}

.d_indv_prod_img_holder {
    position: relative;
    width: 264px;
    height: 365px;
    box-shadow: 4px 7px 12px 0 rgba(0, 0, 0, .21);
    -webkit-transform: rotateX(0) rotateY(-30deg) rotateZ(0);
    transform: rotateX(0) rotateY(-30deg) rotateZ(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.img_indv_prod_other_prod {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.mask, .slider {
    position: relative
}

.tc_indv_prod_tab {
    overflow: visible;
    margin-top: 64px;
    margin-bottom: 149px
}

.s_indv_prod_case {
    overflow: hidden;
    margin-bottom: 403px
}

.h5_indv_prod_case_study {
    margin-left: 25%;
    font-size: .929em;
    line-height: 154%;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase
}

.w_indv_prod_cases_carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    margin-top: 30px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.indv_prod_case_study_item {
    position: relative;
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.img_indv_prod_case_study_item {
    width: 100%;
    margin-bottom: 40px;
    -o-object-fit: cover;
    object-fit: cover
}

.mask {
    overflow: visible;
    width: 760px;
    height: 661px
}

.slider {
    width: 100%;
    height: 660px;
    padding-right: 25%;
    padding-left: 25%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: transparent
}

.slide_individual_prod {
    position: static;
    width: 100%;
    margin-right: 40px
}

.h3_indv_prod_case_item {
    margin-bottom: 42px;
    font-size: 2.5em;
    line-height: 114%;
    font-weight: 500
}

.tb_indv_prod_case_item {
    display: inline-block;
    font-size: 1.429em;
    line-height: 150%
}

.slider_arrow, .w_switching_layers.cc_svg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.slider_arrow {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 12px;
    height: 30px;
    margin: auto 0 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_switching_layers {
    position: relative;
    margin-right: 40px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: #fff
}

.w_switching_layers.cc_svg {
    z-index: 1000;
    display: flex;
    margin-right: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.d_other_products_center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.d_prod_quote_f_right, .w_indv_prod_options {
    display: -ms-grid;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    grid-column-gap: 16px
}

.tb_products_subtab_description {
    font-size: .9em
}

.w_indv_prod_options {
    display: grid;
    grid-auto-columns: 1fr;
    grid-row-gap: 30px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr
}

.w_indv_prod_options.cc_v2 {
    grid-row-gap: 0;
    border-style: none solid;
    border-width: .91px;
    border-color: #e1e1e1
}

.d_options_holder, .d_prod_quote_f_right, .g_portfolio_no_img, .g_reps, .w_indv_prods_btns {
    grid-row-gap: 16px;
    grid-auto-columns: 1fr
}

.slider_nav {
    display: none
}

.collection-list-wrapper-2 {
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.d_infinite_scroll_target, .w_quote_form {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.img_blog_last {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.c_blog_hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px
}

.lb_blog_other_blogs {
    width: 100%
}

.lb_blog_other_blogs.w--current {
    height: 100%
}

.p_blog_content {
    max-width: 660px;
    margin-left: 22.05%;
    line-height: 147%;
    font-weight: 300
}

.d_infinite_scroll_related {
    display: none
}

.d_infinite_scroll_target, .w_portfolio_checkbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_infinite_scroll_target {
    position: relative;
    display: flex;
    overflow: hidden;
    max-width: 100%;
    -ms-flex-direction: column;
    flex-direction: column
}

.w_portfolio_checkbox {
    display: flex;
    padding-left: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: none
}

.w_portfolio_checkbox.cc_states_filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.tb_case_study_category {
    display: none
}

.d_portfolio_text_filter {
    width: 40px;
    height: 40px
}

.fbi_text_filter {
    overflow: hidden;
    margin-bottom: 0;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: #fff;
    font-weight: 400
}

.fbi_text_filter:focus {
    border-color: transparent
}

.fb_portfolio_text_search {
    margin-bottom: 0
}

.d_spacing_cms_dependent {
    height: 189px
}

.cc_selected_option {
    box-shadow: 0 0 10px 2px #d0bbb3
}

.cc_selected_option.cc_hidden {
    display: none;
    box-shadow: 0 0 0 5px #1c5291
}

.w_quote_form {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100010;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .5)
}

.d_quote_form_content, .form_indv_prod_quote {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_quote_form_content {
    position: relative;
    z-index: 1;
    display: flex;
    overflow: auto;
    width: 80%;
    max-width: 600px;
    margin: 10vh auto;
    padding: 3%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.d_quote_form_content.cc_v2 {
    padding-top: 4%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.d_prod_quote_f_left, .form_indv_prod_quote {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column
}

.form_indv_prod_quote {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.d_prod_quote_f_left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    padding-top: 8%;
    padding-bottom: 10%;
    -ms-flex-direction: column;
    flex-direction: column
}

.d_prod_quote_f_right, .w_success_message {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.d_prod_quote_f_left.cc_v2 {
    width: 100%;
    padding-top: 0;
    padding-bottom: 5%
}

.d_prod_quote_f_right {
    display: grid;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.d_indv_prod_form_divider {
    width: 1px;
    height: 100%;
    margin-right: 4%;
    margin-left: 5%;
    background-color: #304659
}

.formb_indv_prod_quote {
    width: 100%;
    height: 100%;
    margin-bottom: 0
}

.h2_indv_prod_quote {
    margin-bottom: 2em;
    font-size: 2.571em;
    line-height: 116%
}

.d_option_name, .fbi_indv_prod {
    margin-bottom: 0;
    font-size: 1em
}

.d_options_holder {
    display: -ms-grid;
    display: grid;
    justify-items: start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 16px;
    -ms-grid-columns: minmax(50px, 197px) minmax(50px, 197px);
    grid-template-columns: minmax(50px, 197px) minmax(50px, 197px);
    -ms-grid-rows: 1fr 1fr;
    grid-template-rows: 1fr 1fr
}

.d_options_holder.cc_v2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-grid-columns: minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px);
    grid-template-columns: minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px);
    -ms-grid-rows: 1fr;
    grid-template-rows: 1fr
}

.d_indv_prod_option {
    position: relative;
    width: 100%
}

.d_close_overlay, .fbi_option_name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
}

.fbi_option_name {
    top: auto;
    display: none;
    margin-bottom: 0;
    padding: 6px 15px 6px 5px;
    border-top-right-radius: 80px;
    background-color: #d8d8d8;
    color: #3b5266;
    font-size: 1.2em;
    font-weight: 500
}

.fbi_option_name::-webkit-input-placeholder {
    color: #304659
}

.fbi_option_name:-ms-input-placeholder {
    color: #304659
}

.fbi_option_name::-ms-input-placeholder {
    color: #304659
}

.fbi_option_name::placeholder {
    color: #304659
}

.tb_indv_prod_quote_edit {
    margin-top: 30px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    border-bottom: 2px solid #000;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: .7em;
    font-weight: 500;
    text-transform: uppercase
}

.tb_tech_data_resources-2, .w_content.cc-centeredtext, .w_go_back_portfolio {
    -webkit-align-self: center;
    -ms-grid-row-align: center
}

.tb_indv_prod_quote_edit:hover {
    opacity: .7
}

.d_close_overlay {
    top: 0;
    z-index: 0
}

.fbi_indv_prod {
    padding-top: 21px;
    padding-bottom: 21px;
    background-color: #fff;
    line-height: 125%
}

.fbb_indv_prod_quote_sub {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #1c5291;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-size: 1em;
    line-height: 125%
}

.fbb_indv_prod_quote_sub:hover {
    background-color: #304659
}

.d_option_name {
    position: relative;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #e1e1e1;
    color: #1c5291;
    font-weight: 500
}

.d_option_name::-webkit-input-placeholder {
    color: #304659
}

.d_option_name:-ms-input-placeholder {
    color: #304659
}

.d_option_name::-ms-input-placeholder {
    color: #304659
}

.d_option_name::placeholder {
    color: #304659
}

.fbs_indv_prod_quote {
    height: 100%;
    padding: 0;
    background-color: #fafafa
}

.w_success_message {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.em_loader, .em_svg_container {
    -webkit-flex-direction: column
}

.tb_indv_prod_success_form {
    margin-bottom: 15px;
    letter-spacing: 1px;
    text-transform: uppercase
}

.fbe_indv_prod_quote {
    margin-top: 0
}

.d_project_completion {
    display: inline-block
}

.img_blog_placeholder {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.sp_cite {
    color: #d0bbb3;
    font-size: 16px
}

.em_svg_container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.d_line, .d_progress_indicator {
    -webkit-transform-origin: 0 50%;
    -ms-transform-origin: 0 50%
}

.img_portfolio_item {
    width: 100%;
    height: 400px;
    -webkit-filter: brightness(63%);
    filter: brightness(63%);
    -webkit-transition: -webkit-filter .2s ease;
    transition: -webkit-filter .2s ease;
    transition: filter .2s ease;
    transition: filter .2s ease, -webkit-filter .2s ease;
    -o-object-fit: cover;
    object-fit: cover
}

.s_portfolio_no_image {
    margin-bottom: 96px
}

.g_portfolio_no_img {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    grid-column-gap: 16px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.grid-2, .grid-3 {
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.h2_no_image_portfolio {
    text-align: center
}

.cpag_portfolio {
    margin-top: 15px
}

.gi_portfolio_imageless {
    display: block;
    height: 100%
}

.w_go_back_portfolio {
    position: fixed;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 10;
    -ms-flex-item-align: center;
    align-self: center
}

.w_progress_line {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    z-index: 10;
    display: none;
    width: 90%;
    height: 5px;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    background-color: #dae3eb;
    opacity: 0
}

.marker_pulse, .marker_shadow {
    position: absolute;
    right: 0
}

.img_indv_blog, .marker_pulse, .marker_shadow, .w_blog_share {
    left: 0;
    top: 0;
    bottom: 0
}

.div-block-4, .em_loader {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.d_progress_indicator {
    width: 100%;
    height: 100%;
    background-color: #1c5291;
    transform-origin: 0 50%
}

.em_loader {
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.div-block-4 {
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.marker_pulse {
    border-radius: 50%;
    box-shadow: 0 0 0 .6px rgba(48, 70, 89, .73)
}

.marker_wrapper {
    width: 25px;
    height: 25px
}

.marker {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #304659
}

.d_map_marker {
    position: relative
}

.img_indv_blog {
    position: absolute;
    right: 0
}

.w_blog_share {
    position: fixed;
    right: auto;
    z-index: 99;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.d_tooltip_icon, .w_indv_prod_tooltip {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column
}

.tb_share {
    display: inline-block;
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 1px
}

.w_blog_social_share_btns {
    display: none;
    margin-left: 22px
}

.btn_blog_social_share {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 16px;
  /*  background-image: url(); */
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

.materials-bg, .v_ripplebg {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0
}

.btn_blog_social_share:hover {
    opacity: .6
}

.btn_blog_social_share.cc_email {
    background-image: url(../images/icons/mail_icon.svg)
}

.btn_blog_social_share.cc_tweeter {
    background-image: url(../images/icons/twitter_icon.svg)
}

.btn_blog_social_share.cc_linkedin {
    background-image: url(../images/icons/linkedin_icon.svg)
}




.em_social_share {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.v_ripplebg {
    z-index: 1;
    overflow: visible;
    height: 100%;
    min-height: 700px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(77%, hsla(0, 0%, 100%, 0)), to(#fff));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 77%, #fff)
}



.img_materials {
    position: relative;
    display: none;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.gradient-overlay {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(280deg, rgba(0, 0, 0, .94) 46%, transparent 83%);
    opacity: .69
}

.w_indv_prod_tooltip {
    position: relative;
    z-index: 9999;
    display: inline-block;
    margin-left: 4px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.g_testimonialgrid, .w_line {
    margin-right: auto;
    margin-left: auto
}

.w_indv_prod_tooltip.cc_techdata {
    display: block
}

.d_tooltip_icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 14px;
    height: 14px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_next_page_mover, .w_tooltip_content {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.d_tooltip_icon.cc_tech_data {
    width: 100%;
    height: 100%
}

.img_tooltip {
    width: 100%
}

.w_tooltip_content {
    position: absolute;
    left: 50%;
    right: 0;
    bottom: auto;
    display: none;
    width: 222px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%)
}

.w_tooltip_content.cc-mounte {
    display: none
}

.w_tooltip_content.cc_tech_data {
    left: 100%
}

.w_tooltip_content.cc_mount_types {
    bottom: 14px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 450px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.d_tooltip_connector {
    border-top: 8px solid rgba(0, 0, 0, .9);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent
}

.d_tooltip_text {
    padding: 23px 24px 31px 29px;
    background-color: rgba(0, 0, 0, .9);
    color: #fff
}

.h5_tooltip_title {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 600
}

.p_tooltip_text {
    color: #fff;
    font-size: 13px;
    font-weight: 300
}

.btn_beige, .btn_beige:hover {
    border-color: #0038e3;
    color: #000;
}

.p_tooltip_text.cc_mounte {
    display: block
}

.w_content {
    position: relative;
    z-index: 9
}

.w_content.cc-centeredtext {
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center
}

.grid-2 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.c_type-content {
    margin-bottom: 10%;
    padding-right: 5%;
    padding-left: 5%
}

.c_type-content.cc-nbm, .slide {
    margin-bottom: 0
}



.d_testibtn, .d_testiprojectphoto {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.btn_beige {
    position: static;
    border-style: solid;
    border-width: 2px;
    background-color: #fff;
    -webkit-transition: color .2s ease, background-color .2s ease;
    transition: color .2s ease, background-color .2s ease;
    font-weight: 500
}

.btn_beige:hover {
    background-color: #0038e3;
    color: #fff;

}

.btn_beige.cc-moretopspace {
    margin-top: 5%
}

.btn_beige.cc-largebtn {
    padding: 15px 30px
}

.btn_beige.cc-xlbtn {
    display: inline-block;
    padding: 20px 45px
}

.btn_beige.cc-xlbtn.cc-installspace {
    display: none;
    margin-top: 35px
}

.btn_beige.cc-xlbtn.cc-hide {
    display: none
}

.btn_transparent {
    margin-top: 30px;
    border: 2px solid #fff;
    border-radius: 3px;
    background-color: transparent;
    -webkit-transition: color .2s ease, background-color .2s ease;
    transition: color .2s ease, background-color .2s ease;
    color: #fff
}

.btn_transparent:hover {
    background-color: #fff;
    color: #000
}

.icon-4, .icon-5 {
    color: #1c5291
}

.btn_transparent.cc-largebtn {
    padding: 15px 30px
}

.grid-3 {
    grid-column-gap: 39px
}

.w_portfolio_item_read-more {
    position: absolute;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: 12px;
    line-height: 1;
    cursor: none
}

.w_next_page_mover {
    position: relative;
    z-index: 999;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_sticky_next_p_loader {
    position: static;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_hidden, .icon-4, .icon-5 {
    display: none
}

.w_line {
    overflow: hidden;
    width: 90%;
    height: 4px;
    max-width: 100px;
    background-color: hsla(0, 0%, 100%, .3)
}

.d_line {
    width: 100%;
    height: 100%;
    background-color: #fff;
    transform-origin: 0 50%
}

.slide {
    padding-top: 2%;
    padding-bottom: 0
}

.sld_testimonial {
    margin-top: 4%;
    margin-bottom: 4%;
    background-color: #fff
}

.d_testibtn, .paragraph-3 {
    margin-top: auto;
    margin-bottom: auto
}

.g_testimonialgrid {
    width: 83%;
    justify-items: center;
    grid-column-gap: 29px;
    -ms-grid-columns: .5fr 1fr .5fr;
    grid-template-columns: .5fr 1fr .5fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}



.s_comres_hero, .sld_lftarrow, .sld_rtarrow {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}



.d_testibtn.cc_leftbtn {
    -webkit-transform: rotateX(0) rotateY(-180deg) rotateZ(0);
    transform: rotateX(0) rotateY(-180deg) rotateZ(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.sld_lftarrow, .sld_rtarrow {
    display: flex
}

.m_circle {
    overflow: hidden;
    max-height: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    border-radius: 100%
}

.m_circle.cc-commercial4 {
    position: absolute;
    top: 33%;
    right: 30%;
    z-index: -1;
    width: 300px;
    height: 300px
}



.s_comres_hero {
    position: relative;
    z-index: 2;
    display: flex;
    width: 100%;
    margin: 10% 0 18% auto;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.w_indv_prod_side_panel {
    position: absolute;
    left: 8%;
    top: 18%;
    right: auto;
    bottom: auto;
    z-index: 4
}

.d_indv_prod_side_panel_item {
    position: relative
}

.d_indv_prod_side_panel_trigger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 11px 11px 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000
}

.d_indv_prod_side_panel_content {
    position: absolute;
    left: 100%;
    top: 50%;
    right: auto;
    bottom: auto;
    display: none;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.w_sidepanel_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 228px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.d_hub_loc_beacon, .d_info_dot_beacon, .w_beacon_content, .w_tech_d_spec_d_tab_c_right-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.d_sidepanel_connector {
    width: 8px;
    height: 8px;
    border-style: solid;
    border-width: 8px;
    border-color: transparent #000 transparent transparent
}

.d_sidepanel_content {
    padding: 13px 14px 16px 16px;
    background-color: rgba(0, 0, 0, .9);
    color: #fff
}

.img_sidepanel {
    margin-bottom: 18.25px
}

.w_imdv_prod_image_info_dots {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: none
}

.w_imdv_prod_image_info_dots.cc-show {
    display: block
}

.w_info_dot_item {
    position: absolute;
    left: 80%;
    top: 23%;
    display: inline-block
}

.w_info_dot_item.cc_beacon2 {
    left: 84%;
    top: 50%
}

.w_info_dot_item.cc_beacon3 {
    left: 59%;
    top: 53%
}

.w_info_dot_item.cc_beacon3.cc-home1 {
    left: 60%;
    top: 20%
}

.w_info_dot_item.cc_bcool_beacon1 {
    left: 37%;
    top: 58%
}

.w_info_dot_item.cc_bcool_beacon2 {
    left: 69%;
    top: 43%
}

.w_info_dot_item.cc_bcore_2 {
    left: 67%;
    top: 36%
}

.w_info_dot_item.cc-home3 {
    left: 69%;
    top: 49%
}

.d_info_dot_beacon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%
}

.d_info_dot_beacon.cc_is_beaming {
    position: relative;
    display: block
}

.d_info_dot_beacon.cc_is_beaming.cc-hide, .w_beacon_content.cc_down_mobile, .w_beacon_content.cc_down_mobile.cc-hide {
    display: none
}

.d_beacon_circle {
    width: 14px;
    height: 14px;
    border-radius: 51%;
    background-color: #1c5291
}

.d_beacon_pulse {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.w_beacon_content {
    position: absolute;
    left: 50%;
    top: -11.46px;
    right: 0;
    bottom: auto;
    display: none;
    width: 222px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%)
}

.link-block, .m_imgreveal {
    bottom: 0;
    position: absolute
}

.w_indv_prods_btns {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    grid-column-gap: 30px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.w_indv_prods_btns.cc-topborder {
    padding-top: 5%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-top: 1px solid #e1e1e1
}

.icon_dropdown {
    position: static;
    margin-right: 0;
    margin-left: 9px;
    color: #000
}

.img_naddl_clip {
    width: 15px;
    height: 15px;
    margin-right: 11px
}

.d_tab_menu_active_indicator {
    width: 16px;
    height: 2px;
    margin-right: auto;
    margin-left: auto;
    background-color: #1c5291;
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease
}

.tl_indv_prod {
    margin-right: 1.285%;
    margin-left: 1.285%;
    padding-right: 14px;
    padding-left: 14px;
    background-color: transparent;
    opacity: .2;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    line-height: 162%
}

.tl_indv_prod.w--current {
    background-color: transparent;
    opacity: 1
}

.tb_tab_text {
    margin-bottom: 10px;
    font-size: 1.313em
}

.link-block {
    left: 0;
    top: 0;
    right: 0
}

.h3_leed_c_text_c_title-2 {
    margin-bottom: 10px;
    line-height: 120%;
    font-weight: 400
}

.tb_tech_data_resources-2 {
    margin-left: 18px;
    -ms-flex-item-align: center;
    align-self: center
}

.btn_-2 {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding: 10px 36.5px;
    border: 1px solid #000;
    background-color: #fff;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: #000;
    font-size: 1em;
    line-height: 147%;
    font-weight: 600;
    text-align: center
}

.btn_-2:hover {
    background-color: #000;
    color: #fff
}

.btn_-2.cc_tech_data_page {
    margin-bottom: 57px;
    margin-left: 50px;
    padding: 8px 14px
}

.btn_-2.cc_tech_data_page.cc_certifcates_download {
    margin-right: 24px;
    margin-bottom: 5px;
    margin-left: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.btn_-2.cc_tech_data_page.cc_cleaning_maintance {
    margin-top: 22px;
    margin-bottom: 0
}

.btn_-2.cc_tech_data_page.cc-custom {
    margin-top: 20px;
    margin-left: 0
}

.btn_-2.cc_tech_d_o_data_inner {
    margin-top: 14%;
    margin-right: 0;
    padding-right: 20px;
    padding-left: 20px
}

.btn_-2.cc_tdresources {
    margin-top: 4%;
    margin-left: 0
}

.m_scrollline, .sp_scroll {
    margin-right: auto;
    margin-left: auto
}

.w_tech_d_spec_d_tab_c_right-2 {
    display: block;
    max-width: 100%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.w_tech_d_spec_d_tab_c_right-2.cc_hide {
    display: none
}

.lb_tech_data_resources-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 28px;
    -webkit-transition: opacity .2s ease;
    transition: opacity .2s ease;
    font-size: 1em;
    line-height: 133%;
    font-weight: 300
}

.lb_tech_data_resources-2:hover {
    opacity: .7
}

.navddl_tech_data-2 {
    display: block;
    min-width: 300px;
    background-color: #fff
}

.navddl_tech_data-2.w--open {
    border-left: 1px solid #d3dce5
}

.img_baswa_cool {
    display: block;
    width: 100%;
    -o-object-fit: fill;
    object-fit: fill
}

.sp_scroll {
    display: block;
    font-size: 10px
}

.m_scrollline {
    display: block;
    overflow: hidden;
    width: 2px;
    height: 75px;
    margin-top: 20px;
    background-color: rgba(0, 0, 0, .1)
}

.m_scrollline.cc-light {
    background-color: hsla(0, 0%, 100%, .1)
}

.m_scrollline.cc-light.cc-pushright {
    margin-left: 20px;
    background-color: rgba(0, 0, 0, .1)
}

.m_imgreveal {
    left: -1%;
    top: 0;
    right: 0;
    z-index: 5;
    display: block;
    width: 102%;
    height: 102%;
    background-color: #fff;
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0
}

.w_headerimages {
    position: absolute;
    top: 140px;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.w_headerimages.cc-commercial {
    position: relative;
    z-index: 9;
    width: 70%;
    margin-top: -727px;
    margin-right: 0;
    margin-left: auto;
    padding-right: 5%;
    padding-left: 5%
}

.s_healthyarch {
    margin-top: -10%
}

.nav_mobile {
    display: none;
    width: 25px;
    height: 25px;
    background-image: url(../images/icons/hamburger-menu.svg);
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat
}

.img_heroframe.cc-f1, .img_heroframe.cc-f2, .img_heroframe.cc-f3, .img_heroframe.cc-f4, .img_heroframe.cc-f5, .img_heroframe.cc-f6, .img_heroframe.cc-f7 {
    background-position: 50% 0;
}

.w_contact_page_us_map {
    position: relative;
    margin-right: auto;
    margin-left: auto
}

.w_hub_loc_item {
    position: absolute;
    left: 87%;
    top: 23%;
    display: inline-block
}

.w_hub_loc_item.cc_hub_beacon1 {
    left: 13%;
    top: 69%
}

.w_hub_loc_item.cc_hub_beacon2 {
    left: 74%;
    top: 35%
}

.d_hub_loc_beacon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%
}

.d_hub_loc_beacon.cc_is_beaming {
    position: relative
}

.s_contact_reps {
    display: block
}

.fb_reps_filters, .w_reps_table_caption {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.w_reps_filters {
    margin-bottom: 0;
    padding-top: 5%;
    padding-bottom: 5%;
    border-top: 1px solid #dae3eb;
    border-bottom: 1px solid #dae3eb;
    text-align: center
}

.fb_reps_filters {
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent
}

.fbsel_reps_table, .w_form_reps {
    -webkit-box-flex: 0;
    margin-bottom: 0
}

.w_form_reps {
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.fbsel_reps_table {
    max-width: 250px;
    margin-right: 51px;
    padding-left: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border: 1px solid #000;
    background-color: #fff;
    font-size: 12px;
    letter-spacing: 1px
}

.fbsel_reps_table.cc_states {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-width: 1px;
    border-color: #000;
    font-size: 12px;
    font-weight: 500
}

.w_reps_table_caption {
    display: flex
}

.d_type_of_partner, .w_reps_caption_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    text-transform: uppercase
}

.w_reps_caption_item {
    display: flex;
    margin-right: 31px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_type_of_partner, .w_rep_name {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column
}

.d_type_of_partner {
    display: flex;
    width: 41px;
    padding: 4px 11.25px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3.16px;
    background-color: #000;
    color: #fff;
    font-size: 11.02px;
    line-height: 13px
}

.d_type_of_partner.cc_baswa_contact {
    background-color: #1c5291
}

.p_rep_caption {
    margin-bottom: 0;
    margin-left: 8px;
    font-size: 13px;
    line-height: 17px;
    font-weight: 500
}

.lo_panel {
    position: absolute;
    left: auto;
    right: -219px;
    bottom: -14%;
    width: 47vw;
    height: 800px;
    max-width: 100%;
    -webkit-transform: rotate(-54deg);
    -ms-transform: rotate(-54deg);
    transform: rotate(-54deg);
    -webkit-transform-origin: 50% 100%;
    -ms-transform-origin: 50% 100%;
    transform-origin: 50% 100%
}

.gi_rep, .grid-4 {
    position: relative
}

.lo_panel.cc-cta {
    bottom: -26%
}

.gi_rep {
    z-index: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 29.26%;
    min-height: 70px;
    margin-right: 1.5%;
    margin-bottom: 43px;
    margin-left: 1.5%;
    padding: 18px 19px 17px 14px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #dae3eb;
    border-radius: 3px;
    background-color: transparent
}

.grid-6, .w_scrollline.cc-center {
    margin-right: auto;
    margin-left: auto
}

.g_reps {
    margin-top: 2.7%;
    margin-bottom: 3.6%;
    grid-column-gap: 16px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.grid-6, .grid-7, .grid-8 {
    grid-column-gap: 100px;
    grid-row-gap: 100px
}

.grid-4, .grid-6 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.rep_address {
    display: none
}

.img_heroframe.cc-f1, .w_rep_name {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.lottie-animation {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.grid-6, .img_heroframe, .w_healthypanel, .w_rep_info, .w_scrollline {
    position: relative
}

.img_heroframe.cc-f1 , .img_heroframe.cc-f1Med {
    position: relative;
    display: flex;
    width: 517px;
    height: 756px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    /*  */
    background-size: cover
}
.img_heroframe.cc-f1 {
    background-image: url(../images/banner-gdtc-tunisia.jpeg);
}

.w_scrollline, .w_scrollline.cc-hiddenm {
    display: block
}

.img_heroframe.cc-f2 {
    height: 433px;
    /*background-image: url();*/
    background-size: cover
}

.img_heroframe.cc-f3 {
    height: 576px;
   /* background-image: url();*/
    background-size: cover
}

.img_heroframe.cc-f4 , .img_heroframe.cc-f4Med {
    height: 704px;
    max-height: 704px;
    /* background-image: url(../images/GDTC-dental-trainig.jpg); */
    background-size: cover;
    background-position: 80%;
}
.img_heroframe.cc-f4 {
    background-image: url(../images/GDTC-dental-trainig.jpg);  
}
.img_heroframe.cc-f5 , .img_heroframe.cc-f5Med {
    height: 608px;
    /* background-image: url(../images/gdtc-tunisia.jpeg); */
    background-size: cover;
    margin-top: 100px;
}
.img_heroframe.cc-f5 {
    background-image: url(../images/gdtc-tunisia.jpeg);
}

.img_heroframe.cc-f6 {
    height: 424px;
    max-width: 572px;
   /* background-image: url();*/
    background-size: cover;
    background-repeat: repeat
}

.img_heroframe.cc-f7 {
    height: 249px;
    max-height: 249px;
    max-width: 261px;
   /* background-image: url();*/
    background-size: cover
}

.img_heroframe.cc-f8 {
    height: 350px;
    margin-top: 50px;
   /* background-image: url(../images/gdtc-online-dental-conference.jpeg);*/
    background-position: 0 0;
    background-size: cover;
    background-position: 40%;
}

.img_heroframe.cc-commercial-1 {
    height: 608px;
  /*  background-image: url();*/
    background-position: 50% 0;
    background-size: cover
}

.img_heroframe.cc-commercial-2 {
    height: 418px;
  /*  background-image: url();*/
    background-position: 0 50%;
    background-size: cover
}

.img_heroframe.cc-commercial-5 {
    top: -179px;
    height: 250px;
   /* background-image: url();*/
    background-position: 0 0;
    background-size: cover
}

.img_heroframe.cc-commercial-3 {
    height: 418px;
    max-height: 418px;
    /*background-image: url(); */
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.img_heroframe.cc-reshero-1 {
    height: 608px;
    /*background-image: url();*/
    background-position: 50% 0;
    background-size: cover
}

.img_heroframe.cc-reshero-3, .img_heroframe.cc-reshero_2 {
    height: 418px;
    max-height: 418px;
    background-position: 50% 0;
    background-size: cover
}

.img_heroframe.cc-reshero_2 {
  /*  background-image: url()*/
}

.img_heroframe.cc-reshero-3 {
 /*   background-image: url()*/
}

.img_heroframe.cc-installhero-1 {
    height: 608px;
  /*  background-image: url();*/
    background-position: 50% 0;
    background-size: cover
}

.img_heroframe.cc-installhero-2, .img_heroframe.cc-installhero-3 {
    height: 418px;
    background-position: 50% 0;
    max-height: 418px;
    background-size: cover
}

.img_heroframe.cc-installhero-2 {
   /* background-image: url()*/
}

.img_heroframe.cc-installhero-3 {
   /* background-image: url()*/
}

.img_heroframe.cc-res1 {
    height: 608px;
  /*  background-image: url();*/
    background-position: 50% 0;
    background-size: cover
}

.img_heroframe.cc-residential3 {
    height: 418px;
   /* background-image: url();*/
    background-position: 0 50%;
    background-size: cover
}

.img_heroframe.cc-residential2 {
    height: 418px;
    max-height: 418px;
   /* background-image: url();*/
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.lb_socialicon, .lb_socialicon.cc-ig {
    background-size: contain;
    height: 20px
}

.grid-6 {
    right: auto;
    width: 100%;
    max-width: 1600px;
    justify-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.grid-7 {
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto
}

.grid-8 {
    margin-top: 507px
}

.w_scrollline {
    top: 66px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.w_scrollline.cc-center {
    top: 0;
    margin-top: 10%
}

.w_healthypanel {
    overflow: hidden;
    padding-top: 20%;
    padding-bottom: 15%
}

.grid-9 {
    -ms-grid-columns: .25fr 1fr;
    grid-template-columns: .25fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.w_rep_name {
    display: flex;
    margin-right: 12px;
    margin-left: 16px;
    -ms-flex-direction: column;
    flex-direction: column
}

.c_inner, .g_commercialhero {
    margin-right: auto;
    margin-left: auto
}

.d_rep_tooltip_trigger, .w_rep_info {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.h4_rep_name {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1em;
    line-height: 116%;
    font-weight: 500
}

.tb_rep_job {
    margin-top: 3px;
    font-size: 1em;
    line-height: 116%;
    font-weight: 400
}

.d_rep_tooltip_trigger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_rep_trigger_dot {
    width: 3px;
    height: 3px;
    margin-top: 1.5px;
    margin-bottom: 1.5px;
    border-radius: 50%;
    background-color: #000
}

.d_rep_list_gutter {
    display: none;
    width: 3.17%;
    height: 43px
}

.w_rep_info {
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: auto;
    padding: 2px 7px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_rep_info_content {
    position: absolute;
    left: 50%;
    top: 0;
    right: 0;
    bottom: auto;
    display: none;
    width: 222px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(-50%, -100%);
    -ms-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%)
}

.w_rep_info_item {
    margin-bottom: 15px
}

.tb_rep_regions {
    display: none
}

.d_techdataheader {
    max-width: 1440px
}

.d_tdresources {
    display: block;
    margin-top: 2%
}

.d_tdresources.cc-testdatatable {
    display: none
}

.grid-10, .w_subscribeform {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.grid-10 {
    display: flex;
    width: 100%;
    grid-column-gap: 30px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.g_tdvideo {
    grid-row-gap: 29px;
    -ms-grid-rows: auto auto auto auto;
    grid-template-rows: auto auto auto auto
}

.g_tdspecdetail, .grid-11 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.g_tdvideo.cc_addvideo {
    max-width: 600px;
    grid-column-gap: 29px;
    grid-row-gap: 17px;
    -ms-grid-columns: 1fr .5fr;
    grid-template-columns: 1fr .5fr
}

.d_speccontent {
    text-align: right
}

.g_tdspecdetail {
    grid-column-gap: 40px
}

.w_bottombar {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #000
}

.grid-11 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: .25fr 1fr;
    grid-template-columns: .25fr 1fr
}

.lb_socialicon {
    filter: invert(1);
    margin-right: 10px;
    width: 20px;
    background-image: url(../images/icons/linkedin.svg);
    background-position: 0 0;
    background-repeat: no-repeat
}

.lb_socialicon.cc-fb {
    width: 11px;
    background-image: url(../images/icons/facebook.svg)
}



.lb_socialicon.cc-twitter {
    width: 25px;
    height: 20px;
    background-image: url(../images/icons/twitter_light.svg)
}



.lb_socialicon.cc-yt {
    width: 24px;
    background-image: url(../images/icons/youtube.svg)
}

.lb_socialicon.cc-ig {
    width: 24px;
    background-image: url(../images/icons/instagram.svg)
}

.g_commercialhero, .g_cta {
    width: 100%;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.grid-12 {
    grid-column-gap: 20px;
    -ms-grid-columns: auto auto auto auto auto 1fr 1fr;
    grid-template-columns: auto auto auto auto auto 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.sp_bottomfooterright {
    color: #fff;
    text-align: right
}

.text-block {
    line-height: 150%;
    font-weight: 400
}

.w_subscribeform {
    display: flex;
    padding: 10%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-left: 1px solid #e1e1e1
}

.c_newsletter {
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.g_cta {
    position: relative;
    grid-column-gap: 60px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.d_rep_item_connector {
    border-top: 8px solid rgba(0, 0, 0, .9);
    border-right: 8px solid transparent;
    border-left: 8px solid transparent
}

.portgradientoverlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .7)), color-stop(42%, transparent));
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .7), transparent 42%)
}

.g_commercialhero {
    position: relative;
    right: auto;
    max-width: 1600px;
    justify-items: stretch;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-column-gap: 0;
    grid-row-gap: 100px;
    -ms-grid-columns: .25fr 1fr;
    grid-template-columns: .25fr 1fr
}

.c_inner, .c_map {
    max-width: 1440px;
    font-weight: 500
}

.c_inner.cc-zeroppading, .c_map.cc-zeroppading {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_ripple {
    position: absolute;
    left: -25%;
    top: -41%;
    right: auto;
    bottom: 0;
    width: 1000px;
    height: 1000px
}

.c_homeleft, .c_inner, .c_inner.cc-zeroppading.cc_indv_case_study {
    position: relative
}

.c_homeleft {
    z-index: 2
}

.c_homeleft.cc-install {
    z-index: 9999
}

.g_commercialinnerhero {
    grid-column-gap: 100px;
    grid-row-gap: 100px;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.g_tdcleaning, .g_techspec, .grid-14 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.c_inner {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px
}

.c_inner.cc-zeroppading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    -ms-flex-direction: column;
    flex-direction: column
}

.c_inner.cc-zeroppading.cc_hidden {
    display: none
}

.w_currently_selected_color {
    position: relative;
    width: 25px;
    height: 25px;
    margin-left: 5px
}

.d_color_connector {
    position: absolute;
    left: 5%;
    top: 50%;
    border-top: 5px solid transparent;
    border-right: 10px solid #1c5291;
    border-bottom: 5px solid transparent;
    -webkit-transform: translate(-100%, -50%);
    -ms-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%)
}

.c_map, .c_map.cc-zeroppading.cc_indv_case_study, .d_current_color {
    position: relative
}

.d_current_color {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #f6f6f6
}

.c_map {
    width: 100%;
    margin: 5% auto 10%;
    padding-right: 40px;
    padding-left: 40px
}

.c_map.cc-zeroppading {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    -ms-flex-direction: column;
    flex-direction: column
}

.c_map.cc-zeroppading.cc_hidden {
    display: none
}

.d_contactrep {
    margin-left: 8%
}

.fbi_contactclean {
    border-style: solid;
    border-width: 1px;
    border-color: transparent transparent #000;
    color: #000;
    font-size: 1em
}

.form-block {
    margin-top: 25px
}

.g_techspec {
    margin-top: 20px;
    grid-column-gap: 39px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr
}

.heading {
    font-size: 3.7em;
    font-weight: 500
}

.heading-2 {
    margin-bottom: 20px
}

.d_basic {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.cc-techdata {
    text-align: left
}

.image {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch
}

.m_projecttitle, .w_vertprojecttitle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-pack: end
}

.cc-blogfilter {
    margin-bottom: 0;
    margin-left: 15px;
    font-size: 16px;
    font-weight: 600
}

.w_inner {
    max-width: 800px;
    margin: 10% auto;
    padding-right: 40px;
    padding-left: 40px
}

.w_styles {
    margin-top: 10%
}

.h_large {
    font-size: 5em;
    font-weight: 600
}

.h_large.cc-center {
    text-align: center
}

.h_large.cc-white {
    color: #fff
}

.heading-3, .heading-4 {
    font-size: 4em
}

.h_xlarge {
    font-size: 7em;
    line-height: 120%;
    font-weight: 600
}

.w_vertprojecttitle {
    position: absolute;
    left: -35px;
    top: auto;
    right: auto;
    bottom: 10px;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
    font-family: Flama, sans-serif;
    color: #d0bbb3;
    font-size: 1em;
    font-weight: 500;
    text-transform: uppercase
}

.w_vertprojecttitle.cc-adjust {
    left: -106px
}

.w_vertprojecttitle.cc-adjust2 {
    left: -135px;
    bottom: 103px
}

.sp_verttext {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.m_projecttitle {
    display: flex;
    overflow: hidden;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.sp_heroprojecttitle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-transform: none
}

.a_stickynavlink, .tabl_products {
    letter-spacing: 1px;
    text-transform: uppercase
}

.w_stickynav {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    display: block
}

.d_tdothertest, .nav_stickymenu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.nav_stickymenu {
    display: flex;
    float: none;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start
}

.btn_black, .image-2 {
    -webkit-align-self: center;
    -ms-flex-item-align: center
}

.a_stickynavlink {
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
    padding: 0;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
    color: rgba(0, 0, 0, .35);
    font-size: .9em;
    font-weight: 500;
    text-align: left
}

.a_stickynavlink.w--current {
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    color: #000;
    font-size: .9em
}

.grid-13 {
    grid-column-gap: 0;
    -ms-grid-columns: 150px 1fr;
    grid-template-columns: 150px 1fr
}

.grid-13.cc-techdata {
    grid-column-gap: 50px
}

.grid-13.cc_faq {
    width: 100%;
    max-width: 1440px;
    margin-top: 120px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 40px;
    padding-left: 40px;
    grid-column-gap: 60px;
    -ms-grid-columns: 220px 1fr;
    grid-template-columns: 220px 1fr
}

.w_altcards {
    margin-top: 10%;
    padding-left: 5%
}

.w_altcards.cc-ntm {
    margin-top: 0;
    width: 100%;
}

.cc-tdintrop, .grid-17 {
    margin-top: 20px
}

.nav_sticky {
    background-color: transparent
}

.g_tdcleaning {
    margin-bottom: 40px;
    grid-column-gap: 40px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr
}

.heading-5 {
    text-align: left
}

.grid-14 {
    margin-bottom: 3%
}

.grid-15 {
    padding-left: 50px;
    grid-row-gap: 31px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.d_products_2items_row, .g_product, .g_resbtngroup, .grid-16, .grid-18, .grid-19 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.grid-16 {
    grid-row-gap: 4px
}

.image-2 {
    -ms-grid-row-align: center;
    align-self: center
}

.list {
    padding-left: 0
}

.cc-h2td {
    font-size: 2.5em;
    font-weight: 400
}

.grid-17 {
    grid-column-gap: 50px;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr
}

.d_tdothertest {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.tab_products, .w_prods_clean_item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.s_fwheader {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 10%;
    background-color: #000;
    color: #fff
}



.closepop, .img_techdata {
    background-position: 0 0
}

.img_products_hero {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.p_products_hero_v2 {
    max-width: 584px;
    margin-top: 37px;
    margin-bottom: 0
}

.s_products_tab_v2 {
    margin-top: 60px;
    margin-bottom: 120px
}

.tab_products {
    display: flex
}

.tab-link-tab-1 {
    display: block
}

.tabl_products {
    display: block;
    padding: 5px 0;
    background-color: transparent;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    color: silver;
    font-size: .9em
}

.tabl_products.w--current {
    background-color: transparent;
    color: #000
}

.tabc_products {
    margin-left: 49.45px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.w_product_item {
    position: relative;
    overflow: hidden;
    margin-bottom: 53px;
    padding-top: 56px;
    padding-bottom: 56px;
    padding-left: 70px;
    border: 1px solid #e1e1e1
}

.w_product_item.cc_reduced {
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 40px
}

.w_product_item.cc-half {
    padding-top: 10%;
    padding-bottom: 50%;
    padding-left: 40px
}

.d_products_content {
    max-width: 350px
}

.d_products_content.cc_hybrid {
    max-width: 304px
}

.p_products_item {
    margin-bottom: 0
}

.p_products_item.cc_smaller {
    font-size: 1em
}

.p_products_item.cc_clean_item {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 1em
}

.h1_products_items {
    margin-bottom: 0;
    line-height: 211%;
    font-weight: 600
}

.h1_products_items.cc_super_small {
    line-height: 120%
}

.img_products_item {
    position: absolute;
    left: auto;
    top: 24%;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 52%;
    max-width: 66.89%;
    -webkit-transform: rotate(70.12deg);
    -ms-transform: rotate(70.12deg);
    transform: rotate(70.12deg)
}

.img_products_item.cc_baswa_cool {
    top: 42%;
    right: -9%;
    width: 100%;
    max-width: 375px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
}

.img_products_item.cc_baswa_hybrid {
    top: -14%;
    right: -19%;
    width: 75%;
    max-width: none;
    -webkit-transform: translate(0, -12%);
    -ms-transform: translate(0, -12%);
    transform: translate(0, -12%)
}

.d_products_2items_row {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.w_products_finishes_item {
    position: relative;
    overflow: hidden;
    min-height: 333px;
    padding-top: 51px;
    padding-right: 15px;
    padding-left: 40px;
    border: 1px solid #e1e1e1;
    -webkit-transition: border .5s ease;
    transition: border .5s ease
}

.w_products_finishes_item:hover {
    border-color: #9c9c9c
}

.h2_products_finishes_item {
    font-weight: 600
}

.w_finishes_text_content {
    max-width: 250px
}

.p_finishes_item {
    max-width: 199px;
    margin-bottom: 0;
    font-size: 1em
}

.img_finish_item {
    position: absolute;
    left: auto;
    top: auto;
    right: -15.74%;
    bottom: -24%;
    z-index: -1;
    width: 300px;
    max-width: 70%
}

.w_prods_clean_item {
    position: relative;
    display: flex;
    overflow: hidden;
    border: 1px solid #e1e1e1
}

.lb_backtoportfolio, .w_prodimg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_prods_clean_content {
    max-width: 550px;
    padding: 10% 10% 31%
}

.d_prods_clean_content.cc_smaller {
    max-width: 46%
}

.d_prods_clean_content.cc_hybrid {
    max-width: 29.86%
}

.w_prodimg {
    position: absolute;
    left: auto;
    top: auto;
    right: -7%;
    bottom: -15%;
    display: flex;
    overflow: hidden;
    width: 275px;
    height: 275px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-radius: 200%
}

.w_prodimg.cc-fullwidth {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 250px;
    border-radius: 0
}

.img_product {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.burger-line-bottom, .burger-line-middle, .burger-line-top {
    width: 30px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #000
}

.tabm_products {
    position: -webkit-sticky;
    position: sticky;
    top: 50px;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start
}

.burger-button-wrapper {
    padding: 10px
}

.burger-line-bottom {
    margin-left: auto
}

.img_navbar_menu {
    display: none
}

.image-3 {
    -o-object-fit: fill;
    object-fit: fill
}

.cc-lbs {
    margin-bottom: 10px
}

.lb_backtoportfolio {
    position: relative;
    display: flex;
    width: 50px;
    height: 50px;
    margin-right: auto;
    margin-bottom: 50px;
    margin-left: auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border: 2px solid #000;
    border-radius: 200%;
    background-color: #fff;
    -webkit-transition: background-color .2s ease;
    transition: background-color .2s ease
}

.w_next_page_preview, .w_next_page_preview_content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    z-index: 1
}

.i_backport {
    width: 20px;
    height: 20px
}

.s_altcardtitle {
    margin-top: -104px;
    padding-bottom: 5%
}

.w_next_page_preview {
    position: relative;
    top: 0;
    display: flex;
    min-height: 582px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    background-color: #000
}

.btn_black.cc-xlbtn.cc-install, .d_sticky, .w_next_page_preview_content, .w_productblock {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.img_next_page_preview {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: .5;
    -o-object-fit: cover;
    object-fit: cover
}

.w_next_page_preview_content {
    position: relative;
    display: flex;
    max-width: 810px;
    margin-right: auto;
    margin-left: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    text-align: center
}

.d_sticky, .w_breadcrumbs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.h1_next_page_preview {
    color: #fff;
    font-size: 3.776em;
    line-height: 107%
}

.h1_next_page_preview.cc_blog_preview {
    color: #fff
}

.h4_next_page {
    font-size: 1.1em
}

.d_sticky {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.d_white_overlay {
    position: relative;
    z-index: 1;
    height: 100vh;
    background-color: #fff
}

.w_mobilebgcover {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: none;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .9)), to(rgba(0, 0, 0, .9)));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .9), rgba(0, 0, 0, .9));
    opacity: 0
}

.sp_stickymenuname {
    display: none
}

.i_quality {
    display: block;
    margin-bottom: 20px
}

.i_quality.cc-upkeep {
    position: relative;
    left: -10px
}

.i_quality.cc-quality {
    position: relative;
    left: -4px;
    margin-top: -14px
}

.btn_black, .btn_black.cc-xlbtn {
    position: static;
    -ms-grid-row-align: center
}

.i_quality.cc-limitless {
    display: block;
    margin-left: -7px
}

.g_resbtngroup {
    grid-column-gap: 24px;
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr
}

.g_resbtngroup.cc-right {
    -ms-grid-columns: 1fr auto;
    grid-template-columns: 1fr auto
}

.btn_black {
    align-self: center;
    border: 2px solid #000;
    background-color: #000;
    -webkit-transition: background-color .2s ease, color .2s ease;
    transition: background-color .2s ease, color .2s ease;
    color: #fff;
    font-weight: 500
}

.btn_black.cc-xlbtn, .btn_blueshare {
    -webkit-align-self: center;
    -ms-flex-item-align: center
}

.btn_black.cc-xlbtn.cc-btnsample, .btn_black:not(.disabledButton):hover {
    background-color: transparent !important;
    color: #000 !important;
}
.disabledButton{
cursor: default;
}

.btn_black.cc-vertical, .btn_blueshare {
    -webkit-transition: background-color .2s ease, color .2s ease
}

.btn_black.cc-xlbtn {
    padding: 20px 45px;
    align-self: center;
    text-align: center
}

.a_catlink, .heading-7 {
    text-align: left
}

.btn_black.cc-xlbtn.cc-techbtn {
    padding-right: 30px;
    padding-left: 30px
}

.btn_black.cc-xlbtn.cc-btnsample {
    border-color: #d0bbb3
}

.btn_black.cc-xlbtn.cc-btnsample:hover {
    background-color: #d0bbb3;
    color: #000
}

.btn_black.cc-xlbtn.cc-course {
    display: inline-block;
    margin-top: 30px
}

.btn_black.cc-course, .btn_black.cc-mts {
    margin-top: 20px
}

.btn_black.cc-xlbtn.cc-course.cc-block {
    display: block;
    margin-bottom: 40px;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto
}

.btn_blueshare, .btn_blueshare.cc-xlbtn, .d_awardtitle, .d_repfeedback {
    -ms-grid-row-align: center
}

.btn_black.cc-xlbtn.cc-install {
    display: block;
    margin-right: 0;
    margin-left: 0;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -o-object-fit: fill;
    object-fit: fill
}

.btn_black.cc-vertical {
    position: relative;
    transition: background-color .2s ease, color .2s ease;
    cursor: pointer
}

.a_bccurrentpage.w--current, .a_bcparent {
    position: static
}

.w_breadcrumbs {
    display: flex;
    width: 100%;
    height: auto;
    padding-bottom: 5%;
    opacity: .43;
    font-size: .8em
}

.nav_menu_content, .w_productblock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.sp_bcslash {
    margin-right: 9px;
    margin-left: 9px;
    color: #d0bbb3
}

.a_bcparent {
    border-bottom: 1px solid transparent;
    -webkit-transition: border .2s ease;
    transition: border .2s ease
}

.a_bcparent:hover {
    border-bottom: 1px solid #d0bbb3
}

.nav_menu_content {
    display: flex;
    width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.g_product {
    grid-column-gap: 48px;
    -ms-grid-columns: 1.5fr 1fr;
    grid-template-columns: 1.5fr 1fr
}

.grid-18, .grid-20 {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.cc-hide {
    display: none
}

.h_productlink {
    margin-top: -20px;
    margin-bottom: 30px;
    color: #1c5291
}

.w_productgallery {
    display: block;
    margin-top: 20px
}

.w_productgallery.cc-hide {
    display: block
}

.tabs-3 {
    display: none
}

.grid-19 {
    grid-column-gap: 30px
}

.cc-sbold {
    font-weight: 600
}

.a_catlink, .h_blogcategory {
    font-size: .7em;
    font-weight: 500;
    text-transform: uppercase
}

.d_infinite_scroll_white_overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    -webkit-transform: scale3d(1, 0, 1);
    transform: scale3d(1, 0, 1);
    -webkit-transform-origin: 50% 0;
    -ms-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.grid-20 {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto
}

.g_mounttip, .grid-23, .grid-25, .grid-26 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.d_acousticdata {
    padding-top: 5%
}

.grid-21 {
    grid-column-gap: 36px;
    grid-row-gap: 36px
}

.w_productblock {
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 333px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid #e1e1e1;
    -webkit-transition: border .5s ease;
    transition: border .5s ease
}

.closepop, .img_awardbadge {
    position: absolute;
    left: auto;
    bottom: auto
}

.w_productblock:hover {
    border-color: #9c9c9c
}

.w_productblock.cc-mbs {
    padding-bottom: 120px
}

.w_productblock.cc-mbs2 {
    padding-bottom: 175px
}

.c_productcontent {
    padding: 10%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.c_productcontent.cc-condensedwidth {
    max-width: 400px
}

.c_productcontent.cc-condensedwidth2 {
    max-width: 600px;
    padding: 5%
}

.closepop {
    top: 2%;
    right: 1%;
    width: 35px;
    height: 35px;
    background-image: url(../images/icons/5e2f37a3ffa5ea7e5c28c663_np_x_2500110_FFFFFF.svg);
    background-size: contain;
    cursor: pointer
}

.img_awardbadge {
    top: -6%;
    right: 7%;
    width: 150px
}

.h_blogcategory {
    color: #1c5291;
    letter-spacing: 2px
}

.h_blogcategory.cc-white {
    color: #fff
}

.a_readmore {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.linkline {
    width: 50px;
    height: 2px;
    margin-top: 3px;
    margin-left: 10px;
    background-color: #d0bbb3
}

.h_blogtitle {
    min-height: 100px
}

.a_catlink {
    margin-right: 0;
    margin-bottom: 5px;
    margin-left: 0;
    padding: 0;
    -webkit-transition: color .5s ease;
    transition: color .5s ease;
    color: rgba(0, 0, 0, .35);
    letter-spacing: 1px
}

.a_catlink:hover, .cc-dark {
    color: #000
}

.a_catlink.w--current {
    -webkit-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    transform-origin: 100% 50%;
    color: #000
}

.sp_copyurl {
    padding: 9px 15px;
    font-size: 1em
}

.sp_copyurl.cc-vertical {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg)
}

.btn_blueshare {
    align-self: center;
    border: 2px solid #000;
    background-color: #000;
    transition: background-color .2s ease, color .2s ease;
    color: #fff
}

.btn_blueshare:hover {
    background-color: transparent;
    color: #000
}

.btn_blueshare.cc-xlbtn {
    padding: 20px 45px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center
}

.btn_blueshare.cc-mts {
    margin-top: 20px
}

.btn_blueshare.cc-vertical {
    position: relative;
    border-color: #1c5291;
    background-color: #1c5291;
    -webkit-transition: background-color .2s ease, color .2s ease;
    transition: background-color .2s ease, color .2s ease;
    cursor: pointer
}

.btn_blueshare.cc-vertical:hover {
    background-color: #fff;
    color: #1c5291
}

.grid-22 {
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-column-gap: 50px
}

.b_selector {
    padding: 14px;
    text-align: center
}

.sp_selectortext {
    padding-top: 5px;
    font-weight: 400
}

.lightbox-link {
    display: block
}

.image-4, .image-5, .image-6, .image-7 {
    display: none
}

.d_awardentry, .d_mounttip {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-box-orient: horizontal
}

.lightbox-link-2, .lightbox-link-3, .lightbox-link-4 {
    border-bottom-width: 0
}

.cc-underlinetitle, .w_techdatablock, .w_underlinetitles {
    border-bottom: 1px solid #e1e1e1
}



.grid-23 {
    margin-top: 5%;
    grid-column-gap: 48px;
    -ms-grid-columns: 1.5fr 1fr;
    grid-template-columns: 1.5fr 1fr
}

.cc-underlinetitle {
    margin-bottom: 5%;
    padding-bottom: 20px
}

.w_underlinetitles {
    padding-bottom: 5px
}

.w_stage {
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1
}

.w_techdatablock {
    padding: 10% 5%
}

.d_awardbadge, .div-block-6 {
    border-style: solid;
    border-color: #d0bbb3;
    border-radius: 140px
}

.w_techdatablock.cc-pt {
    padding-top: 10%
}

.b_content.cc-edu {
    max-width: 600px
}

.grid-24 {
    grid-column-gap: 58px
}

.d_mounttip {
    display: flex;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start
}

.g_mounttip {
    grid-column-gap: 0;
    -ms-grid-columns: 3fr .25fr;
    grid-template-columns: 3fr .25fr
}

.lb_mount {
    margin-top: 15px
}

.grid-25 {
    display: block;
    grid-column-gap: 40px
}

.s_awards {
    overflow: hidden;
    margin: 5% auto
}

.cc-light {
    color: #fff
}

.grid-26 {
    grid-column-gap: 0;
    grid-row-gap: 38px;
    -ms-grid-columns: .5fr 1fr;
    grid-template-columns: .5fr 1fr
}

.grid-27, .grid-28, .grid-29, .grid-30 {
    grid-column-gap: 50px
}

.cc-awardgrid {
    margin-bottom: 10px;
    text-align: right
}

.d_awardentry {
    display: flex;
    padding-right: 0;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_awardbadge, .d_customizer_form.cc_finish, .d_thickness_option, .div-block-6 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.div-block-6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: 80px;
    max-width: 80px;
    min-height: 80px;
    min-width: 80px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-width: 3px
}

.cc-awardcircle {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 1.1em;
    line-height: 100%;
    text-align: center
}

.d_awardbadge, .d_product_setting_dropdown_trigger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.cc-awardp, .cc-righttext, .d_awardlead {
    text-align: right
}

.d_awardbadge {
    display: flex;
    width: 50px;
    height: 50px;
    max-height: 0;
    max-width: 0;
    min-height: 50px;
    min-width: 50px;
    margin-right: 20px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-width: 2px
}

.cc-awarddate, .cc-awardtitle {
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 5px
}

.cc-awardtitle {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #000;
    text-align: -webkit-center;
    margin-top: 20px;
}

.d_awardtitle {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center
}

.l_award {
    font-size: 16px;
    font-weight: 500
}

.d_awardlead {
    max-width: 350px;
    margin-right: 25px;
    margin-left: 5%;
    padding-right: 25px;
    border-right: 1px solid #e1e1e1
}

.cc-awarddate {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    color: #1c5291
}

.d_product_setting_dropdown_trigger {
    display: flex;
    padding: 14px 14.14px 17.14px 26.9px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: .97px solid #e1e1e1;
    border-bottom: .97px solid #e1e1e1;
    opacity: 1
}

.d_customizer_form, .d_thickness_option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_product_setting_content {
    overflow: hidden;
    height: 0;
    background-color: #f9f9f9;
    -webkit-perspective-origin: 50% 0;
    perspective-origin: 50% 0;
    -webkit-transition: all .8s ease;
    transition: all .8s ease
}

.d_product_setting_content.cc_extended {
    height: 175px
}

.d_thickness_option {
    position: relative;
    display: flex;
    width: 27.8%;
    margin-top: 15px;
    margin-bottom: 20px;
    padding: 25px 16px 12.6px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    box-shadow: 0 0 10px 0 transparent;
    -webkit-transition: all .4s ease;
    transition: all .4s ease
}

.d_thickness_option.cc_active_option {
    background-color: #fff;
    box-shadow: 2px 5px 16px -8px rgba(0, 0, 0, .16);
    color: #1c5291
}

.form_r_btn_product_custom {
    width: 16px;
    height: 16px;
    margin-top: 0;
    margin-left: 0;
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    border-color: #000
}

.form_r_btn_product_custom.w--redirected-checked {
    width: 16px;
    height: 16px;
    border-width: 1px;
    border-color: transparent;
    background-image: url(../images/icons/check_vector.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.form_r_btn_product_custom.w--redirected-focus {
    box-shadow: none
}

.form_r_btn_product_custom.cc_finsih {
    margin-right: 6px;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0
}

.radion_label_thickness {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2
}

.d_customizer_form {
    display: flex;
    padding-right: 8.2%;
    padding-left: 8.2%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.d_finish_options, .d_thickness_label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    position: relative
}

.d_customizer_form.cc_finish {
    padding-top: 15px;
    padding-left: 26.9px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}

.radio_btn_text {
    font-weight: 400
}

.fb_customizer, .form-block-2 {
    margin-bottom: 0
}

.img_arrow_dropdown {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: all .8s ease;
    transition: all .8s ease
}

.img_arrow_dropdown.cc_rotated, .img_arrow_dropdown.cc_thickness {
    -webkit-transform: rotate(-179deg);
    -ms-transform: rotate(-179deg);
    transform: rotate(-179deg)
}

.d_finish_options {
    display: flex;
    margin-bottom: 13px;
    padding-left: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_prod_options_trigger_right, .w_reptablehead, .w_tech_data_tooltip {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.d_finish_options.cc_active_option {
    color: #1c5291
}

.d_thickness_label {
    z-index: 2;
    display: flex;
    margin-top: 16.66px;
    margin-bottom: 16px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_product_setting_color, .w_prod_options_trigger_left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.div_fw, .vid_resource {
    margin-bottom: 5%
}

.img_color_customization {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.d_product_setting_color {
    position: relative;
    display: flex;
    padding: 14px 14.14px 17.14px 26.9px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top: .97px solid #e1e1e1;
    border-bottom: .97px solid #e1e1e1
}

.d_color_preview {
    width: 17px;
    height: 17px;
    margin-left: 7px;
    border-radius: 50%;
    background-color: #f6f6f6
}

.w_prod_options_trigger_left {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.d_color_picker_absolute, .w_prod_options_trigger_right {
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.d_color_picker_absolute {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding-right: 14.14px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.tb_selected_option {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 1px solid #d8d8d8;
    color: #1c5291;
    font-size: 12px;
    text-transform: none
}

.w_prod_options_trigger_right {
    position: relative;
    display: flex;
    width: 16px;
    height: 16px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.lb_download, .w_reptablehead {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.lottie_checkmarck_old {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.lottie_checkmarck {
    position: absolute;
    width: 100%;
    height: 100%
}

.grid-27 {
    grid-row-gap: 20px;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.lb_download {
    display: flex;
    margin-top: 25px;
    padding: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: solid;
    border-width: 2px;
    border-color: #e1e1e1;
    -webkit-transition: border .5s ease;
    transition: border .5s ease
}

.lb_download:hover {
    border-color: #000
}



.d_iconblock, .i_btt {
    background-position: 50% 50%;
    background-repeat: no-repeat
}

.grid-28 {
    grid-row-gap: 50px;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.grid-29, .grid-30 {
    grid-row-gap: 20px
}

.grid-31, .grid-32 {
    grid-column-gap: 0;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.div_fw {
    width: 100%;
    height: 1px;
    margin-top: 5%;
    background-color: #e1e1e1
}

.a_underline {
    border-bottom: 2px solid #1c5291
}

.btn_category {
    position: relative
}

.html-embed, .w_tech_data_tooltip {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    right: 0
}

.w_tech_data_tooltip {
    display: inline-block;
    width: 100%;
    margin-left: 4px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_tech_data_tooltip.cc_techdata {
    display: block;
    padding-bottom: 15px;
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.s_techresources {
    margin-top: 10%;
    padding-left: 5%
}

.w_reptablehead {
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.grid-32 {
    min-height: 100vh
}

.grid-33 {
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr
}

.tb3 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg)
}

.div-block-7 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.text-span {
    display: block;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.v_resvideo, .videolightbox {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 100%
}

.grid-34 {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.html-embed {
    opacity: .6
}

.grid-35, .w_haleft, .w_righthalf {
    position: relative;
    overflow: hidden
}

.b_title.cc-half {
    max-width: 50%
}

.cc-blacktext {
    margin-bottom: 5px;
    color: #000
}

.w_haleft {
    padding-right: 10%
}

.grid-35 {
    width: 100%;
    -ms-grid-columns: .5fr 1fr;
    grid-template-columns: .5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.videolightbox {
    position: absolute;
    z-index: 2;
    display: flex;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.vid_healthyarc {
    height: 75vh
}

.img_playbtn {
    max-width: 100px
}

.w_lopanelhome {
    position: relative;
    left: 185px;
    bottom: -13px
}

.w_hainformaion {
    position: relative;
   /* top: -78px; */
    z-index: 999;
    max-width: 1440px;
    margin-right: auto;
    margin-bottom: -5%;
    margin-left: auto;
    padding: 5%;
    border: 1px solid #e1e1e1;
    background-color: #fff;
}

.grid-36 {
    grid-column-gap: 80px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
}

.w_resvideo {
    position: relative;
    width: 100%;
    height: 50vh
}

.v_resvideo {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow: hidden;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto
}

.cc-nbm {
    margin-bottom: 0
}

.g_customizer {
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-column-gap: 50px;
    -ms-grid-columns: 1fr .5fr;
    grid-template-columns: 1fr .5fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.a_btt {
    position: fixed;
    left: auto;
    top: auto;
    right: 3%;
    bottom: 3%;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 50px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 200%;
    background-color: #000
}

.lb_imggrid, .link-block-2, .link-block-3, .sp_backtoport {
    position: absolute
}



.top {
    display: block
}

.sp_backtoport {
    left: 126%;
    width: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.lb_imggrid, .link-block-2, .link-block-3, .w_img_modal {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.html-embed-3, .s_repfrom {
    width: 100%
}

.tb_share_blogpost {
    padding: 15px 9px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
    line-height: 120%;
    font-weight: 500;
    white-space: nowrap
}

.tb_share_blogpost.cc_scroll_to_next_proj {
    margin-right: 0;
    margin-bottom: 41px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    white-space: nowrap
}

.lb_imggrid {
    z-index: 9;
    width: 100%;
    height: 100%
}

.link-block-10, .link-block-11, .link-block-12, .link-block-5, .link-block-6, .link-block-7, .link-block-8, .link-block-9 {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.img_mount {
    -webkit-filter: invert(100%);
    filter: invert(100%)
}

.tb_case_study_finish, .tb_case_study_system {
    display: none
}

.html_rfqcustom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.lottie-animation-3, .s_repfrom {
    display: block
}

.in_ta {
    height: 300px
}

.s_repfrom {
    position: relative;
    z-index: 2;
    margin: 10% 0 10% auto;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.d_space20px {
    margin-top: 13px
}

.cc_searchicon {
    display: none;
    margin-top: 8px;
    padding-top: 0
}

.d_repfeedback {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center
}

.grid-37 {
    margin-bottom: 50px;
    grid-column-gap: 40px;
    -ms-grid-columns: .75fr 2fr .75fr;
    grid-template-columns: .75fr 2fr .75fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.sp_reptype {
    margin-top: 0;
    color: #1c5291
}

.tb_specificcities {
    margin-top: 10px;
    color: #333;
    font-size: 10px;
    font-weight: 400
}

.tb_rep_states {
    display: none
}

.w_graph_tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.w_course, .w_img_modal {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column
}

.tabl_graph {
    margin-bottom: 0;
    padding-bottom: 5px;
    border-bottom: 2px solid transparent;
    -webkit-transition: border-color .2s ease;
    transition: border-color .2s ease;
    color: #304659
}

.s_resourcelibrary, .w_course {
    width: 100%;
    margin-bottom: 10%
}

.tabl_graph.cc_active {
    border-bottom-color: #304659
}

.img_panel_home {
    display: none;
    -o-object-fit: cover;
    object-fit: cover
}

.w_img_modal {
    position: fixed;
    z-index: 99999;
    display: none;
    width: 100%;
    height: 100vh;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .29)
}

.img_modal {
    max-height: 90%;
    max-width: 90%
}

.tb_close {
    position: absolute;
    left: auto;
    top: 30px;
    right: 30px;
    bottom: auto;
    color: #333;
    font-size: 30px;
    font-weight: 600
}

.s_resourcelibrary {
    position: relative;
    overflow: visible;
    margin-top: 7%;
    padding-right: 5%;
    padding-left: 5%;
    -o-object-fit: fill;
    object-fit: fill
}

.div-block-8 {
    padding-right: 10%;
    padding-left: 10%
}

.grid-38 {
    grid-column-gap: 50px;
    -ms-grid-columns: .5fr 1fr;
    grid-template-columns: .5fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.w_course {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    -ms-flex-direction: column;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-color: #ccc;
    -webkit-transition: border .5s ease;
    transition: border .5s ease
}

.w_coursecontent, .w_courseimg {
    -webkit-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal
}

.w_course:hover {
    border-color: #000
}

.h_description, .sp_coursecredit {
    border-bottom: 1px solid #e1e1e1
}

.sp_format {
    display: inline-block;
    margin-right: 20px;
    padding: 4px 20px;
    background-color: #d0bbb3
}

.w_courseimg {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: visible;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.d_courseexp, .img_generic {
    overflow: hidden
}

.collection-list, .grid-39 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.cc-coursetitle {
    cursor: default
}

.cc-upcomingbadge {
    position: absolute;
    z-index: 99;
    display: inline-block;
    margin-top: 25px;
    padding: 15px 20px;
    background-color: #1c5291;
    color: #fff;
    font-size: 1.2em
}

.c_credit, .w_coursecontent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.h_description {
    margin-top: 20px;
    padding-bottom: 9px;
    cursor: pointer
}

.w_coursecontent {
    display: flex;
    margin-bottom: 40px;
    padding-top: 50px;
    padding-right: 40px;
    padding-left: 40px;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.w_coursecontent.cc-top {
    margin-top: 0;
    padding-top: 0;
    border-top-style: none
}

.collection-list {
    display: -ms-grid;
    display: grid;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 0;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr
}

.sp_coursecredit {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #e1e1e1
}

.c_credit {
    display: flex;
    margin-bottom: 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #1c5291
}

.h_credit, .p_credit {
    margin-bottom: 0
}

.h_credit {
    margin-top: 0;
    margin-right: 5px
}

.grid-39 {
    -ms-grid-columns: 1.5fr;
    grid-template-columns: 1.5fr
}

.w_coursetitlecontent {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 1400px;
    margin-top: 50px;
    margin-bottom: 40px;
    padding-top: 50px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border-top: 1px solid #e1e1e1
}

.w_coursetitlecontent.cc-top {
    margin-top: 0;
    padding-top: 0;
    border-top-style: none
}

.grid-40 {
    -ms-grid-columns: .25fr 1fr;
    grid-template-columns: .25fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto
}

.grid-41, .grid-42 {
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.d_resourcelib {
    max-width: 1400px
}

.grid-41 {
    -ms-grid-columns: 1.5fr 1fr;
    grid-template-columns: 1.5fr 1fr
}

.div-block-9 {
    display: none
}

/*.d_iconblock {
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d55da12d93571c6ed4e04_sustainable.svg);
    background-size: cover
}

.d_iconblock.cc-versatile {
    display: block;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows: auto auto;
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d55da12d93536dded4e03_limitless.svg)
}*/

.c_portfolio_tags, .tabm_portfolio_filters {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.grid-44, .grid-46 {
    grid-column-gap: 40px
}

/*.d_iconblock.cc-quality {
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d572312d935c8ebed5199_quality.svg)
}

.d_iconblock.cc-durable {
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d570a86f417156f1ff39f_durable.svg)
}

.d_iconblock.cc-upkeep {
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d55dad2e06766d0af6754_upkeep.svg)
}

.d_iconblock.cc-value {
    background-image: url(https://assets.website-files.com/5dd319e0c0716e24f0a4fb4a/5e2d57165b8bed3c06aec9d1_value.svg)
}*/

.tabm_portfolio_filters {
    display: flex;
    margin-top: 30px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-bottom: 2px solid #dadada
}

.tab_l_portfolio_filters {
    margin-right: 15px;
    margin-left: 15px;
    background-color: transparent;
    color: #ababab;
    font-size: 18px;
    line-height: 142%;
    font-weight: 600
}

.tab_l_portfolio_filters.w--current {
    background-color: transparent;
    color: #000
}

.tb_state_abbrev {
    display: none
}

.c_portfolio_tags {
    display: flex;
    margin-right: 15px;
    margin-left: 15px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto
}

.link-block-13, .template_filter_tag {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox
}

.template_filter_tag {
    display: flex;
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 5px;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    background-color: #e8e8e8;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    font-size: 12px
}

.g_installcta, .grid-44 {
    margin-top: 30px;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.template_filter_tag:hover {
    background-color: #d6d6d6
}

.link-block-13 {
    display: flex
}

.tb_remove_filter {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: 5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.grid-44, .s_videolist {
    margin-right: auto;
    margin-left: auto
}

.tag-template-hidden {
    display: none
}

.img_generic, .img_generic.cc-generic, .paragraph-4 {
    display: block
}

.grid-43 {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr
}

.cc-finishlink {
    padding-left: 25px
}

.paragraph-4 {
    margin-bottom: 0;
    font-size: .8em
}

.s_videolist {
    width: 80%
}

.grid-44 {
    grid-row-gap: 40px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr
}

.g_installcta {
    -webkit-box-pack: start;
    -webkit-justify-content: start;
    -ms-flex-pack: start;
    justify-content: start;
    justify-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: auto;
    grid-template-columns: auto
}

.grid-45 {
    max-width: 206px;
    margin-top: 40px;
    grid-row-gap: 19px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr
}

.heading-8 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start
}

.grid-46 {
    grid-row-gap: 40px;
    -ms-grid-rows: auto;
    grid-template-rows: auto
}

.rich-text-block {
    max-width: 560px;
    margin: 5% auto 3%
}

.s_genericcontent {
    width: 80%;
    margin-right: auto;
    margin-bottom: 12%;
    margin-left: auto
}

.h3_beigepull {
    margin-right: 15%;
    margin-bottom: 0;
    margin-left: 30%;
    color: #d0bbb3;
    font-size: 2.3em
}

.img_generic {
    position: relative;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 15%;
    max-height: 400px;
    margin-left: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.h5_genericdate, .p_genericsubtitle {
    margin-right: auto;
    margin-left: auto
}

.h5_genericdate {
    max-width: 560px
}

.img_genericgallery {
    width: 100%;
    height: 20%;
    max-height: 250px;
    padding: 10px;
    -o-object-fit: cover;
    object-fit: cover
}

.ci_genericgallery {
    padding-right: 0;
    padding-left: 0;
    -o-object-fit: cover;
    object-fit: cover
}

.sp_genericdate {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    text-transform: none
}

.tb_genericdate {
    position: relative;
    display: block;
    margin-top: 20px;
    margin-right: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: #d0bbb3;
    font-size: 13px;
    line-height: 154%;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap
}

.v_genericvideo {
    margin-bottom: 30px
}

.p_genericsubtitle {
    max-width: 556px;
    color: #d0bbb3;
    font-size: 1.5em;
    line-height: 147%;
    font-weight: 400
}

@media screen and (max-width: 991px) {
    .tl_nav_link, .tl_nav_link.w--current {
        color: #000;
        font-weight: 300
    }

    .tl_nav_link, .tl_nav_link.cc_smallermargin {
        margin-right: 15px
    }

    .c_page, .w_homeleft {
        max-width: 100%
    }

    .n_mainnavbar, .w_alternatingscards.cc_commecial_page {
        padding-right: 0;
        padding-left: 0
    }

    .n_mainnavbar, .w_alternatedgroup.cc_group1 {
        padding-left: 0
    }

    .img_techdata, .materials-bg {
        background-position: 50% 50%
    }

    body {
        font-size: 13.5px
    }

    .n_mainnavbar {
        z-index: 99999
    }

    .w_nav {
        padding-top: 10px;
        padding-bottom: 10px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        background-color: #fff
    }

    .nav-menu, .w_home_awards, .w_quality {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .img_navlogo {
        height: 45px
    }

    .w_navleftsidelinks {
        margin-top: 0px;
        margin-left: 0
    }

    .tl_nav_link {
        position: relative;
        display: block;
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 125%
    }
    .importantLink {
        font-size: 16px;
    }

    .tl_nav_link.cc_smallermargin.cc_navmediulink {
        margin-right: 8px;
        font-weight: 300
    }

    .tb_herobigheading {
        font-size: 3.9em
    }

    .p_heroparagraph {
        max-width: 400px;
        font-size: 1.4em
    }

    .p_heroparagraph.cc-homeherop {
        max-width: 400px;
        margin-top: -20px;
    }

    .w_residentialmiddletoptext {
        max-width: 100%;
        margin-left: 0
    }

    .d_connectorline.cc_group2 {
        height: 156px;
        margin-top: -29.5%;
        margin-left: 60%
    }

    .d_connectorline.cc_group4 {
        margin-left: 2.2%
    }

    .d_connectorline.cc_group5 {
        margin-top: 6%
    }

    .w_alternatingcardstext.cc_group2 {
        width: 55%;
        margin-top: 0
    }

    .w_alternatingcardstext.cc_group4 {
        margin-left: 0
    }

    .w_alternatingcardstext.cc_group5 {
        width: 40%;
        margin-top: 5%;
        margin-right: 0
    }

    .w_alternatingcardstext.cc_group6 {
        width: 58%;
        margin-top: 2.2%
    }

    .w_alternatingimagegroup {
        width: 55%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .w_alternatingimagegroup.cc_group4 {
        width: 45%;
        margin-right: 0
    }

    .w_alternatingimagegroup.cc_group5 {
        width: 50%;
        margin-left: 0
    }

    .w_alternatingimagegroup.cc_group6 {
        width: 80%;
        max-width: 100%
    }

    .w_alternatingimagegroup.cc_group1 {
        width: 60%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .w_alternatedgroup.cc_goup6, .w_alternatedgroup.cc_group5 {
        margin-top: 9.9%
    }

    .w_alternatedgroup.cc_group2 {
        margin-top: 8%;
        margin-left: 0
    }

    .g_qualities {
        grid-column-gap: 30px;
        grid-row-gap: 51px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .w_quality {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .d_vertical_line {
        height: 1px;
        margin-top: 10px;
        margin-right: 21px;
        margin-left: 5px;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .btn.cc_panel_inst_cta, .btn_mobile_nav {
        margin-top: auto
    }

    .d_vertical_line.cc_line6 {
        height: 1px
    }

    .h3_quality_name {
        margin-left: 0
    }

    .d_quality_rotation {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .p_quality {
        padding-top: 0
    }

    .s_footercta {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_textures_text {
        margin-right: 10%;
        margin-left: 5%
    }

    .w_testimony.cc_commercial_page_tesimony_left_side {
        margin-left: 10%
    }

    .w_testimony.cc_commercial_page_tesimony_right_side {
        margin-left: 25%
    }

    .w_footercontent {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        grid-column-gap: 51px;
        grid-row-gap: 50px;
        -ms-grid-columns: 40% 1fr 1fr;
        grid-template-columns: 40% 1fr 1fr
    }

    .fbi_footeremail, .p_footerp {
        font-size: 16px
    }

    .w_footerlinks {
        padding-bottom: 0
    }

    .n_mainmobilenavbar {
        display: block;
        width: 100%;
        background-color: transparent
    }

    .c_navbar_mobile {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-right: 15px;
        padding-left: 15px;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .btn_mobile_nav {
        z-index: 10;
        margin-left: auto;
        padding: 0;
        -webkit-transition: all .2s ease;
        transition: all .2s ease
    }

    .btn_mobile_nav.w--open {
        background-color: transparent;
        color: #fff
    }

    .nav-menu {
        overflow-y: scroll;
        width: 300px;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 99999999;
        display: block;
        padding: 5px;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        background-color: #a4e1ea;
        text-align: left
    }

    .d_mobilenavdivider {
        display: none;
        height: 1px;
        margin-right: 20px;
        margin-bottom: 20px;
        background-color: #6d6d6d
    }

    .s_homehero {
        height: 1550px;
    }

    .w_homeherotext {
        width: 100%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .d_centeredtextandline {
        display: inline-block
    }

    .w_homesectiontext {
        margin-left: 5%
    }

    .w_homesectiontext.cc_layers_section {
        margin-left: 0;
        text-align: left
    }

    .p_section_text.cc_tech_spec_p {
        width: 90%
    }

    .w_homewidesrotatedtext {
        left: -22px;
        top: 687px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .tb_sideways_home_wide_s {
        display: none
    }

    .w_homecardandtext {
        margin-right: 20px
    }

    .s_home_accoustics {
        margin-top: 10%;
        padding-top: 0
    }

    .w_content_accoustic_div {
        margin-right: 0;
        margin-left: 5%
    }

    .w_content_accoustic_div.cc-alightright {
        margin-right: 5%;
        margin-left: 0
    }

    .s_home_layers {
        margin-top: 10%;
        padding: 15% 10%;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .w_layers_img {
        width: 60%;
        padding-bottom: 60%
    }

    .w_layers_text {
        margin-left: 16px
    }

    .s_home_tech_specs {
        margin-top: 10%;
        padding-right: 0;
        padding-left: 0
    }

    .w_home_spects_title {
        padding-right: 40px;
        padding-left: 40px
    }

    .s_home_awards {
        margin-top: 328px
    }

    .w_home_awards_content {
        width: 80%;
        margin-left: 10%
    }

    .w_home_award.cc_award-1 {
        margin-left: 0;
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .w_home_award.cc_award-3 {
        width: auto;
        margin-left: 0;
        -webkit-align-self: flex-end;
        -ms-flex-item-align: end;
        align-self: flex-end
    }

    .w_home_awards {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_award_img {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .s_baswa_types {
        padding-right: 0;
        padding-left: 0;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .w_types_card.cc_middle_card {
        margin-right: 0;
        margin-left: 0;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .p_home_services_types {
        max-width: 100%
    }

    .w_services_types_img {
        padding-bottom: 65%
    }

    .w_services_types_img.cc_middle_card_img {
        margin-bottom: 5%
    }

    .s_home_video {
        margin-top: 201px
    }

    .w_home_video_holder {
        width: 80%;
        max-width: 760px;
        margin-left: 10%
    }

    .w_commercial_alternating_cards {
        margin-top: 10%;
        margin-right: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_2 {
        max-width: none;
        margin-right: 10%;
        margin-left: 10%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: left
    }

    .w_commercial_alternating_cards.cc_text_group_3 {
        margin-top: 10%;
        margin-right: 10%;
        margin-left: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_4 {
        max-width: none;
        min-width: auto;
        margin-right: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_5 {
        max-width: none;
        margin-top: 10%;
        margin-right: 10%;
        margin-left: 10%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        text-align: left
    }

    .w_commercial_alternating_cards.cc_text_group_11, .w_commercial_alternating_cards.cc_text_group_9 {
        -webkit-order: 1;
        -ms-flex-order: 1;
        text-align: left;
        padding-right: 10%;
        padding-left: 10%;
        -webkit-box-ordinal-group: 2
    }

    .w_commercial_alternating_cards.cc_text_group_6 {
        max-width: 100%;
        min-width: auto;
        margin-top: 10%;
        margin-right: 10%;
        margin-left: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_7 {
        margin-right: 10%;
        margin-left: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_8 {
        overflow: visible;
        width: auto;
        max-width: none;
        margin-top: 10%;
        margin-right: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_9 {
        width: 100%;
        max-width: none;
        margin-top: 10%;
        margin-right: 10%;
        margin-left: 10%;
        order: 1
    }

    .w_commercial_alternating_cards.cc_text_group_10 {
        width: 100%;
        max-width: none;
        margin-right: 10%;
        margin-left: 10%;
        padding-right: 10%;
        padding-left: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_11 {
        width: 100%;
        max-width: none;
        margin-right: 0;
        order: 1
    }

    .c_newsletter, .s_contact_info, .tabl_tech_data, .tl_products_subtab, .tl_products_top_tab, .w_faq_left, .w_prod_i_tab_left {
        text-align: center
    }

    .w_commercial_alternating_cards.cc_text_group_12 {
        width: 100%;
        max-width: none;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 10%;
        padding-left: 10%
    }

    .w_commercial_alternating_image.cc_group1 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group2 {
        width: 100%;
        padding-bottom: 65%;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .w_commercial_alternated_group.cc_group1, .w_commercial_alternated_group.cc_group2 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .w_commercial_alternating_image.cc_group3 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group4, .w_commercial_alternating_image.cc_group5 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group6, .w_commercial_alternating_image.cc_group7, .w_commercial_alternating_image.cc_group8 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group9 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group11 {
        width: 100%;
        padding-bottom: 57.74%
    }

    .w_commercial_alternating_image.cc_group12, .w_commercial_alternating_image.cc_group_10 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 65%
    }

    .d_commercial_cards_divider.cc_commercial_divider_6 {
        margin-top: 5%
    }

    .d_commercial_cards_divider.cc_commercial_divider_12, .d_commercial_cards_divider.cc_commercial_divider_8 {
        margin-top: 25%
    }

    .d_commercial_cards_divider.cc_commercial_divider_10 {
        margin-left: 30%
    }

    .w_commercial_alternated_group.cc_group1 {
        margin-right: 5%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_commercial_alternated_group.cc_group2 {
        margin-left: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start
    }

    .w_commercial_alternated_group.cc_group4, .w_commercial_alternated_group.cc_group5 {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_commercial_alternated_group.cc_group4 {
        margin-right: 0;
        margin-left: 0;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_commercial_alternated_group.cc_group5 {
        margin-top: 20%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_commercial_alternated_group.cc_group11, .w_commercial_alternated_group.cc_group12, .w_commercial_alternated_group.cc_group13, .w_commercial_alternated_group.cc_group6, .w_commercial_alternated_group.cc_group8, .w_commercial_alternated_group.cc_group9 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_healthy_architecture_top {
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_healthy_architecture_bottom {
        margin-top: 30px;
        margin-right: 0
    }

    .w_healthy_arch_rotated_text {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .w_healthy_arch_img {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .h1_portfolio_hero_name {
        font-size: 4.5em
    }

    .s_portfolio_search_controls {
        padding-right: 40px;
        padding-left: 40px
    }

    .w_portfolio_filters_groups {
        width: 100%;
        min-width: auto
    }

    .cli_portfolio_checkbox {
        -webkit-box-flex: 0;
        -webkit-flex: 0 33%;
        -ms-flex: 0 33%;
        flex: 0 33%
    }

    .rte_blog_content.cc_blog_page, .s_contact_hero {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .s_indv_port_hero {
        padding-right: 5%;
        padding-left: 5%
    }

    .s_indv_port_img {
        padding-right: 1.5%;
        padding-left: 1.5%
    }

    .w_quote {
        margin-left: 10%
    }

    .p_indv_port_quote {
        font-size: 28px
    }

    .tb_background_text {
        font-size: 60px
    }

    .s_blog_hero {
        margin-top: 0
    }

    .w_blog_hero_text {
        max-width: 80%
    }

    .s_blog_content {
        width: 100%
    }

    .rte_blog_content.cc_blog_page {
        width: 100%;
        margin-left: 0;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .s_4_images_row {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .w_blog_content_images {
        max-width: 680px;
        margin-right: auto;
        margin-left: 0;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .d_image_item {
        width: 45%;
        margin-top: 25px;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 45%
    }

    .w_blog_bottom_image {
        width: 50%;
        padding-bottom: 59.47%
    }

    .w_blog_bottom_image_info.cc_info_group_2 {
        bottom: 0
    }

    .w_blog_bottom_image_info.cc_info_group_4 {
        bottom: 19%
    }

    .p_blog_content_bottom {
        width: 20vw
    }

    .cli_blog_more_items {
        width: 47.5%;
        margin-right: 5%
    }

    .s_contact_hero {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .s_contact_info, .s_faq_content {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_contact_hero_left {
        width: auto;
        max-width: none;
        min-height: 100vh
    }

    .w_contact_hero_right {
        margin-top: 30px;
        margin-right: 5%;
        margin-left: 5%
    }

    .s_contact_info {
        padding: 60px 0;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_contact_page_address {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .lb_contact_page_social_media_icon {
        width: 32px;
        height: 32px;
        margin-right: 8px;
        margin-left: 8px
    }

    .s_panel_inst_content, .w_faq_left {
        margin-right: 0;
        margin-left: 0
    }

    .h3_contact_map {
        left: 5%
    }

    .s_contact_bottom_images {
        max-width: 90%
    }

    .s_faq_content {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_faq_left {
        position: static;
        margin-bottom: 10.5%
    }

    .w_faq_right {
        margin-right: 0
    }

    .v_healthyarch {
        padding-top: 0;
        padding-bottom: 0
    }

    .bgv {
        background-image: none
    }

    .reveal {
        display: none
    }

    .w_panel_inst_hero_bottom {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_panel_i_h_bottom_right {
        margin-top: 45px;
        padding-left: 0
    }

    .img_p_inst_step_img {
        max-width: 45%
    }

    .h2_p_i_cta {
        font-size: 3em
    }

    .tm_procuts_top_tab {
        padding-right: 32px;
        padding-left: 32px
    }

    .tl_products_subtab {
        display: inline-block;
        margin-right: 35px;
        margin-bottom: 0;
        margin-left: 35px;
        padding-bottom: 16px
    }

    .tm_products_inner_tabs {
        display: block;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_products_inner_tab_content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_prod_i_tab_right {
        width: 80%;
        max-width: none;
        margin-top: 30px;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 auto;
        -ms-flex: 0 auto;
        flex: 0 auto
    }

    .d_installation_icon, .s_tech_data_graphs, .s_tech_data_specs_dets, .tabs-menu {
        margin-right: 0
    }

    .c_tech_data, .c_titletechdivider, .t_tech_d_other_test_inner, .w_tech_data_cta_item {
        margin-left: 0
    }

    .w_prods_i_tab_installations {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        text-align: center
    }

    .w_installation_icons {
        margin-top: 15px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .tabm_tech_data_spec_dets.cc_graphs, .w_video_resources_content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .d_installation_icon {
        margin-left: 0
    }

    .p_products_inner_tab {
        max-width: none
    }

    .c_tech_data {
        width: 100%;
        padding-right: 40px;
        padding-left: 40px
    }

    .tabl_tech_data {
        padding-right: 14px;
        padding-left: 14px
    }

    .tabl_tech_data.tabl_graph {
        margin-bottom: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        border-style: solid;
        border-width: 1px 1px 2px;
        border-color: #e1e1e1
    }

    .tabl_tech_data.tabl_graph.cc_active {
        border-bottom-color: #1c5291
    }

    .tabl_tech_data_inner_tab {
        font-size: 1.1em
    }

    .w_tech_d_o_data_inner_content {
        grid-column-gap: 25px;
        -ms-grid-columns: 3.03fr 6.1fr;
        grid-template-columns: 3.03fr 6.1fr;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .p_tech_d_o_inner_content {
        font-size: 1.3em
    }

    .lb_tech_data_resources, .lb_tech_data_resources-2 {
        font-size: 1.1em
    }

    .tabm_tech_data_spec_dets.cc_graphs {
        -ms-flex-direction: column;
        flex-direction: column;
        border-bottom-style: none
    }

    .w_video_resources_content {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .s_indv_prod_hero, .w_leed_credits_download_certificates {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .v_tech_data_resources {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 40px
    }

    .c_titletechdivider {
        width: 100%
    }

    .d_additional_videos_separator {
        margin-top: 26px;
        margin-bottom: 26px
    }

    .w_leed_credits_download_certificates {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .tb_leed_credits_download {
        max-width: 100%
    }

    .tabm_leed_v4_credits {
        margin-right: 7.5%
    }

    .tabc_leed_v4_credits {
        margin-right: 0
    }

    .s_indv_prod_hero {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .form_indv_prod_quote, .w_indv_prod_hero_right {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_indv_prod_hero_right {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        max-width: 100%;
        margin-top: 0;
        padding-top: 5%;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .p_indv_prod_hero_right {
        margin-bottom: 5%
    }

    .w_indv_prod_settings {
        display: inline-block;
        margin-right: 40px;
        margin-bottom: 20px
    }

    .d_indv_prod_right_line {
        display: none
    }

    .d_indv_prod_right_line.cc_non_stem {
        display: block;
        width: 100%;
        max-width: none
    }

    .d_indv_prod_form_divider, .m_imgreveal, .slider_nav {
        display: none;
    }

    .s_indv_prod_all_prods {
        margin-top: 15%
    }

    .tm_indv_prod_tab {
        margin-top: 4%
    }

    .w_indv_prod_horiz_scroll {
        padding-top: 30px
    }

    .d_indv_prod_img_holder {
        width: 200px;
        height: 273px
    }

    .tc_indv_prod_tab {
        margin-top: 40px;
        margin-bottom: 10.3%
    }

    .s_indv_prod_case {
        margin-bottom: 300px
    }

    .h5_indv_prod_case_study {
        margin-left: 50px
    }

    .mask {
        width: 500px
    }

    .slider {
        height: 485px;
        padding-right: 0;
        padding-left: 50px
    }

    .tb_indv_prod_case_item {
        margin-right: 60px
    }

    .w_switching_layers {
        margin-top: 30px;
        margin-right: 40px;
        margin-left: 40px
    }

    .w_switching_layers.cc_svg {
        margin-right: 0;
        margin-left: 0;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_indv_prod_options {
        margin-bottom: 5%;
        grid-row-gap: 20px;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .w_indv_prod_options.cc_v2 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .collection-list-wrapper-2 {
        margin-right: auto;
        margin-left: auto
    }

    .w_portfolio_checkbox {
        -webkit-box-flex: 0;
        -webkit-flex: 0 33%;
        -ms-flex: 0 33%;
        flex: 0 33%
    }

    .d_spacing_cms_dependent {
        height: 130px
    }

    .d_quote_form_content {
        min-height: auto
    }

    .form_indv_prod_quote {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .d_prod_quote_f_left {
        width: 100%;
        padding-bottom: 0
    }

    .h2_indv_prod_quote {
        margin-bottom: .8em
    }

    .d_options_holder {
        -ms-grid-columns: minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px);
        grid-template-columns: minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px) minmax(50px, 197px);
        -ms-grid-rows: 1fr;
        grid-template-rows: 1fr
    }

    .g_commercialhero, .grid-6 {
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .em_svg_container {
        margin-bottom: -88px;
        padding-right: 5%;
        padding-left: 5%
    }

    .c_type-content, .s_comres_hero {
        margin-bottom: 0
    }

    .g_portfolio_no_img {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }



    .img_materials {
        position: absolute;
        left: 0;
        top: 0;
        right: auto;
        bottom: 0;
        display: none;
        -o-object-fit: none;
        object-fit: none
    }

    .gradient-overlay {
        left: 0;
        top: auto;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-image: -webkit-gradient(linear, left top, right top, color-stop(43%, rgba(0, 0, 0, .79)), to(transparent));
        background-image: linear-gradient(90deg, rgba(0, 0, 0, .79) 43%, transparent)
    }

    .w_tooltip_content.cc_mount_types {
        width: 340px
    }

    .dot-bg {
        top: -107px;
        right: -4%
    }

    .dot-bg.cc-left {
        right: 35%
    }

    .grid-3 {
        grid-row-gap: 80px
    }

    .g_commercialinnerhero, .grid-7, .grid-8 {
        grid-column-gap: 50px;
        grid-row-gap: 50px
    }

    .w_indv_prod_side_panel {
        left: 0;
        top: auto;
        right: auto;
        bottom: 63%;
        -webkit-transform: translate(0, 50%);
        -ms-transform: translate(0, 50%);
        transform: translate(0, 50%)
    }

    .w_info_dot_item {
        top: 27%
    }

    .w_info_dot_item.cc_beacon2 {
        left: 81%;
        top: 57%
    }

    .w_info_dot_item.cc_beacon3 {
        top: 61%
    }

    .btn_-2.cc_tech_data_page.cc_certifcates_download {
        margin-right: 0;
        margin-bottom: 15px
    }

    .btn_-2.cc_tech_d_o_data_inner {
        margin-top: 0;
        margin-left: 0
    }

    .navddl_tech_data-2 {
        background-color: #fff
    }

    .w_headerimages {
        top: 217px;
        padding-right: 5%;
        padding-left: 5%
    }

    .w_headerimages.cc-commercial {
        top: 0;
        margin-top: -340px
    }

    .fbsel_reps_table {
        margin-right: 30px
    }

    .fbsel_reps_table.cc_states {
        max-width: 180px
    }

    .c_inner, .c_map, .d_products_content.cc_smaller {
        max-width: 100%
    }

    .w_reps_table_caption {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_reps_caption_item {
        margin-top: 4px;
        margin-right: 0;
        margin-bottom: 4px
    }

    .lo_panel {
        right: -40%;
        width: 80vw
    }

    .lo_panel.cc-cta {
        position: static;
        width: 100%;
        height: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .gi_rep {
        width: 40%;
        margin-right: 5%;
        margin-bottom: 30px;
        margin-left: 5%
    }

    .s_repfrom, .w_hainformaion {
        margin-bottom: 0;
        width: 100%;
    }

    .grid-4 {
        -ms-grid-columns: 2fr 1fr;
        grid-template-columns: 2fr 1fr
    }

    .lottie-animation {
        position: static;
        width: 100%;
        height: 100%
    }

    .img_heroframe.cc-f1 {
        width: 100%;
        height: 656px
    }

    .img_heroframe.cc-f2 {
        height: 333px
    }

    .img_heroframe.cc-f3 {
        height: 476px
    }

    .img_heroframe.cc-f4 {
        height: 604px
    }

    .img_heroframe.cc-f5 {
        height: 508px
    }

    .img_heroframe.cc-f6 {
        height: 324px
    }

    .img_heroframe.cc-f7 {
        height: 149px
    }

    .img_heroframe.cc-f8 {
        height: 250px
    }

    .img_heroframe.cc-commercial-1 {
        height: 400px
    }

    .img_heroframe.cc-commercial-2, .img_heroframe.cc-commercial-3 {
        height: 350px
    }

    .img_heroframe.cc-reshero-1 {
        height: 400px
    }

    .img_heroframe.cc-reshero-3, .img_heroframe.cc-reshero_2 {
        height: 350px
    }

    .img_heroframe.cc-installhero-1 {
        height: 400px
    }

    .img_heroframe.cc-installhero-2, .img_heroframe.cc-installhero-3 {
        height: 350px
    }

    .img_heroframe.cc-res1 {
        height: 400px
    }

    .img_heroframe.cc-residential2, .img_heroframe.cc-residential3 {
        height: 350px
    }

    .grid-6 {
        grid-column-gap: 50px
    }

    .w_scrollline {
        top: 51px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .w_scrollline.cc-center {
        top: 0;
        margin-top: 10%
    }

    .w_healthypanel {
        padding-top: 0
    }

    .w_rep_info_content {
        left: 0;
        top: 50%;
        right: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-transform: translate(-100%, -50%);
        -ms-transform: translate(-100%, -50%);
        transform: translate(-100%, -50%)
    }

    .nav_menu_content, .tab_products {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_subscribeform {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 0;
        border-top: 1px solid #e1e1e1;
        border-left-style: none
    }

    .g_cta {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .d_rep_item_connector {
        border-bottom: 8px solid transparent;
        border-top-color: transparent;
        border-right-style: none;
        border-left-color: #000
    }

    .g_commercialhero {
        grid-column-gap: 50px
    }

    .w_ripple {
        left: -30%;
        top: -47%;
        width: 100%
    }

    .w_currently_selected_color {
        width: 25px
    }

    .w_vertprojecttitle.cc-adjust {
        left: -103px
    }

    .w_vertprojecttitle.cc-adjust2 {
        left: -126px
    }

    .grid-13.cc-techdata {
        grid-column-gap: 15px;
        -ms-grid-columns: auto 1fr;
        grid-template-columns: auto 1fr
    }

    .grid-13.cc_faq {
        padding-right: 0;
        grid-column-gap: 0;
        -ms-grid-columns: 180px 1fr;
        grid-template-columns: 180px 1fr
    }

    .collection-list, .grid-33 {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .tab_products {
        display: block;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tabl_products {
        display: inline-block;
        margin-right: 18px;
        margin-left: 18px
    }

    .tabc_products {
        margin-top: 49.45px;
        margin-left: 0
    }

    .w_product_item {
        padding-left: 5%
    }

    .w_product_item.cc-half, .w_product_item.cc_reduced {
        padding-right: 5%;
        padding-bottom: 50%
    }

    .img_products_item {
        top: 56%;
        width: 70%
    }

    .img_products_item.cc_baswa_cool {
        top: 64%;
        right: -12%
    }

    .img_products_item.cc_baswa_hybrid {
        top: 24%;
        right: -39%;
        width: 100%
    }

    .tabm_products {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .lottie-animation-2 {
        -webkit-transform: scale(11);
        -ms-transform: scale(11);
        transform: scale(11)
    }

    .burger-button-wrapper {
        -webkit-transform: scale(.6);
        -ms-transform: scale(.6);
        transform: scale(.6)
    }

    .burger-line-bottom, .burger-line-top {
        width: 20px
    }

    .img_navbar_menu {
        position: absolute;
        top: 102px;
        right: 0;
        display: block;
        max-width: 34.4%
    }

    .img_navbar_menu.cc_img2 {
        top: auto;
        right: 8.2%;
        bottom: 17%;
        max-width: 39.46%
    }

    .s_altcardtitle {
        padding-top: 10%
    }

    .w_next_page_preview_content {
        max-width: 90%
    }

    .w_mobilebgcover {
        display: none
    }

    .logo {
        z-index: 999999999
    }

    .w_breadcrumbs {
        margin-top: 0;
        padding-bottom: 0
    }

    .nav_menu_content {
        height: 100%;
        padding-top: 20px;
        padding-left: 51px;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_productblock.cc-mobilegrowdown {
        padding-bottom: 150px
    }

    .grid-27 {
        grid-row-gap: 0
    }

    .b_title.cc-half {
        max-width: none
    }

    .vid_healthyarc {
        height: 50vh
    }

    .w_hainformaion {
        top: 0
    }

    .v_resvideo {
        padding-top: 0;
        padding-bottom: 0
    }

    .grid-46 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }
}

@media screen and (max-width: 767px) {
    .s_residential_hero, .w_alternatedgroup, .w_alternatingcardstext {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    body {
        font-size: 13px
    }

    h1 {
        font-size: 2.8em
    }

    p {
        font-size: 1.2em
    }

    .n_mainnavbar {
        padding-right: 0;
        padding-left: 0
    }

    .s_residential_hero {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_homeleft {
        position: static;
        max-width: 100%;
        text-align: center
    }

    .tb_herobigheading {
        max-width: 100%;
        font-size: 4em;
        white-space: normal
    }

    .p_heroparagraph {
        max-width: none;
        margin-bottom: 30px
    }

    .w_residentialmiddletoptext {
        margin-bottom: 5%
    }

    .w_alternatingscards.cc_commecial_page {
        padding-bottom: 20%
    }

    .d_connectorline {
        display: none
    }

    .d_connectorline.cc_group3 {
        margin-right: 15px;
        margin-left: -19.8%
    }

    .w_alternatingcardstext {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 90%;
        max-width: 478px;
        margin-top: 20px;
        margin-left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center
    }

    .w_alternatingcardstext.cc_group2 {
        width: 90%;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto
    }

    .w_alternatingcardstext.cc_group3, .w_alternatingcardstext.cc_group4 {
        width: 90%;
        max-width: 478px;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto
    }

    .w_alternatingcardstext.cc_group5 {
        width: 90%;
        margin-top: 20px;
        margin-left: 0
    }

    .w_alternatingcardstext.cc_group6 {
        width: 90%;
        max-width: 478px;
        margin-top: 20px;
        margin-left: 0
    }

    .p_alternatinggroups {
        text-align: left
    }

    .d_speccontent, .fbi_footeremail, .h2_tech_d_o_inner_content, .li_tech_data_ratings, .p_blog_content.cc_blog_wide_image_caption, .p_quality, .p_tech_d_o_inner_content, .s_4_images_row, .tabl_tech_data_inner_tab, .w_commercial_alternating_cards, .w_footercontent, .w_leed_credits_download_certificates, .w_textures_text {
        text-align: center
    }

    .p_alternatinggroups.cc_max_width_360p {
        max-width: 100%
    }

    .lb_alternatingcards {
        margin-left: 0
    }

    .w_alternatingimagegroup, .w_alternatingimagegroup.cc_group1, .w_alternatingimagegroup.cc_group5 {
        width: 80%
    }

    .w_alternatedgroup {
        margin-top: 80px;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_alternatedgroup.cc_group2, .w_alternatedgroup.cc_group4 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse
    }

    .w_alternatedgroup.cc_group4 {
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .w_alternatedgroup.cc_goup6, .w_alternatedgroup.cc_group5 {
        margin-top: 80px
    }

    .w_alternatedgroup.cc_group1 {
        padding-left: 0
    }

    .w_alternatedgroup.cc_group2 {
        width: auto;
        margin-left: 0;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .s_footercta, .w_quality {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .g_qualities {
        grid-row-gap: 0;
        -ms-grid-columns: 100%;
        grid-template-columns: 100%
    }

    .g_homepage, .w_footercontent {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .w_quality {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .d_vertical_line {
        height: 30px;
        margin: 5px 0 6px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .d_vertical_line.cc_line6 {
        height: 30px
    }

    .d_quality_rotation {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .p_quality {
        margin-bottom: 30px
    }

    .s_footercta {
        padding-top: 10%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_accoustics_image_text, .w_footerleftsidecontent {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .w_textures_text {
        margin-right: 5%
    }

    .w_textures_text.cc_w_in_commercial_page {
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto
    }

    .s_testimonials {
        padding-right: 0;
        padding-left: 0
    }

    .w_testimony {
        margin-left: 0
    }

    .w_testimony.cc_commercial_page_tesimony_left_side {
        max-width: 95%;
        margin-left: 0
    }

    .w_homesectiontext, .w_testimony.cc_commercial_page_tesimony_right_side {
        margin-left: 5%
    }

    .w_footercontent {
        padding-right: 0;
        grid-row-gap: 25px;
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto
    }

    .fbi_footeremail::-webkit-input-placeholder {
        text-align: center
    }

    .fbi_footeremail:-ms-input-placeholder {
        text-align: center
    }

    .fbi_footeremail::-ms-input-placeholder {
        text-align: center
    }

    .fbi_footeremail::placeholder {
        text-align: center
    }

    .w_footerleftsidecontent {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .p_footerp {
        margin-top: 20px
    }

    .w_footerlinks {
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .btn_beige.cc-moretopspace, .h2_alternating_card {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start
    }

    .tl_footernavigation {
        margin-bottom: 16px
    }

    .h2_alternating_card {
        margin-top: 0;
        margin-bottom: 10px;
        align-self: flex-start
    }

    .scrollline.cc-light {
        background-color: #000
    }

    .w_homesectiontext.cc_layers_section {
        padding-right: 0;
        padding-left: 0
    }

    .h1_sectiontitle {
        margin-bottom: 32px
    }

    .p_section_text.cc_accoustic_p {
        margin-top: 20px;
        margin-bottom: 30px
    }

    .p_section_text.cc_tech_spec_p {
        width: 100%;
        margin-bottom: 30px
    }

    .w_homewidesrotatedtext {
        margin-right: 0;
        margin-left: auto;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .tb_sideways_home_wide_s {
        font-size: 1em
    }

    .w_homecardandtext {
        margin-right: 0;
        margin-bottom: 20px
    }

    .w_hometextbtn {
        margin-top: 20px
    }

    .c_homecard {
        padding-bottom: 100%
    }

    .h3_homecardtitle {
        margin-bottom: 10px
    }

    .p_home_text_and_btn {
        margin-bottom: 20px
    }

    .w_accoustics_image_text {
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .d_accoustic_image_text_item, .s_home_layers {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .d_accoustic_image_text_item {
        width: 100%;
        margin-bottom: 50px;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .d_accoustic_image_text_item.cc_second_item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: end;
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end
    }

    .w_content_accoustic_div {
        margin-right: 15px;
        margin-left: 15px;
        padding-top: 15px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_content_accoustic_div.cc_second_div {
        margin-top: 25px;
        padding-top: 0;
        padding-right: 5px;
        padding-bottom: 15px
    }

    .w_content_accoustic_div.cc_tech_data_hero {
        max-width: 80%;
        margin-right: 40px;
        margin-left: 40px;
        padding-top: 25px
    }

    .w_accoustic_img {
        width: 100%;
        height: 300px;
        margin-left: 0;
        padding-bottom: 60%
    }

    .w_accoustic_img.cc-left.cc_techdata {
        width: 100%
    }

    .s_home_layers {
        height: 100%;
        margin-top: 10%;
        padding-top: 70%;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group2, .w_commercial_alternating_cards {
        -webkit-flex-direction: column;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical
    }

    .w_layers_img {
        width: 80%;
        margin-left: 0
    }

    .w_layers_text {
        margin-top: 15px
    }

    .s_home_tech_specs {
        margin-top: 10%
    }

    .w_home_tech_specs_content {
        margin-top: 0
    }

    .w_tech_spec_details {
        padding-right: 0;
        padding-left: 0
    }

    .tb_tech_spec_number {
        margin-bottom: 5px;
        font-size: 2.4em
    }

    .w_home_awards_content {
        margin-left: 0
    }

    .w_home_award.cc_award-1 {
        margin-right: 0
    }

    .w_home_award.cc_award-2 {
        margin-bottom: 12.1%
    }

    .s_baswa_types {
        margin-top: 10%
    }

    .w_types_card.cc_middle_card {
        width: 100%;
        padding-top: 0
    }

    .w_commercial_alternating_cards {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 10%;
        padding-left: 10%;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .w_commercial_alternating_cards.cc_text_group_2 {
        margin-top: 10%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center
    }

    .w_commercial_alternating_cards.cc_text_group_3 {
        max-width: none;
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_4 {
        margin-top: 10%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_5 {
        width: 100%;
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 10%;
        padding-left: 10%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_commercial_alternating_cards.cc_text_group_6 {
        margin-top: 10%
    }

    .w_commercial_alternating_cards.cc_text_group_7 {
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 25px;
        padding-left: 25px;
    }

    .w_commercial_alternating_cards.cc_text_group_8 {
        width: 100%;
        margin-top: 5%;
        margin-right: 0;
        padding-top: 5%;
        padding-right: 10%;
        padding-left: 10%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_9 {
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        padding-right: 10%;
        padding-left: 10%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_commercial_alternating_cards.cc_text_group_10 {
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_11 {
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        margin-left: 0;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_commercial_alternating_cards.cc_text_group_12 {
        width: 100%;
        margin-top: 10%;
        margin-right: 0;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .w_commercial_alternating_image.cc_group1 {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group2 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group3 {
        width: 100%;
        margin-left: 0
    }

    .w_commercial_alternating_image.cc_group4, .w_commercial_alternating_image.cc_group6 {
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 65%;
        width: 100%
    }

    .w_commercial_alternating_image.cc_group5 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group7 {
        width: 100%
    }

    .w_commercial_alternating_image.cc_group8 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group9 {
        width: 100%;
        padding-bottom: 71.74%
    }

    .w_commercial_alternating_image.cc_group11 {
        width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group12 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 73.74%;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .w_commercial_alternating_image.cc_group_10 {
        width: 100%;
        margin-left: 0;
        padding-bottom: 65%
    }

    .d_commercial_cards_divider {
        display: none;
        width: 1px;
        height: auto;
        margin-top: 5px;
        margin-left: 0;
        padding-bottom: 9.6%
    }

    .d_commercial_cards_divider.cc_commercial_divider_3 {
        margin-left: 42%
    }

    .d_commercial_cards_divider.cc_commercial_divider_7 {
        margin-left: 30%
    }

    .d_commercial_cards_divider.cc_commercial_divider_8 {
        margin-top: 5%
    }

    .d_commercial_cards_divider.cc_commercial_divider_11, .d_commercial_cards_divider.cc_commercial_divider_9 {
        margin-right: -20.49%
    }

    .d_commercial_cards_divider.cc_commercial_divider_12 {
        margin-top: 5%;
        margin-left: -22.49%
    }

    .w_commercial_alternated_group {
        padding-right: 5%;
        padding-left: 5%;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .w_commercial_alternated_group.cc_group1 {
        margin-top: 10%;
        margin-right: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group2 {
        margin-top: 80px;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group1 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_commercial_alternated_group.cc_group3 {
        margin-top: 80px;
        margin-right: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group4 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group5 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group6 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group7 {
        margin-top: 80px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group8 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group9 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group10 {
        margin-top: 80px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternated_group.cc_group11 {
        margin-top: 80px;
        padding-right: 5%;
        padding-left: 5%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group12 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group13 {
        margin-top: 80px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_commercial_alternated_group.cc_group14 {
        margin-top: 80px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_healthy_architecture_bottom {
        margin-left: 40%
    }

    .h1_portfolio_hero_name {
        font-size: 3.7em
    }

    .s_portfolio_search_controls {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .s_ind_port_main_content, .w_main_content_left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .s_indv_port_hero {
        padding-top: 110px;
        padding-bottom: 100px
    }

    .w_indv_port_img_sec_award {
        right: 7%
    }

    .s_indv_port_text_sec {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-right: 5%;
        padding-left: 5%
    }

    .s_ind_port_main_content {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_main_content_left {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .s_4_images_row, .s_blog_big_image {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_quote {
        margin-bottom: 120px
    }

    .w_main_content_img_left {
        left: 0;
        width: 97%;
        margin-right: auto;
        margin-left: auto
    }

    .img_main_content_left {
        position: absolute
    }

    .w_main_content_right {
        right: 0;
        width: 100%;
        margin-top: 44px;
        margin-right: auto;
        margin-left: 0
    }

    .w_main_content_img_right {
        width: 97%;
        margin-right: auto;
        margin-left: auto
    }

    .s_indv_port_background_text {
        display: none;
        margin-top: -29.25%
    }

    .s_blog_hero {
        min-height: 500px
    }

    .w_blog_hero_text {
        padding: 5%
    }

    .h1_blog_hero {
        font-size: 3.4em
    }

    .s_blog_content {
        margin-top: 70px
    }

    .rte_blog_content {
        margin-top: 10px;
        margin-left: 0
    }

    .s_4_images_row {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_blog_content_images {
        margin-right: 0;
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1
    }

    .d_image_item.cc_no_left_margin {
        margin-right: 0
    }

    .tb_images_section {
        margin-right: 0;
        margin-bottom: 15px;
        margin-left: 0;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0)
    }

    .h2_blog_content {
        margin-left: 0
    }

    .s_blog_big_image {
        margin-right: 0;
        margin-left: 0;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .h3_blog_big_img_info {
        margin-right: 0;
        margin-bottom: 10px;
        margin-left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .w_blog_bottom_image {
        width: 100%;
        max-width: 100%;
        margin-top: 70px;
        padding-bottom: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_blog_bottom_image_info {
        position: static;
        top: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-top: 15px;
        margin-bottom: 15px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    .cl_blog_more_articles, .w_installation_process_step {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical
    }

    .w_blog_bottom_image_info.cc_info_group_3, .w_blog_bottom_image_info.cc_info_group_4 {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .p_blog_content_bottom {
        width: auto;
        max-width: none
    }

    .d_blog_content_bottom_info_line {
        position: static;
        width: 30px;
        margin-right: 20px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .d_blog_content_bottom_info_line.cc_blog_bottom_line_2 {
        width: 30px
    }

    .d_blog_content_bottom_info_line.cc_blog_bottom_line_3, .d_blog_content_bottom_info_line.cc_blog_bottom_line_4 {
        width: 30px;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .cl_blog_more_articles {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .cli_blog_more_items {
        width: 90%;
        margin-right: auto;
        margin-bottom: 30px;
        margin-left: auto
    }

    .h4_blog_more_item {
        margin-top: 15px
    }

    .h1_page_title {
        margin-bottom: 15px
    }

    .w_main_blog_filter {
        margin-bottom: 60px
    }

    .ci_blog_post_item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20%;
        margin-left: 0
    }

    .tabl_tech_data, .tl_products_subtab {
        margin-bottom: 10px
    }

    .w_main_blog_post_image {
        height: 300px
    }

    .w_contact_hero_left {
        width: 100%;
        height: 50vh;
        min-height: auto;
        margin-right: auto;
        margin-left: auto;
        padding-top: 60px;
        padding-bottom: 60px
    }

    .d_contact_hero_left_content {
        margin-right: 4%;
        margin-left: 7%
    }

    .p_contact_hero_left {
        font-size: 1.5em
    }

    .h1_contact_page_title {
        font-size: 4em
    }

    .w_contact_hero_right {
        overflow: visible;
        height: auto
    }

    .h3_contact_hero_right {
        font-size: 1.5em
    }

    .w_contact_page_address {
        margin-right: 0;
        margin-left: 0
    }

    .s_contact_bottom_images {
        max-width: 100%
    }

    .w_faq_right {
        margin-right: 0
    }

    .w_faq_question_group {
        padding-top: 77px
    }

    .tm_products_inner_tabs, .w_features {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .h2_portfolio_item {
        font-size: 2.5em
    }

    .w_features {
        grid-row-gap: 2.7em
    }

    .s_tech_data_ctas, .tm_products_inner_tabs {
        grid-auto-columns: 1fr;
        grid-column-gap: 16px;
        grid-row-gap: 16px
    }

    .h2_pillar_content, .tb_pillar_content_excerpt {
        margin-left: 16%
    }

    .h1_panel_inst_hero {
        margin-left: 0;
        font-size: 5em
    }

    .w_panel_inst_hero_bottom {
        width: 100%
    }

    .h2_panel_inst_hero {
        font-size: 3.5em
    }

    .h1_panel_i_content {
        font-size: 3em
    }

    .w_installation_process_step {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_installation_process_step.cc_reverse_on_mobile {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .tm_products_inner_tabs, .w_panel_i_cta_wrapper {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .p_inst_step {
        margin-top: 40px
    }

    .w_p_i_step_text {
        width: 100%;
        padding-right: 5%;
        padding-left: 5%
    }

    .img_p_inst_step_img {
        width: 90%;
        max-width: 100%;
        margin-top: 20px;
        margin-right: auto;
        margin-left: auto
    }

    .s_panel_inst_cta {
        padding-left: 0
    }

    .w_panel_i_cta_wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-right: 15px;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .h1_products_hero {
        font-size: 6.6em
    }

    .tl_products_top_tab {
        margin-top: 0;
        text-align: center
    }

    .tm_products_inner_tabs {
        -ms-flex-direction: column;
        flex-direction: column;
        justify-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .w_products_inner_tab_content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .img_products_i_tab {
        max-width: 90%;
        margin-right: auto;
        margin-left: auto
    }

    .w_installation_icons {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .d_installation_icon {
        margin-right: 15px;
        margin-left: 15px
    }

    .h1_tech_data_hero {
        font-size: 6em
    }

    .c_tech_data {
        margin-left: 0
    }

    .d_accoustic_test_results {
        max-width: 100%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .d_accoustic_test_results.cc_typee {
        max-width: 100%;
        margin-left: 0
    }

    .d_accoustic_test_results.cc_typea.cc_tooltip_conditioned, .d_accoustic_test_results.cc_typee.cc_tooltip_conditioned {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex
    }

    .p_tech_data_test_data {
        margin-bottom: 20px
    }

    .tabl_tech_data {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border: 1px solid #e1e1e1;
        font-size: .9em
    }

    .t_other_test_data {
        margin-right: 0
    }

    .tabl_tech_data_inner_tab {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .tabs-menu {
        justify-items: center
    }

    .tabm_tech_data_spec_dets, .w_tech_d_spec_d_tab_content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .w_tech_d_o_data_inner_content {
        -ms-grid-columns: 3.03fr;
        grid-template-columns: 3.03fr
    }

    .tabm_tech_data_spec_dets {
        -ms-flex-direction: column;
        flex-direction: column;
        border-bottom-style: none
    }

    .w_tech_d_spec_d_tab_content {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_tech_d_cleaning_2row, .w_tech_d_cleaning_3row {
        -webkit-flex-direction: column;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical
    }

    .w_tech_d_spec_d_tab_c_left {
        margin-right: 0;
        margin-bottom: 40px
    }

    .img_specs_tab_content {
        margin-bottom: 30px
    }

    .w_tech_d_cleaning_2row {
        margin-bottom: 9.9%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .p_tech_d_cleaning_2row {
        max-width: none;
        margin-right: 0
    }

    .w_tech_d_cleaning_3row {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_tech_d_cm_3row_content {
        max-width: 100%
    }

    .w_tech_d_cm_3row_content.cc_center {
        margin: 15px 0
    }

    .w_leed_credits_content {
        margin-right: 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .p_leed_credits_content {
        width: auto;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 30px
    }

    .h3_indv_prod_case_item, .img_indv_prod_case_study_item {
        margin-bottom: 25px
    }

    .w_leed_credits_download_certificates {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .tab_leed_v4_credits {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tabm_leed_v4_credits {
        position: static;
        width: 100%;
        margin-right: 0;
        padding-top: 10px;
        padding-bottom: 40px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .img_blog_last, .w_indv_prod_hero_right {
        position: relative
    }

    .tabc_leed_v4_credits {
        width: 100%
    }

    .w_leed_v4_credits_text_content {
        margin-top: 6%
    }

    .s_tech_data_ctas {
        display: -ms-grid;
        display: grid;
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .d_tech_data_ctas_divider {
        display: none;
        margin-right: 10px;
        margin-left: 10px
    }

    .w_indv_prod_settings {
        margin-right: 0
    }

    .tm_indv_prod_tab {
        display: block
    }

    .d_spacing_cms_dependent, .slider_nav, .w_imdv_prod_image_info_dots, .w_indv_prod_tooltip.cc_techdata {
        display: none
    }

    .h5_indv_prod_case_study {
        margin-left: 5vw
    }

    .mask {
        width: 390px
    }

    .slider {
        height: 377px;
        padding-left: 5vw
    }

    .w_switching_layers {
        margin-right: 5%;
        margin-left: 5%
    }

    .w_switching_layers.cc_svg {
        margin-bottom: 0;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_indv_prod_options {
        -ms-grid-columns: 1.25fr 1fr;
        grid-template-columns: 1.25fr 1fr
    }

    .g_cta, .g_tdspecdetail, .grid-4 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .collection-list-wrapper-2 {
        width: 100%
    }

    .img_product, .lo_panel {
        position: static
    }

    .p_blog_content {
        margin-top: 10px;
        margin-left: 0
    }

    .d_quote_form_content {
        width: 90%
    }

    .d_quote_form_content.cc_v2 {
        padding-top: 5%;
        padding-bottom: 5%
    }

    .form_indv_prod_quote {
        padding-right: 5.5%;
        padding-left: 5%
    }

    .tb_indv_prod_quote_edit {
        margin-top: 15px
    }

    .em_svg_container {
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0
    }

    .w_blog_share {
        margin-left: 0
    }

    .w_tooltip_content.cc_mount_types {
        bottom: -14px;
        width: 260px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-transform: translate(-50%, 100%);
        -ms-transform: translate(-50%, 100%);
        transform: translate(-50%, 100%)
    }

    .d_tooltip_connector.cc_down_mobile {
        border-bottom: 8px solid #000;
        border-top-width: 0
    }

    .d_tooltip_connector.cc_right_mobile_land {
        border-bottom: 8px solid transparent;
        border-top-color: transparent;
        border-right-color: #000;
        border-left-style: none
    }

    .d_tooltip_connector.cc_left_mobile_land {
        border-color: transparent #000;
        border-top-width: 8px;
        border-right-style: none;
        border-bottom-style: solid;
        border-bottom-width: 8px;
        border-left-style: solid
    }

    .nav_sticky, .nav_stickymenu {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1
    }

    .grid-2 {
        grid-column-gap: 40px
    }

    .c_type-content {
        padding-right: 5%;
        padding-left: 5%
    }

    .dot-bg {
        bottom: 13%
    }

    .dot-bg.cc-left {
        bottom: 15%
    }

    .btn_beige.cc-moretopspace {
        margin-top: 0;
        align-self: flex-start
    }

    .btn_beige.cc-leftalignedm {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .grid-3 {
        grid-row-gap: 100px
    }

    .s_comres_hero {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .d_indv_prod_side_panel_trigger {
        padding: 7px
    }

    .w_info_dot_item {
        left: 33%;
        top: 17%
    }

    .w_info_dot_item.cc_beacon2 {
        left: 55%;
        top: 37%
    }

    .w_beacon_content {
        z-index: 1000000
    }

    .w_beacon_content.cc_down_mobile {
        top: auto;
        bottom: -100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-transform: translate(-50%, 100%);
        -ms-transform: translate(-50%, 100%);
        transform: translate(-50%, 100%)
    }

    .w_beacon_content.cc_right_mobile_land {
        left: 100%;
        top: 50%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%)
    }

    .w_beacon_content.cc_left_mobile_land {
        left: 0;
        top: auto;
        bottom: 50%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-transform: translate(-100%, 50%);
        -ms-transform: translate(-100%, 50%);
        transform: translate(-100%, 50%)
    }

    .fb_reps_filters, .s_repfrom, .w_prods_clean_item {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .img_indv_prod_sidepanel {
        max-width: 22px
    }

    .w_tech_d_spec_d_tab_c_right-2 {
        max-width: 100%
    }

    .m_imgreveal {
        left: -1%;
        display: block;
        width: 102%;
        height: 100%
    }

    .w_headerimages.cc-commercial {
        width: 100%;
        margin-top: 0;
        padding-left: 5%
    }

    .w_hub_loc_item {
        left: 33%;
        top: 17%
    }

    .fb_reps_filters {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .fbsel_reps_table {
        margin-right: 0
    }

    .fbsel_reps_table.cc_states {
        max-width: 250px
    }

    .w_reps_table_caption {
        margin-top: 20px
    }

    .lo_panel {
        width: 100%;
        height: auto;
        -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
        transform: rotate(120deg);
        -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        transform-origin: 50% 50%
    }

    .lo_panel.cc_home {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .gi_rep {
        width: 45%;
        margin-right: 2.5%;
        margin-left: 2.5%
    }

    .grid-4 {
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .lottie-animation {
        position: static;
        width: 100%;
        height: 100%;
        -webkit-transform: rotate(-54deg);
        -ms-transform: rotate(-54deg);
        transform: rotate(-54deg)
    }

    .lottie-animation.cc_home_page {
        display: none
    }

    .img_heroframe.cc-commercial-1 {
        height: 350px
    }

    .img_heroframe.cc-commercial-2 {
        width: 250px;
        height: 250px;
        max-width: 400px
    }

    .img_heroframe.cc-commercial-3 {
        width: 300px;
        height: 200px;
        max-width: 400px
    }

    .img_heroframe.cc-reshero-1 {
        height: 350px
    }

    .img_heroframe.cc-reshero_2 {
        width: 300px;
        height: 200px;
        max-width: 400px
    }

    .img_heroframe.cc-reshero-3 {
        width: 250px;
        height: 250px;
        max-width: 400px
    }

    .img_heroframe.cc-installhero-1 {
        height: 350px
    }

    .img_heroframe.cc-installhero-2 {
        width: 300px;
        height: 200px;
        max-width: 400px
    }

    .img_heroframe.cc-installhero-3 {
        width: 250px;
        height: 250px;
        max-width: 400px
    }

    .img_heroframe.cc-res1 {
        height: 350px
    }

    .img_heroframe.cc-residential3 {
        width: 250px;
        height: 250px;
        max-width: 400px
    }

    .img_heroframe.cc-residential2 {
        width: 300px;
        height: 200px;
        max-width: 400px
    }

    .w_scrollline {
        top: 74px;
        display: block;
        padding-left: 5%
    }

    .w_scrollline.cc-hiddenm {
        top: 95px;
        display: block
    }

    .w_scrollline.cc-center {
        top: 0;
        padding-left: 0
    }

    .w_scrollline.cc-center.cc-hidemobile {
        display: none
    }

    .w_rep_info_content {
        z-index: 1000000;
        display: none
    }

    .w_ripple {
        left: -15%;
        top: -1%;
        width: 500px;
        height: 500px
    }

    .c_homeleft {
        text-align: left
    }

    .g_commercialinnerhero {
        grid-column-gap: 50px;
        -ms-grid-columns: auto auto auto;
        grid-template-columns: auto auto auto
    }

    .cc-blogfilter {
        margin-left: 10px
    }

    .h_large {
        font-size: 4em
    }

    .a_stickynavlink, .tabl_products {
        font-size: 1em;
        text-align: center
    }

    .w_vertprojecttitle {
        left: -25px
    }

    .w_stickynav {
        top: -2px;
        z-index: 99999;
        background-color: #fff
    }

    .nav_stickymenu {
        padding: 5%;
        background-color: #fff
    }

    .a_stickynavlink {
        padding: 4px
    }

    .grid-13.cc_faq, .grid-15, .w_altcards {
        padding-left: 0
    }

    .w_altcards {
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch
    }

    .nav_sticky {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .nav_sticky.cc_hide {
        display: none
    }

    .grid-17 {
        grid-column-gap: 15px
    }

    .w_product_item {
        padding-top: 32px;
        padding-left: 40px
    }

    .img_products_item {
        left: 24%;
        top: 68%;
        right: 0;
        bottom: 0;
        width: 80%;
        max-width: 100%;
        -webkit-transform: rotate(70.12deg);
        -ms-transform: rotate(70.12deg);
        transform: rotate(70.12deg);
        -o-object-fit: cover;
        object-fit: cover
    }

    .img_products_item.cc_baswa_cool {
        left: auto;
        top: 44%;
        right: -10%;
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0)
    }

    .img_products_item.cc_baswa_hybrid {
        left: 3%;
        top: auto;
        right: 0;
        bottom: -11%;
        width: 200%;
        height: 100%;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        -o-object-fit: contain;
        object-fit: contain
    }

    .d_products_2items_row {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .w_prods_clean_item {
        padding-right: 40px;
        padding-bottom: 20px;
        padding-left: 40px;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .d_prods_clean_content {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
        padding-top: 30px;
        padding-bottom: 0;
        padding-left: 0
    }

    .tabm_products {
        position: relative;
        top: 0
    }

    .burger-line-bottom, .burger-line-top {
        width: 20px
    }

    .s_altcardtitle {
        margin-top: 0;
        padding-top: 20%
    }

    .menu-button {
        margin-left: 20px;
        background-color: #1c5291;
        color: #fff
    }

    .sp_stickymenuname {
        display: block;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .g_resbtngroup {
        margin-top: 20px;
        margin-right: 30px;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: start;
        -ms-flex-line-pack: start;
        align-content: start;
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .grid-32, .grid-37 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .w_productblock.cc-mobilegrowdown {
        padding-bottom: 175px
    }

    .img_awardbadge {
        top: -13%;
        width: 120px
    }

    .h_blogtitle {
        min-height: auto
    }

    .w_stage {
        border-top-style: none;
        border-right-style: none;
        border-left-style: none
    }

    .w_techdatablock {
        padding-right: 0;
        padding-left: 0
    }

    .s_techresources {
        padding-left: 0
    }

    .w_lopanelhome {
        left: 0;
        bottom: 0
    }

    .s_repfrom {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .grid-37 {
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .img_panel_home {
        display: block;
        width: 100%
    }

    .collection-list {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .grid-40, .grid-41 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .grid-40 {
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto
    }

    .div-block-9 {
        display: block
    }

    .c_portfolio_tags {
        width: 100%;
        margin-top: 10px;
        margin-right: 0;
        margin-left: 0;
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .h2_install, .heading-8 {
        display: none
    }
}

@media screen and (max-width: 479px) {
    .p_alternatinggroups, .w_footerlinks {
        text-align: left
    }

    .cc-conedu, .cc-conedu.w--current {
        font-weight: 500;
        text-decoration: none
    }

    body {
        font-size: 12.5px
    }

    h1, h2 {
        font-size: 2.5em
    }

    h3 {
        font-size: 1.9em
    }

    p {
        font-size: 1.3em
    }

    .c_page {
        margin-bottom: 25%;
        padding-right: 10%;
        padding-left: 10%
    }

    .g_qualities, .p_section_text.cc_tech_spec_p {
        margin-bottom: 15px
    }

    .w_nav {
        position: relative;
        z-index: 999
    }

    .img_navlogo {
        height: 45px
    }

    .tl_nav_link.cc_smallermargin.cc-hide {
        display: none
    }

    .tb_herobigheading {
        font-size: 3.7em
    }

    .s_alternated_cards {
        margin-top: 10%
    }

    .w_alternatingscards.cc_commecial_page {
        padding-bottom: 10%
    }

    .d_connectorline {
        width: 1px;
        height: 167px;
        margin-top: -20%;
        margin-left: 80%
    }

    .d_connectorline.cc_group3 {
        margin-top: -36%;
        margin-right: 40%;
        margin-left: auto
    }

    .d_connectorline.cc_group4 {
        margin-top: -20%;
        margin-left: 4.2%
    }

    .d_connectorline.cc_group5 {
        margin-top: -30%;
        margin-right: 9%;
        margin-left: auto
    }

    .d_connectorline.cc_group6 {
        margin-left: 64.5%
    }

    .w_alternatingcardstext, .w_alternatingcardstext.cc_group2 {
        margin-left: auto;
        margin-right: auto
    }

    .w_alternatingcardstext {
        width: 90%;
        margin-top: 0
    }

    .w_alternatingcardstext.cc_group3 {
        width: 100%;
        margin-top: 0
    }

    .w_alternatingcardstext.cc_group4 {
        width: 95%;
        margin-top: 0;
        margin-left: 4.2%
    }

    .w_alternatingcardstext.cc_group5 {
        width: 91%;
        margin-top: 0;
        margin-right: auto;
        margin-left: auto
    }

    .w_alternatingcardstext.cc_group6 {
        width: 90%;
        margin-right: auto;
        margin-left: auto
    }

    .w_alternatingimagegroup.cc_group4 {
        width: 80%
    }

    .w_alternatingimagegroup.cc_group1 {
        max-width: 100%
    }

    .w_alternatedgroup {
        margin-top: 20%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_alternatedgroup.cc_group4 {
        margin-top: 20%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_accoustics_image_text, .w_alternatedgroup.cc_group1 {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical
    }

    .w_alternatedgroup.cc_group5 {
        margin-top: 20%
    }

    .w_alternatedgroup.cc_group1 {
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_alternatedgroup.cc_group2 {
        width: 100%;
        margin-top: 20%;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .g_qualities {
        margin-top: 5%;
        grid-row-gap: 20px
    }

    .btn.cc_panel_inst_cta {
        margin-left: 0
    }

    .btn.cc_btn_prod_i_tabs_2nd {
        margin-top: 15px;
        margin-right: 0;
        margin-left: 0
    }

    .btn.cc_products_btn {
        margin-right: 0;
        margin-left: 0
    }

    .tb_testimony_author {
        margin-top: 15px
    }

    .w_footercontent {
        grid-column-gap: 40px;
        grid-row-gap: 0
    }

    .w_footerlinks {
        padding-top: 0;
        padding-bottom: 30px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .h4_footerlinkgroup {
        font-size: 1.3em;
        text-align: center
    }

    .h2_alternating_card {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .c_navbar_mobile {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .s_homehero {
        height: 1100px;
        margin-top: 0;
        padding-top: 0%;
        padding-bottom: 0;
    }

    .w_homeherotext {
        margin-top: 0;
        padding-top: 0;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .d_centeredtextandline {
        width: 15px
    }

    .scrollline.cc-light {
        background-color: #fff
    }

    .w_homesectiontext, .w_homesectiontext.cc_accoustics_section {
        margin-left: 0
    }

    .w_homewidesrotatedtext {
        margin-left: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .tb_sideways_home_wide_s {
        display: block;
        margin-right: auto
    }

    .w_homecardandtext {
        width: 100%
    }

    .w_accoustics_image_text {
        padding-left: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .d_accoustic_image_text_item {
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse
    }

    .w_home_tech_specs_content, .w_types_card.cc_middle_card {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .d_accoustic_image_text_item.cc_second_item, .w_content_accoustic_div {
        margin-bottom: 10%
    }

    .w_content_accoustic_div.cc_second_div {
        margin-bottom: 0;
        padding-bottom: 0
    }

    .w_content_accoustic_div.cc_tech_data_hero {
        margin-right: 5%;
        margin-left: 5%
    }

    .w_content_accoustic_div.cc-alightright {
        margin-left: 5%;
        padding-top: 5%;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
        text-align: left
    }

    .w_content_accoustic_div.cc-alignleft {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_accoustic_img {
        height: auto;
        padding-bottom: 65%
    }

    .s_home_layers {
        padding-top: 50%;
        padding-bottom: 20%
    }

    .w_layers_img {
        width: 100%
    }

    .w_layers_text {
        margin-top: 8px;
        margin-left: 0
    }

    .s_home_tech_specs {
        margin-top: 0;
        padding-top: 10%
    }

    .w_home_spects_title {
        padding-right: 0;
        padding-left: 0;
        text-align: left
    }

    .w_home_tech_specs_content {
        margin-top: 10%;
        margin-bottom: 10%;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tb_tech_spec_number {
        font-size: 1.5em;
        text-align: center
    }

    .h3_tech_spec_name {
        font-size: .8em;
        text-align: center
    }

    .w_home_awards_content {
        width: 100%;
        margin-left: 0
    }

    .w_home_award {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_award_img {
        width: 100px;
        height: 100px
    }

    .s_baswa_types {
        margin-bottom: 20%
    }

    .w_types_card {
        width: 100%
    }

    .w_types_card.cc_middle_card {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .p_home_services_types {
        margin-top: 10px;
        margin-bottom: 15px;
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .w_services_types_img {
        padding-bottom: 65%;
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .w_services_types_img.cc_middle_card_img {
        margin-bottom: 0;
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .h1_home_video_title {
        margin-left: 0
    }

    .w_home_video_holder {
        width: 100%;
        margin-left: 0
    }

    .w_commercial_alternating_cards {
        width: 100%;
        margin-top: 10%;
        margin-right: auto;
        margin-left: auto;
        padding-top: 0;
        padding-right: 5%;
        padding-left: 5%;
        text-align: left
    }

    .w_commercial_alternating_cards.cc_text_group_2 {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-top: 0;
        text-align: left
    }

    .w_commercial_alternating_cards.cc_text_group_3 {
        padding-top: 0
    }

    .w_commercial_alternating_cards.cc_text_group_4 {
        margin-right: auto;
        margin-left: auto;
        padding-top: 0
    }

    .w_commercial_alternating_cards.cc_text_group_5 {
        padding-top: 0;
        padding-right: 5%;
        padding-left: 5%;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1;
        text-align: center
    }

    .w_commercial_alternating_cards.cc_text_group_11, .w_commercial_alternating_cards.cc_text_group_9 {
        text-align: left;
        width: 100%;
        padding-top: 0;
        padding-right: 5%;
        padding-left: 5%
    }

    .w_commercial_alternating_cards.cc_text_group_6 {
        margin-right: auto;
        margin-left: auto;
        padding-top: 0;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_7 {
        margin-right: 0;
        padding-top: 0;
        padding-right: 20px;
        padding-left: 20px;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .w_commercial_alternating_cards.cc_text_group_8 {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: 5%;
        padding-left: 5%
    }

    .w_commercial_alternating_cards.cc_text_group_10 {
        width: 100%;
        max-width: none;
        margin-right: 0;
        padding-top: 0;
        padding-right: 5%;
        padding-left: 5%
    }

    .a_stickynavlink, .btn_beige, .btn_black, .grid-11, .h3_blog_big_img_info, .indv_prod_case_study_item, .s_contact_map, .s_indv_prod_case, .tabl_products, .text-block, .w_additional_video {
        text-align: center
    }

    .w_commercial_alternating_cards.cc_text_group_12 {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-top: 0;
        padding-right: 5%;
        padding-left: 5%
    }

    .w_commercial_alternating_image.cc_group1 {
        max-width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group2 {
        max-width: 100%;
        padding-bottom: 65%;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .w_commercial_alternated_group, .w_commercial_alternated_group.cc_group1 {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_commercial_alternating_image.cc_group3 {
        max-width: 100%
    }

    .w_commercial_alternating_image.cc_group4 {
        max-width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group5 {
        max-width: 100%
    }

    .w_commercial_alternating_image.cc_group6 {
        max-width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group7, .w_commercial_alternating_image.cc_group8, .w_commercial_alternating_image.cc_group9 {
        max-width: 100%
    }

    .w_commercial_alternating_image.cc_group11 {
        max-width: 100%;
        padding-bottom: 65%
    }

    .w_commercial_alternating_image.cc_group12 {
        max-width: 100%
    }

    .w_commercial_alternating_image.cc_group_10 {
        padding-bottom: 65%
    }

    .d_commercial_cards_divider {
        width: 1px;
        height: 167px;
        margin-top: -20%;
        margin-left: 80%
    }

    .w_commercial_alternated_group {
        margin-top: 20%;
        padding-right: 5%;
        padding-left: 5%;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_commercial_alternated_group.cc_group5 {
        margin-top: 20%;
        width: 100%
    }

    .w_commercial_alternated_group.cc_group1 {
        margin-right: auto;
        margin-left: auto;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_commercial_alternated_group.cc_group2 {
        width: 100%;
        margin-top: 20%;
        margin-right: auto;
        margin-left: auto
    }

    .w_commercial_alternated_group.cc_group3 {
        margin-top: 20%;
        margin-right: auto;
        margin-left: auto
    }

    .w_commercial_alternated_group.cc_group4 {
        width: 100%;
        margin-top: 20%;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .tm_procuts_top_tab, .w_form {
        -webkit-justify-content: space-around
    }

    .w_commercial_alternated_group.cc_group10, .w_commercial_alternated_group.cc_group11, .w_commercial_alternated_group.cc_group12, .w_commercial_alternated_group.cc_group13, .w_commercial_alternated_group.cc_group6, .w_commercial_alternated_group.cc_group7, .w_commercial_alternated_group.cc_group8, .w_commercial_alternated_group.cc_group9 {
        width: 100%;
        margin-top: 20%
    }

    .w_commercial_alternated_group.cc_group14 {
        width: 100%;
        margin-top: 20%;
        margin-bottom: 0
    }

    .w_healthy_architecture_bottom {
        margin-top: 15px;
        margin-left: 0
    }

    .w_healthy_arch_rotated_text {
        margin-left: 0
    }

    .w_testimony_info {
        margin-top: 10px
    }

    .tb_testimony_author_subtext {
        margin-top: 5px
    }

    .h1_portfolio_hero_name {
        margin-top: 5px;
        margin-bottom: 19px
    }

    .s_portfolio_search_controls {
        padding-right: 5%;
        padding-left: 5%;
        background-color: #fafafa
    }

    .w_form {
        -ms-flex-pack: distribute;
        justify-content: space-around
    }

    .cli_portfolio_checkbox {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }

    .s_indv_port_hero {
        padding-top: 70px;
        padding-bottom: 65px
    }

    .w_indv_port_img_sec_award {
        top: -50px;
        width: 100px;
        height: 100px
    }

    .tb_indv_port_award {
        font-size: 14px;
        line-height: 125%
    }

    .s_ind_port_main_content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_quote {
        margin-bottom: 60px
    }

    .w_main_content_right {
        right: 0;
        width: 100%;
        margin-top: 80px
    }

    .tb_rotated_text {
        padding: 9px 15px
    }

    .tb_background_text {
        font-size: 38px
    }

    .s_indv_port_background_text {
        margin-top: 100px
    }

    .s_blog_hero {
        height: 80vh;
        min-height: 450px;
        margin-top: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff
    }

    .w_blog_hero_bg {
        background-image: url(https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png);
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat
    }

    .img_blog_hero_bg {
        opacity: .6
    }

    .w_blog_hero_text {
        margin-right: auto;
        margin-bottom: 10%;
        margin-left: auto;
        padding: 0;
        background-color: transparent;
        color: #fff
    }

    .h1_blog_hero {
        font-size: 3em
    }

    .rte_blog_content {
        margin-left: 0
    }

    .s_4_images_row {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_blog_content_images {
        width: 100%;
        margin-left: 0;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .d_image_item {
        width: 45%;
        max-width: none;
        margin-right: auto;
        margin-left: auto;
        padding-bottom: 45%;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .s_blog_big_image, .w_installation_process_step {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column
    }

    .tb_images_section {
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0)
    }

    .h2_blog_content {
        font-size: 2.2em
    }

    .s_blog_big_image {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .h3_blog_big_img_info {
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        margin-left: 0;
        -webkit-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0);
        white-space: normal
    }

    .d_blog_content_bottom_info_line {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto
    }

    .h2_blog_more {
        margin-right: 5%;
        margin-left: 5%;
        font-size: 2.3em
    }

    .h1_page_title {
        font-size: 6em
    }

    .w_main_blog_filter {
        width: 100%
    }

    .ci_blog_post_item {
        margin-right: 0;
        margin-bottom: 30%;
        margin-left: 0
    }

    .d_main_blog_link_to_post {
        margin-top: 14px;
        margin-bottom: 14px
    }

    .w_main_blog_post_image {
        height: auto;
        padding-top: 0;
        padding-bottom: 65%
    }

    .fbi_contact_page {
        font-size: 1em
    }

    .w_contact_social_media_icons {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .s_contact_map {
        margin-bottom: 16%
    }

    .h3_contact_map {
        position: static;
        margin-bottom: 20px
    }

    .d_contact_page_images {
        -o-object-fit: none;
        object-fit: none
    }

    .img_contact_page_bottom_placeholder {
        position: relative
    }

    .g_contact_bottom_images {
        position: relative;
        -ms-grid-columns: auto;
        grid-template-columns: auto;
        -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto
    }

    .h2_faq_page_title {
        font-size: 4em
    }

    .h2_faq_group_title {
        font-size: 1.5em
    }

    .w_question_content {
        width: 88%
    }

    .ci_faq {
        margin-bottom: 27px;
        padding-bottom: 25px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .w_faq_answer {
        width: 88%;
        max-width: 88%
    }

    .b_title.cc-half, .h1_prods_hero {
        max-width: 100%
    }

    .v_healthyarch {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .bgv {
        /* background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, .4))); */
        /* background-image: linear-gradient(180deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4)); */
    }

    .h2_portfolio_item {
        font-size: 2em
    }

    .h1_pillar_hero {
        font-size: 4.1em
    }

    .w_features {
        margin-top: -42px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .h2_pillar_content, .tb_pillar_content_excerpt {
        margin-left: 5%
    }

    .d_installation_icon, .h1_panel_inst_hero, .h5_indv_prod_case_study, .p_blog_content {
        margin-left: 0
    }

    .h1_panel_inst_hero {
        font-size: 4em
    }

    .w_panel_inst_hero_bottom {
        width: 100%
    }

    .h2_panel_inst_hero {
        font-size: 2.5em
    }

    .h1_panel_i_content {
        font-size: 2.2em
    }

    .w_installation_process_step {
        margin-top: 0;
        padding-top: 70px;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .s_panel_inst_cta, .w_products_buttons {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .p_inst_step {
        margin-top: 30px
    }

    .h2_p_inst_step {
        font-size: 2.1em
    }

    .s_panel_inst_cta {
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_panel_i_cta_wrapper {
        margin-right: 0;
        margin-bottom: 40px
    }

    .p_panel_inst_cta {
        margin-top: 15px;
        margin-bottom: 20px
    }

    .h4_additional_video, .tabl_tech_data {
        margin-bottom: 10px
    }

    .img_p_inst_hero {
        -o-object-position: 0 0;
        object-position: 0 0
    }

    .h1_products_hero {
        font-size: 5em
    }

    .tl_products_top_tab {
        display: inline-block;
        margin-bottom: 10px
    }

    .tm_procuts_top_tab {
        display: block;
        -ms-flex-pack: distribute;
        justify-content: space-around
    }

    .tm_products_inner_tabs {
        padding-bottom: 16px;
        grid-column-gap: 10px;
        grid-row-gap: 10px
    }

    .w_installation_icons {
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around
    }

    .d_installation_icon {
        margin-right: 0
    }

    .w_products_buttons {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .h1_tech_data_hero {
        font-size: 4em
    }

    .c_tech_data {
        padding-right: 5%;
        padding-left: 5%
    }

    .h2_tech_data_section_name {
        font-size: 3.5em
    }

    .d_accoustic_test_results.cc_typee.cc_tooltip_conditioned {
        display: block;
        padding-right: 0;
        padding-left: 0
    }

    .d_accoustic_test_results.cc_typea.cc_tooltip_conditioned {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding-right: 0;
        padding-left: 0
    }

    .tabm_tech_data {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom-color: transparent
    }

    .tabl_tech_data {
        width: 100%;
        padding-top: 15px;
        border: 1px solid #e1e1e1
    }

    .tabm_tech_data_other_test, .tabm_tech_data_spec_dets {
        -webkit-flex-direction: column;
        border-bottom-color: transparent;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .tabl_tech_data.w--current {
        border-bottom-width: 2px
    }

    .tabm_tech_data_other_test {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .tabl_tech_data_inner_tab {
        padding: 10px
    }

    .c_blog_hero, .slider {
        padding-right: 5%;
        padding-left: 5%
    }

    .tabs-menu {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr
    }

    .tabm_tech_data_spec_dets {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .nav_sticky, .nav_stickymenu {
        border-top: 1px solid #e1e1e1;
        border-bottom: 1px solid #e1e1e1
    }

    .w_additional_video {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_additional_video_thumb {
        margin-right: 0;
        margin-bottom: 25px
    }

    .d_additional_videos_separator {
        margin-top: 8px;
        margin-bottom: 18px
    }

    .s_tech_data_ctas {
        grid-row-gap: 40px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .w_indv_prod_hero_right {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .w_indv_prod_other_prod, .w_indv_prods_btns {
        -webkit-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal
    }

    .w_indv_prod_settings {
        margin-right: 0;
        margin-bottom: 0
    }

    .navdd_indv_prod_hero_r {
        margin-top: 10px;
        margin-left: 0
    }

    .tm_indv_prod_tab {
        display: block
    }

    .w_indv_prod_other_prod {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .w_imdv_prod_image_info_dots, .w_indv_prod_tooltip.cc_techdata {
        display: none
    }

    .d_indv_prod_img_holder {
        width: 150px;
        height: 205px
    }

    .tc_indv_prod_tab {
        margin-bottom: 29.3%
    }

    .mask {
        width: 100%;
        height: 410px
    }

    .slider {
        height: 440px;
        text-align: center
    }

    .tb_indv_prod_case_item {
        margin-right: 0
    }

    .slider_arrow {
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0)
    }

    .w_switching_layers.cc_svg {
        margin-right: 5%;
        margin-bottom: 0
    }

    .tb_products_subtab_description {
        margin-top: auto;
        margin-bottom: auto
    }

    .c_blog_hero {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch
    }

    .w_portfolio_checkbox {
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }

    .fbi_text_filter {
        background-color: #fff
    }

    .d_quote_form_content.cc_v2 {
        padding-bottom: 10%
    }

    .d_prod_quote_f_left.cc_v2 {
        margin-bottom: 20px
    }

    .d_options_holder {
        -ms-grid-columns: minmax(50px, 197px) minmax(50px, 197px);
        grid-template-columns: minmax(50px, 197px) minmax(50px, 197px);
        -ms-grid-rows: 1fr auto;
        grid-template-rows: 1fr auto
    }

    .g_resbtngroup, .grid-12 {
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .d_options_holder.cc_v2 {
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr
    }

    .fbb_indv_prod_quote_sub {
        font-size: 1.2em
    }

    .img_portfolio_item {
        height: 300px
    }

    .g_portfolio_no_img {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .w_blog_share {
        left: 0;
        top: auto;
        right: 0;
        bottom: 30px;
        margin-right: auto;
        margin-left: auto
    }

    .materials-bg {
        background-position: 100% 50%
    }

    .gradient-overlay {
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .89)), to(rgba(0, 0, 0, .89)));
        background-image: linear-gradient(180deg, rgba(0, 0, 0, .89), rgba(0, 0, 0, .89))
    }

    .grid-2 {
        grid-column-gap: 20px;
        -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr
    }

    .grid-11, .grid-3 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .c_type-content {
        margin-bottom: 0
    }

    .btn_beige {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .btn_beige.cc-moretopspace {
        -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
        align-self: flex-start
    }

    .s_comres_hero {
        z-index: 99;
        overflow: hidden
    }

    .w_indv_prod_side_panel {
        left: 0;
        top: 12%;
        right: auto;
        bottom: auto;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .w_imdv_prod_image_info_dots.cc-show {
        position: absolute
    }

    .gi_rep, .lo_panel, .lottie-animation, .tabm_products {
        position: relative
    }

    .w_info_dot_item {
        top: 14%
    }

    .w_info_dot_item.cc_beacon2 {
        left: 61%;
        top: 33%
    }

    .w_info_dot_item.cc_beacon3 {
        top: 63%
    }

    .w_indv_prods_btns {
        width: 99%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .tl_indv_prod {
        display: inline-block
    }

    .img_heroframe.cc-f3, .img_heroframe.cc-f6, .img_heroframe.cc-f7, .img_heroframe.cc-f8 {
        display: none
    }

    .m_scrollline.cc-light.cc-pushright {
        background-color: hsla(0, 0%, 100%, .1)
    }

    .w_headerimages.cc-commercial {
        top: -46px;
        margin-left: 0
    }

    .s_healthyarch {
        margin-top: 0
    }

    .w_hub_loc_item {
        top: 14%
    }

    .w_reps_table_caption {
        margin-top: 15px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .w_reps_caption_item {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .lo_panel {
        right: 10%;
        width: 100vw;
        margin-bottom: -110px
    }

    .lo_panel.cc_home {
        right: auto;
        bottom: 0;
        margin-bottom: 0
    }

    .gi_rep {
        width: 100%;
        margin-right: 0;
        margin-bottom: 20px;
        margin-left: 0
    }

    .lottie-animation {
        left: -47%;
        right: auto;
        width: 200%;
        height: 100%
    }

    .img_heroframe.cc-f1 {
        height: 400px
    }

    .img_heroframe.cc-f2 {
        display: none;
        height: 400px
    }

    .img_heroframe.cc-f4 {
        height: 300px
    }

    .img_heroframe.cc-f5 {
        height: 350px
    }

    .img_heroframe.cc-commercial-1 {
        width: 180px;
        height: 250px
    }

    .img_heroframe.cc-commercial-3 {
        width: 150px
    }

    .img_heroframe.cc-reshero-1 {
        width: 180px;
        height: 250px
    }

    .img_heroframe.cc-reshero_2 {
        width: 150px
    }

    .img_heroframe.cc-installhero-1 {
        width: 180px;
        height: 250px
    }

    .img_heroframe.cc-installhero-2 {
        width: 150px
    }

    .img_heroframe.cc-res1 {
        width: 180px;
        height: 250px
    }

    .img_heroframe.cc-residential2 {
        width: 150px
    }

    .grid-6 {
        grid-column-gap: 30px;
        grid-row-gap: 30px;
    }

    .grid-8 {
        margin-top: 200px
    }

    .w_scrollline {
        top: 38px;
        margin-left: 5%;
        padding-left: 0
    }

    .w_rep_info {
        z-index: 1
    }

    .w_sociallinks {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: center;
        -webkit-transform: scale(.8);
        -ms-transform: scale(.8);
        transform: scale(.8)
    }

    .grid-12 {
        display: -ms-grid;
        display: grid;
        width: 100%;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        grid-auto-columns: 1fr;
        grid-column-gap: 20px;
        grid-row-gap: 16px;
        -ms-grid-columns: auto auto auto auto auto auto 1fr;
        grid-template-columns: auto auto auto auto auto auto 1fr
    }

    .g_tdcleaning, .grid-19, .grid-20 {
        grid-column-gap: 0
    }

    .w_subscribeform {
        padding-top: 30px;
        padding-right: 0
    }

    .w_ripple {
        left: -20%;
        top: 1%;
        width: 400px
    }

    .w_ripple.cc-hidemobile {
        display: none
    }

    .c_inner, .c_map {
        padding-right: 5%;
        padding-left: 5%
    }

    .h_large {
        font-size: 3em
    }

    .h_xlarge {
        font-size: 4em
    }

    .w_vertprojecttitle {
        left: -65px;
        display: none
    }

    .w_stickynav {
        top: -1px;
        z-index: 9999;
        width: 100%;
        background-color: #fff
    }

    .grid-42, .s_repfrom, .w_mobilebgcover {
        z-index: 99
    }

    .nav_stickymenu {
        padding: 5%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        background-color: #fff;
        color: #fff
    }

    .a_stickynavlink {
        padding: 4px;
        font-size: 1em
    }

    .w_altcards {
        margin-top: 0;
        padding-left: 0
    }

    .nav_sticky {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .nav_sticky.cc_hide {
        display: none;
        overflow: visible
    }

    .grid-17 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .s_fwheader {
        padding-top: 25.5%;
        padding-bottom: 17.5%
    }

    .p_products_hero_v2 {
        margin-top: 15px
    }

    .s_products_tab_v2 {
        margin-top: 30px;
        margin-bottom: 60px
    }

    .tabl_products {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
        margin-left: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #e1e1e1
    }

    .tabl_products.w--current {
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom-color: #1c5291
    }

    .tabc_products {
        margin-top: 25px
    }

    .w_product_item {
        padding: 10% 5%
    }

    .w_product_item.cc-half, .w_product_item.cc_reduced, .w_products_finishes_item {
        padding-top: 10%;
        padding-left: 5%
    }

    .img_products_item {
        left: 0;
        top: auto;
        right: 0;
        bottom: -375px;
        width: 1000%;
        -webkit-transform: translate(0, -50%) rotate(70.12deg);
        -ms-transform: translate(0, -50%) rotate(70.12deg);
        transform: translate(0, -50%) rotate(70.12deg)
    }

    .img_products_item.cc_baswa_cool {
        top: 317px;
        right: -30px;
        width: 82%;
        max-width: 100%;
        -webkit-transform: translate(0, -7%);
        -ms-transform: translate(0, -7%);
        transform: translate(0, -7%)
    }

    .img_products_item.cc_baswa_hybrid {
        bottom: -15%
    }

    .w_products_finishes_item {
        min-height: 300px;
        padding-right: 5%
    }

    .w_prods_clean_item {
        margin-bottom: 15%;
        padding: 10% 5%
    }

    .d_prods_clean_content {
        padding-top: 0
    }

    .w_prodimg.cc-fullwidth {
        height: 150px;
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .tabm_products {
        top: 0;
        display: block;
        border-bottom: 1px none #e1e1e1
    }

    .h1_prods_hero {
        width: 100%;
        margin-bottom: 15px;
        white-space: normal
    }

    .s_altcardtitle {
        padding-top: 10%
    }

    .menu-button.w--open {
        background-color: #fff;
        color: #000
    }

    .h_blogcategory.cc-whitemobile, .h_blogposttitle, .h_whitemobile {
        color: #fff
    }

    .g_resbtngroup {
        width: 100%;
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -ms-grid-columns: auto auto;
        grid-template-columns: auto auto
    }

    .collection-list, .g_resbtngroup.cc-right, .grid-20, .grid-36, .grid-37, .grid-39, .grid-40, .grid-41, .grid-44 {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr
    }

    .g_resbtngroup.cc-right {
        width: 100%
    }

    .btn_black {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .cc-righttext, .d_awardlead {
        text-align: left
    }

    .w_productblock {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        min-height: 300px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .c_productcontent.cc-condensedwidth2 {
        padding: 10%
    }

    .img_awardbadge {
        width: 100px
    }

    .a_catlink {
        padding: 4px;
        font-size: 1em
    }

    .btn_blueshare.cc-vertical {
        margin-right: auto;
        margin-left: auto
    }

    ._100 {
        height: 100%
    }

    .darkoverlay {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .39)
    }

    .s_awards {
        margin-top: 10%
    }

    .d_awardlead {
        margin-right: 0;
        margin-left: 0;
        padding-right: 0;
        border-right-width: 0
    }

    .d_product_setting_content.cc_extended.cc_thickness {
        height: 295px;
        padding-top: 15px;
        padding-bottom: 15px
    }

    .d_thickness_option {
        width: auto;
        margin-top: 5px;
        margin-bottom: 5px;
        padding-top: 16px;
        padding-right: 10%;
        padding-left: 0;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .d_customizer_form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .s_techresources {
        margin-top: 0;
        padding-left: 0
    }

    .vid_healthyarc {
        height: 50vh
    }

    .img_playbtn {
        max-width: 75px
    }

    .w_hainformaion {
        padding-top: 10%
    }

    .w_resvideo {
        height: 35vh
    }

    .v_resvideo {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 0;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .a_btt {
        right: 5%;
        width: 30px;
        height: 30px
    }

    .i_btt {
        width: 15px;
        height: 15px
    }

    .tb_share_blogpost {
        padding: 9px 15px
    }

    .d_resourcelib, .w_coursetitlecontent {
        padding-top: 70px
    }

    .s_repfrom {
        overflow: hidden;
        margin-bottom: 10%
    }

    .grid-37 {
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .grid-42 {
        position: static;
        top: 20px;
        padding-top: 20px;
        -ms-grid-rows: auto;
        grid-template-rows: auto
    }

    .cc-conedu {
        font-size: .9em
    }

    .cc-conedu.w--current, .cc-conedu:active {
        color: #1c5291
    }

    .div-block-9 {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 99;
        display: block;
        margin-bottom: 0;
        padding-bottom: 0;
        background-color: #fff
    }

    .grid-44 {
        -ms-grid-rows: auto auto;
        grid-template-rows: auto auto
    }

    .grid-45 {
        margin-right: auto;
        margin-left: auto;
        -webkit-box-pack: stretch;
        -webkit-justify-content: stretch;
        -ms-flex-pack: stretch;
        justify-content: stretch;
        justify-items: stretch
    }

    .rich-text-block {
        margin-top: 7%
    }
    .f_mainfooter .c_page {
        margin-bottom: 0%;
    }
}

#w-node-f9a4d6293485-3a368f7a {
    -ms-grid-row-span: 1;
    -ms-grid-column-align: end;
    justify-self: end
}

#w-node-8226eb520370-3a368f7a {
    -ms-grid-row-align: end;
    align-self: end;
}

#w-node-1567172d1258-c1d734e0, #w-node-184d7230ab50-abc16c15, #w-node-1f5cbbb84497-5cb00601, #w-node-35e2ed03adf0-3a368f7a, #w-node-8b0de2fd0c8d-abc16c15, #w-node-b9f38cfff70c-abc16c15, #w-node-dc26b99847b3-c1d734e0, #w-node-efbbfd3ac0f9-fd3ac0f6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1
}

#w-node-2366d69a9dfd-5cb00601, #w-node-2fbcb444dc92-5cb00601, #w-node-2fbcb444dc93-5cb00601, #w-node-4290867e8583-5cb00601, #w-node-48a4963f117f-5cb00601, #w-node-48a4963f1180-5cb00601, #w-node-531dfa115bc1-5cb00601, #w-node-67ad7f953a90-5cb00601, #w-node-69ff12617a6c-5cb00601, #w-node-722f6044d1a4-c1d734e0, #w-node-7a0cfa8af6ff-5cb00601, #w-node-7ab4b5e86cb0-5cb00601, #w-node-886332e35b13-5cb00601, #w-node-cbb8c51d9c0a-3a368f7a, #w-node-d54c114240bd-5cb00601, #w-node-d54c114240be-5cb00601, #w-node-d714042c4641-5cb00601, #w-node-d714042c4642-5cb00601, #w-node-d9312648704c-5cb00601, #w-node-d9312648704d-5cb00601, #w-node-d9c24377ba48-5cb00601, #w-node-e4f9b6f8a61b-5cb00601, #w-node-efbbfd3ac119-fd3ac0f6 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-494e2d4310f2-3a368f7a {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start;
    -ms-grid-column-align: end;
    justify-self: end
}

#w-node-0d0e9b1ee9de-c530f919, #w-node-48afe834b159-c530f919, #w-node-57ff6d411c59-c530f919, #w-node-886332e35b1e-5cb00601, #w-node-886332e35b28-5cb00601, #w-node-8a7574ba16b4-c530f919, #w-node-907d6837e851-3a368f7a, #w-node-bd92ac3b2be2-c530f919 {
    justify-self: end;
    -ms-grid-column-align: end
}

#w-node-907d6837e851-3a368f7a {
    -ms-grid-row-span: 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1
}

#w-node-3a996ff11d6b-3a368f7a, #w-node-6497ad234f20-3a368f7a, #w-node-a81cc02ba82e-fd3ac0f6 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-c29b58ea2522-fd3ac0f6 {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch
}

#w-node-0b2fe3b960f7-1417f64f, #w-node-a22481013540-c530f919 {
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 2;
    grid-column-end: 3
}

#w-node-0b2fe3b960f9-1417f64f, #w-node-a22481013542-c530f919 {
    -ms-grid-row: span 3;
    grid-row-start: span 3;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-90ea46874bf2-c530f919 {
    -ms-grid-row-align: auto;
    align-self: auto
}

#w-node-76a1d7127ebf-c530f919, #w-node-b948989b036a-1417f64f {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-3ca5873ec5c1-1417f64f, #w-node-6b1120e5a394-c530f919, #w-node-72dadc2e93e2-c530f919, #w-node-82fc913cd405-c530f919, #w-node-87a22b233a41-c530f919, #w-node-8c6ead2857d1-c530f919, #w-node-8cd9860e2412-c530f919, #w-node-a4bcdc45b432-c530f919, #w-node-daa17aab5b91-c530f919, #w-node-e3c9653ffcea-c530f919 {
    justify-self: baseline
}

#w-node-0d0e9b1ee9e0-c530f919, #w-node-3ca5873ec5c3-1417f64f, #w-node-48afe834b15b-c530f919, #w-node-57ff6d411c5b-c530f919, #w-node-6b1120e5a396-c530f919, #w-node-72dadc2e93e4-c530f919, #w-node-82fc913cd407-c530f919, #w-node-87a22b233a43-c530f919, #w-node-8a7574ba16b6-c530f919, #w-node-8c6ead2857d3-c530f919, #w-node-8cd9860e2414-c530f919, #w-node-a4bcdc45b434-c530f919, #w-node-bd92ac3b2be4-c530f919, #w-node-daa17aab5b93-c530f919, #w-node-e3c9653ffcec-c530f919 {
    justify-self: baseline;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto
}

#w-node-aa8a9b8400ed-c530f919 {
    -ms-grid-column-align: end;
    justify-self: end
}

#w-node-707b17e1ba72-9bc30d38 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
    justify-self: center
}

#w-node-43c2a3c1ad6e-4710397d, #w-node-ad52a90412e7-5cb00601 {
    -ms-grid-row-align: start;
    align-self: start;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2
}

#w-node-db615355d3af-1417f64f {
    -ms-grid-column-align: stretch;
    justify-self: stretch
}

#w-node-0b2fe3b960fd-1417f64f {
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-787161e3b336-5cb00601 {
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-74d4ee9bc855-5cb00601 {
    -ms-grid-column: span 4;
    grid-column-start: span 4;
    -ms-grid-column-span: 4;
    grid-column-end: span 4;
    -ms-grid-row-span: 1;
    -ms-grid-column-align: start;
    justify-self: start
}

#w-node-1119ec05be74-abc16c15, #w-node-215bf1a77890-5cb00601, #w-node-2e93488f68e0-abc16c15, #w-node-a7d8b16d34cf-5cb00601, #w-node-c499228ed8de-5cb00601 {
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column-align: stretch;
    -ms-grid-row-span: 1;
    align-self: center;
    -ms-grid-row-align: center
}

#w-node-215bf1a77890-5cb00601, #w-node-a7d8b16d34cf-5cb00601, #w-node-c499228ed8de-5cb00601 {
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1
}

#w-node-b0292e66ad53-5cb00601 {
    -ms-grid-column-align: center;
    justify-self: center;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-0456a383d3e5-abc16c15 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row-span: 1;
    grid-row-end: span 1
}

#w-node-2e93488f68e2-abc16c15, #w-node-718b3a833679-842bd412, #w-node-7fbe7e0fc03c-842bd412 {
    justify-self: stretch;
    -ms-grid-column-align: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-2e93488f68e2-abc16c15 {
    -ms-grid-row-span: 1
}

#Project-Description.w-node-0b8bcdf51f8d-abc16c15 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    -ms-grid-row-align: stretch;
    align-self: stretch
}

#Project-Description.w-node-605d34e45a48-80c32f12, #w-node-2e93488f6911-abc16c15, #w-node-b97c80c32f20-80c32f12 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-90ea46874bf2-c4ee97b2, #w-node-9b7175f66b8c-23060ddd, #w-node-aa8a9b8400eb-c4ee97b2, #w-node-aa8a9b8400ed-c4ee97b2, #w-node-fbaab485af98-c4ee97b2 {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2
}

#w-node-0a4a96f9aabe-59bd449c, #w-node-392ae874d29d-60a261cc, #w-node-489362a988a1-842bd412, #w-node-58ffffeba0b0-842bd412, #w-node-5994e397a0b1-842bd412, #w-node-61553412c5a0-60038a07, #w-node-90ea46874bf2-c4ee97b2, #w-node-9b43028a7466-08c11f78, #w-node-9b7175f66b8c-23060ddd, #w-node-a22481013542-c4ee97b2, #w-node-aa8a9b8400eb-c4ee97b2, #w-node-aa8a9b8400ed-c4ee97b2, #w-node-d9363c4ad047-f3df298d, #w-node-ec22c0563ccf-a07321ac, #w-node-f1e1e58e8a1d-842bd412, #w-node-fbaab485af98-c4ee97b2 {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    grid-column-end: span 1
}

#w-node-118b8f17c282-e7acebb4, #w-node-a22481013540-c4ee97b2 {
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1
}

#w-node-9b7175f66b8c-23060ddd {
    -ms-grid-column-span: 1
}

#w-node-58ffffeba0b0-842bd412 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-span: 1;
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-718b3a833679-842bd412, #w-node-7fbe7e0fc03c-842bd412 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-row-span: 1
}

#w-node-7fbe7e0fc03e-842bd412 {
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-489362a988a1-842bd412, #w-node-5994e397a0b1-842bd412, #w-node-f1e1e58e8a1d-842bd412 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column-span: 1
}

#w-node-a22481013540-c4ee97b2 {
    -ms-grid-column-span: 2;
    grid-column-end: 3
}

#w-node-a22481013542-c4ee97b2 {
    -ms-grid-row: span 3;
    grid-row-start: span 3;
    -ms-grid-row-span: 3;
    grid-row-end: span 3;
    -ms-grid-column-span: 1;
    -ms-grid-row-align: center;
    align-self: center
}

#w-node-90ea46874bf2-c4ee97b2 {
    -ms-grid-column-span: 1;
    -ms-grid-row-align: auto;
    align-self: auto
}

#w-node-24a144d773f2-c4ee97b2, #w-node-24a144d773f7-c4ee97b2, #w-node-24a144d773fc-c4ee97b2, #w-node-24a144d77401-c4ee97b2, #w-node-24a144d77406-c4ee97b2, #w-node-24a144d7740b-c4ee97b2, #w-node-7a7dcc26a6d8-a07321ac {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1
}

#w-node-76a1d7127ebf-c4ee97b2 {
    -webkit-align-self: start;
    -ms-flex-item-align: start;
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-03d8b9e8b5ae-c4ee97b2, #w-node-c9f22efbfd39-c4ee97b2 {
    -ms-grid-column-align: end;
    justify-self: end
}

#w-node-03d8b9e8b5b0-c4ee97b2, #w-node-c9f22efbfd3b-c4ee97b2 {
    -ms-grid-column-align: end;
    justify-self: end;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto
}

#w-node-aa8a9b8400ed-c4ee97b2 {
    -ms-grid-column-align: end;
    justify-self: end;
    -ms-grid-column-span: 1
}

#w-node-aa8a9b8400eb-c4ee97b2, #w-node-fbaab485af98-c4ee97b2 {
    -ms-grid-column-span: 1
}

#w-node-de32b88f9e8e-b88f9e8d {
    justify-self: baseline
}

#w-node-de32b88f9e90-b88f9e8d {
    justify-self: baseline;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    -ms-grid-row-align: auto;
    align-self: auto
}

#w-node-58ffffeba0b0-08c11f78, #w-node-58ffffeba0b0-10535eee, #w-node-58ffffeba0b0-1bacf594, #w-node-58ffffeba0b0-2da26772, #w-node-58ffffeba0b0-34a268b4, #w-node-58ffffeba0b0-59bd449c, #w-node-58ffffeba0b0-60038a07, #w-node-58ffffeba0b0-60a261cc, #w-node-58ffffeba0b0-79bf5ddf, #w-node-58ffffeba0b0-a07321ac, #w-node-58ffffeba0b0-e7acebb4, #w-node-58ffffeba0b0-f003983a, #w-node-58ffffeba0b0-f3df298d, #w-node-58ffffeba0b0-f8535eff, #w-node-58ffffeba0b0-fb5354be {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row-align: start;
    align-self: start
}

#w-node-7fbe7e0fc03c-08c11f78, #w-node-7fbe7e0fc03c-10535eee, #w-node-7fbe7e0fc03c-1bacf594, #w-node-7fbe7e0fc03c-2da26772, #w-node-7fbe7e0fc03c-34a268b4, #w-node-7fbe7e0fc03c-59bd449c, #w-node-7fbe7e0fc03c-60038a07, #w-node-7fbe7e0fc03c-60a261cc, #w-node-7fbe7e0fc03c-79bf5ddf, #w-node-7fbe7e0fc03c-a07321ac, #w-node-7fbe7e0fc03c-e7acebb4, #w-node-7fbe7e0fc03c-f003983a, #w-node-7fbe7e0fc03c-f3df298d, #w-node-7fbe7e0fc03c-f8535eff {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: stretch;
    justify-self: stretch
}

#w-node-0a4a96f9aabe-59bd449c, #w-node-392ae874d29d-60a261cc, #w-node-61553412c5a0-60038a07, #w-node-9b43028a7466-08c11f78, #w-node-d9363c4ad047-f3df298d, #w-node-ec22c0563ccf-a07321ac {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-column-span: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1
}

#w-node-7fbe7e0fc03e-08c11f78, #w-node-7fbe7e0fc03e-10535eee, #w-node-7fbe7e0fc03e-1bacf594, #w-node-7fbe7e0fc03e-2da26772, #w-node-7fbe7e0fc03e-34a268b4, #w-node-7fbe7e0fc03e-59bd449c, #w-node-7fbe7e0fc03e-60038a07, #w-node-7fbe7e0fc03e-60a261cc, #w-node-7fbe7e0fc03e-79bf5ddf, #w-node-7fbe7e0fc03e-a07321ac, #w-node-7fbe7e0fc03e-e7acebb4, #w-node-7fbe7e0fc03e-f003983a, #w-node-7fbe7e0fc03e-f8535eff, #w-node-7fbe7e0fc03e-fb5354be, #w-node-8984a112b767-a112b767 {
    -ms-grid-column-align: stretch;
    justify-self: stretch
}

#w-node-118b8f17c282-e7acebb4 {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: stretch;
    justify-self: stretch;
    -ms-grid-column-span: 1;
    grid-column-end: 2
}

#w-node-0757c2dfd087-1bacf594, #w-node-0757c2dfd088-1bacf594, #w-node-142e7dd67ef9-fb5354be, #w-node-142e7dd67efa-fb5354be {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-f58204dcaa31-50573173, #w-node-f58204dcaa31-8984ef48, #w-node-f58204dcaa31-ed699b1c {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1
}

#upcoming.w-node-8e049a8f033b-811bc103, #w-node-606643cb4983-50573173, #w-node-606643cb4983-8984ef48, #w-node-606643cb4983-ed699b1c {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2
}

#w-node-2e3b2c9ea1f9-811bc103, #w-node-f58204dcaa31-4aee28c3, #w-node-f58204dcaa31-cd538340 {
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row-span: 1
}

#w-node-2e3b2c9ea1f9-811bc103 {
    -ms-grid-row-align: start;
    align-self: start;
    -ms-grid-column: 1;
    grid-column-start: 1;
    grid-column-end: 2
}

#w-node-67ad7f953a90-811bc103, #w-node-69ff12617a6c-811bc103, #w-node-7a0cfa8af6ff-811bc103 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-1f5cbbb84497-811bc103 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1
}

#w-node-f58204dcaa31-4aee28c3, #w-node-f58204dcaa31-cd538340 {
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2
}

#w-node-606643cb4983-4aee28c3, #w-node-606643cb4983-cd538340 {
    -ms-grid-column-span: 1;
    grid-column-end: 3;
    -ms-grid-column: 2;
    grid-column-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    -ms-grid-row: 2;
    grid-row-start: 2
}

@media screen and (max-width: 991px) {
    #w-node-5002e109f399-3a368f7a, #w-node-67c82edb3b5b-5cb00601, #w-node-79262c435273-5cb00601, #w-node-811daf03c55b-3a368f7a, #w-node-c29b58ea2522-fd3ac0f6, #w-node-e1b44dadf91b-5cb00601, #w-node-e8bf02f74c29-3a368f7a {
        -ms-grid-column: span 3;
        grid-column-start: span 3;
        -ms-grid-column-span: 3;
        grid-column-end: span 3;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1
    }

    #w-node-2821e451c41e-3a368f7a, #w-node-2e93488f680f-abc16c15, #w-node-787161e3b336-5cb00601, #w-node-b0292e66ad53-5cb00601 {
        grid-column-start: span 2;
        grid-column-end: span 2
    }

    #w-node-022ff5003317-5cb00601, #w-node-022ff50034c1-5cb00601, #w-node-022ff50034ca-5cb00601, #w-node-030670633f78-5cb00601, #w-node-030670633f8b-5cb00601, #w-node-0724d80fc584-5cb00601, #w-node-28bf77f92301-abc16c15, #w-node-2a3bfed32aa9-3a368f7a, #w-node-440d459b6f5d-5cb00601, #w-node-440d459b6f7b-5cb00601, #w-node-74d4ee9bc855-5cb00601, #w-node-7ed04362d68a-08c11f78, #w-node-7ed04362d68a-10535eee, #w-node-7ed04362d68a-1bacf594, #w-node-7ed04362d68a-2da26772, #w-node-7ed04362d68a-34a268b4, #w-node-7ed04362d68a-59bd449c, #w-node-7ed04362d68a-60038a07, #w-node-7ed04362d68a-60a261cc, #w-node-7ed04362d68a-79bf5ddf, #w-node-7ed04362d68a-842bd412, #w-node-7ed04362d68a-a07321ac, #w-node-7ed04362d68a-e7acebb4, #w-node-7ed04362d68a-f003983a, #w-node-7ed04362d68a-f3df298d, #w-node-7ed04362d68a-f8535eff, #w-node-7ed04362d68a-fb5354be, #w-node-8c363baf3bf3-5cb00601, #w-node-ceba8dda10c3-5cb00601, #w-node-ceba8dda10ce-5cb00601, #w-node-ceba8dda10d9-5cb00601, #w-node-ceba8dda10e4-5cb00601, #w-node-ceba8dda10ef-5cb00601, #w-node-ceba8dda10fa-5cb00601, #w-node-ceba8dda1109-5cb00601, #w-node-ceba8dda1116-5cb00601, #w-node-ceba8dda1126-5cb00601, #w-node-ceba8dda1133-5cb00601, #w-node-ceba8dda1140-5cb00601, #w-node-de7d6124a1b8-5cb00601, #w-node-e175c90dc2f3-5cb00601, #w-node-e175c90dc323-5cb00601, #w-node-fdc75fbc908a-3a368f7a {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-6497ad234f20-3a368f7a {
        -ms-grid-row-align: center;
        align-self: center;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1
    }

    #w-node-3301fa2f2467-3a368f7a, #w-node-34feaf58949b-5cb00601, #w-node-4b47686b61b7-5cb00601, #w-node-db9cb5edfb86-5cb00601, #w-node-db9cb5edfb86-811bc103, #w-node-fadfde9a9ff9-5cb00601, #w-node-fadfde9a9ff9-811bc103 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1
    }

    #w-node-2821e451c41e-3a368f7a {
        -ms-grid-column-span: 2;
        -ms-grid-column: span 2;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -webkit-box-ordinal-group: 10000;
        -webkit-order: 9999;
        -ms-flex-order: 9999;
        order: 9999
    }

    #w-node-099da455cb35-3a368f7a {
        -ms-grid-row: 2;
        grid-row-start: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-column-span: 2;
        grid-column-end: 4
    }

    #w-node-c29b58ea2522-fd3ac0f6 {
        -ms-grid-row-align: stretch;
        align-self: stretch
    }

    #w-node-787161e3b336-5cb00601 {
        -ms-grid-row-align: start;
        align-self: start;
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        -ms-grid-column: span 2;
        -ms-grid-column-span: 2
    }

    #w-node-b0292e66ad53-5cb00601 {
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        -ms-grid-column-span: 2
    }

    #w-node-2e93488f680f-abc16c15 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        -ms-grid-column-span: 2
    }

    #w-node-58ffffeba0b0-08c11f78, #w-node-58ffffeba0b0-10535eee, #w-node-58ffffeba0b0-1bacf594, #w-node-58ffffeba0b0-2da26772, #w-node-58ffffeba0b0-34a268b4, #w-node-58ffffeba0b0-59bd449c, #w-node-58ffffeba0b0-60038a07, #w-node-58ffffeba0b0-60a261cc, #w-node-58ffffeba0b0-79bf5ddf, #w-node-58ffffeba0b0-842bd412, #w-node-58ffffeba0b0-a07321ac, #w-node-58ffffeba0b0-e7acebb4, #w-node-58ffffeba0b0-f003983a, #w-node-58ffffeba0b0-f3df298d, #w-node-58ffffeba0b0-f8535eff, #w-node-58ffffeba0b0-fb5354be {
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -webkit-box-ordinal-group: 10000;
        -webkit-order: 9999;
        -ms-flex-order: 9999;
        order: 9999
    }
}

@media screen and (max-width: 767px) {
    #w-node-0b2fe3b960fd-1417f64f, #w-node-787161e3b336-5cb00601, #w-node-90ea46874bf2-c4ee97b2, #w-node-90ea46874bf2-c530f919 {
        -ms-grid-row-align: start;
        align-self: start;
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2
    }

    #w-node-0b2fe3b960f9-1417f64f, #w-node-2e93488f680f-abc16c15, #w-node-6497ad234f20-3a368f7a, #w-node-74d4ee9bc855-5cb00601, #w-node-a22481013542-c4ee97b2, #w-node-a22481013542-c530f919, #w-node-c29b58ea2522-fd3ac0f6, #w-node-df78f6cea3e1-4710397d {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        grid-row-end: span 1
    }

    #w-node-0b2fe3b960fd-1417f64f, #w-node-2e3b2c9ea1f9-811bc103, #w-node-2e93488f680f-abc16c15, #w-node-43c2a3c1ad6e-4710397d, #w-node-6497ad234f20-3a368f7a, #w-node-76a1d7127ebf-c4ee97b2, #w-node-76a1d7127ebf-c530f919, #w-node-787161e3b336-5cb00601, #w-node-90ea46874bf2-c4ee97b2, #w-node-90ea46874bf2-c530f919, #w-node-ad52a90412e7-5cb00601, #w-node-b948989b036a-1417f64f, #w-node-c29b58ea2522-fd3ac0f6, #w-node-df78f6cea3e1-4710397d {
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-3ca5873ec5c1-1417f64f, #w-node-3ca5873ec5c3-1417f64f, #w-node-f58204dcaa31-8984ef48, #w-node-f58204dcaa31-ed699b1c {
        -ms-grid-row: 1;
        grid-row-start: 1;
        grid-row-end: 2
    }

    #upcoming.w-node-8e049a8f033b-811bc103, #w-node-3ca5873ec5c1-1417f64f, #w-node-606643cb4983-4aee28c3, #w-node-606643cb4983-50573173, #w-node-606643cb4983-8984ef48, #w-node-606643cb4983-cd538340, #w-node-606643cb4983-ed699b1c, #w-node-f58204dcaa31-4aee28c3, #w-node-f58204dcaa31-8984ef48, #w-node-f58204dcaa31-cd538340, #w-node-f58204dcaa31-ed699b1c {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1
    }

    #w-node-6497ad234f20-3a368f7a {
        -ms-grid-row-align: center;
        align-self: center;
        -ms-grid-row-span: 1
    }

    #w-node-0b2fe3b960fb-1417f64f, #w-node-0e94fbfee63b-5cb00601, #w-node-0e94fbfee63b-811bc103, #w-node-1567172d1258-c1d734e0, #w-node-1567172d1262-c1d734e0, #w-node-1567172d126c-c1d734e0, #w-node-28bf77f92301-abc16c15, #w-node-2f8bd1af9e6e-5cb00601, #w-node-2f8bd1af9e74-5cb00601, #w-node-3301fa2f2467-3a368f7a, #w-node-5a3f7a996731-3a368f7a, #w-node-673166251909-c1d734e0, #w-node-6895e020d517-c1d734e0, #w-node-768c3c86ddfa-3a368f7a, #w-node-8adb3d4e6484-5cb00601, #w-node-9b6f98f00b9b-3a368f7a, #w-node-a22481013544-c4ee97b2, #w-node-a22481013544-c530f919, #w-node-b948989b037e-1417f64f, #w-node-dc26b99847b3-c1d734e0, #w-node-dc26b99847bd-c1d734e0, #w-node-dc26b99847c7-c1d734e0, #w-node-e77021931b2c-c1d734e0, #w-node-efbbfd3ac0f9-fd3ac0f6, #w-node-f275df8e86ea-c1d734e0, #w-node-f744892c5120-c1d734e0, #w-node-fba00eaf0f9e-c4ee97b2, #w-node-fba00eaf0f9e-c530f919 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-2821e451c41e-3a368f7a {
        -ms-grid-row-span: 4;
        grid-row-end: 4
    }

    #w-node-efbbfd3ac0fd-fd3ac0f6, #w-node-efbbfd3ac10a-fd3ac0f6 {
        -ms-grid-column-align: center;
        justify-self: center
    }

    #w-node-c29b58ea2522-fd3ac0f6 {
        -ms-grid-row-align: stretch;
        align-self: stretch;
        -ms-grid-row-span: 1
    }

    #w-node-0b2fe3b960f9-1417f64f, #w-node-a22481013542-c4ee97b2, #w-node-a22481013542-c530f919 {
        -ms-grid-row-span: 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
        -ms-grid-row-align: end;
        align-self: end
    }

    #w-node-0b2fe3b960fd-1417f64f, #w-node-90ea46874bf2-c4ee97b2, #w-node-90ea46874bf2-c530f919 {
        -ms-grid-column-align: end;
        justify-self: end
    }

    #w-node-2e3b2c9ea1f9-811bc103, #w-node-43c2a3c1ad6e-4710397d, #w-node-76a1d7127ebf-c4ee97b2, #w-node-76a1d7127ebf-c530f919, #w-node-ad52a90412e7-5cb00601, #w-node-b948989b036a-1417f64f {
        -ms-grid-row-align: start;
        align-self: start;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1
    }

    #w-node-707b17e1ba72-9bc30d38 {
        -webkit-box-ordinal-group: -9998;
        -webkit-order: -9999;
        -ms-flex-order: -9999;
        order: -9999
    }

    #w-node-df78f6cea3e1-4710397d {
        -ms-grid-row-span: 1
    }

    #w-node-3ca5873ec5c3-1417f64f {
        -ms-grid-column-span: 1;
        grid-column-end: 3;
        -ms-grid-column: 2;
        grid-column-start: 2;
        -ms-grid-row-span: 1
    }

    #w-node-74d4ee9bc855-5cb00601 {
        -ms-grid-column: span 4;
        grid-column-start: span 4;
        -ms-grid-column-span: 4;
        grid-column-end: span 4;
        -ms-grid-row-span: 1
    }

    #w-node-886332e35b1e-5cb00601 {
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -webkit-align-self: stretch;
        -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch
    }

    #w-node-886332e35b28-5cb00601 {
        -ms-grid-column-align: auto;
        justify-self: auto
    }

    #w-node-2e93488f680f-abc16c15 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        -ms-grid-row-span: 1
    }

    #w-node-606643cb4983-50573173, #w-node-606643cb4983-8984ef48, #w-node-606643cb4983-ed699b1c {
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }

    #upcoming.w-node-8e049a8f033b-811bc103 {
        grid-row-end: 4;
        -ms-grid-row: 3;
        grid-row-start: 3
    }

    #w-node-f58204dcaa31-4aee28c3, #w-node-f58204dcaa31-cd538340 {
        -ms-grid-row: 1;
        grid-row-start: 1;
        grid-row-end: 2
    }

    #w-node-606643cb4983-4aee28c3, #w-node-606643cb4983-cd538340 {
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
}

@media screen and (max-width: 479px) {
    #w-node-2e3b2c9ea1f9-811bc103, #w-node-494e2d4310f2-3a368f7a, #w-node-76a1d7127ebf-c4ee97b2, #w-node-787161e3b336-5cb00601, #w-node-f9a4d6293485-3a368f7a {
        -ms-grid-row-align: start;
        align-self: start
    }

    #w-node-f9a4d6293485-3a368f7a {
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-column-align: end;
        justify-self: end
    }

    #Email.w-node-2e93488f690e-abc16c15, #Name.w-node-2e93488f690c-abc16c15, #Project-Location.w-node-e5a36ccbe1d9-abc16c15, #Project-Name.w-node-bfc113c28302-abc16c15, #w-node-184d7230ab50-abc16c15, #w-node-1b33c17f22c8-3a368f7a, #w-node-35e2ed03adf0-3a368f7a, #w-node-8b0de2fd0c8d-abc16c15, #w-node-b948989b037e-1417f64f, #w-node-b9f38cfff70c-abc16c15, #w-node-ceba8dda10a6-5cb00601, #w-node-ceba8dda10b3-5cb00601, #w-node-f9a4d6293487-3a368f7a, #w-node-fba00eaf0f9e-c530f919 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-2366d69a9dfd-5cb00601, #w-node-2f8bd1af9e6e-5cb00601, #w-node-2f8bd1af9e74-5cb00601, #w-node-74d4ee9bc855-5cb00601, #w-node-787161e3b336-5cb00601 {
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        grid-column-end: span 1;
        -ms-grid-column-span: 1
    }

   /* #w-node-8226eb520370-3a368f7a {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row-align: end;
        align-self: end
    }

    #w-node-e630e9f9e3b4-3a368f7a {
        -ms-grid-row: span 2;
        grid-row-start: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }*/

    #w-node-494e2d4310f2-3a368f7a {
        -ms-grid-column-align: start;
        justify-self: start;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-4290867e8583-5cb00601, #w-node-5002e109f399-3a368f7a, #w-node-531dfa115bc1-5cb00601, #w-node-7a56ba054eca-3a368f7a, #w-node-811daf03c55b-3a368f7a, #w-node-d9c24377ba48-5cb00601, #w-node-e4f9b6f8a61b-5cb00601, #w-node-e8bf02f74c29-3a368f7a {
        -ms-grid-row: span 1;
        /* grid-row-start: span 1; */
        -ms-grid-row-span: 1;
        /* grid-row-end: span 1; */
        -ms-grid-column: span 1;
        /* grid-column-start: span 1; */
        -ms-grid-column-span: 1;
        grid-column-end: span 1;
    }

    #w-node-907d6837e851-3a368f7a {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-column-align: start;
        justify-self: start
    }

    #w-node-3a996ff11d6b-3a368f7a {
        -ms-grid-row-align: center;
        align-self: center;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-099da455cb35-3a368f7a {
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1
    }

    #w-node-a6cd4b998d80-fd3ac0f6, #w-node-efbbfd3ac0fd-fd3ac0f6, #w-node-efbbfd3ac10a-fd3ac0f6 {
        -ms-grid-column-align: center;
        justify-self: center
    }

    #w-node-43c2a3c1ad6e-4710397d, #w-node-76a1d7127ebf-c530f919, #w-node-ad52a90412e7-5cb00601, #w-node-b948989b036a-1417f64f {
        -ms-grid-row-align: start;
        align-self: start;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-column-align: stretch;
        justify-self: stretch
    }

    #w-node-0d0e9b1ee9de-c530f919, #w-node-2e93488f68e2-abc16c15, #w-node-48afe834b159-c530f919, #w-node-57ff6d411c59-c530f919, #w-node-6b1120e5a394-c530f919, #w-node-72dadc2e93e2-c530f919, #w-node-82fc913cd405-c530f919, #w-node-87a22b233a41-c530f919, #w-node-8a7574ba16b4-c530f919, #w-node-8c6ead2857d1-c530f919, #w-node-8cd9860e2412-c530f919, #w-node-a4bcdc45b432-c530f919, #w-node-bd92ac3b2be2-c530f919, #w-node-daa17aab5b91-c530f919, #w-node-e3c9653ffcea-c530f919 {
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-0d0e9b1ee9e0-c530f919, #w-node-48afe834b15b-c530f919, #w-node-57ff6d411c5b-c530f919, #w-node-6b1120e5a396-c530f919, #w-node-72dadc2e93e4-c530f919, #w-node-82fc913cd407-c530f919, #w-node-87a22b233a43-c530f919, #w-node-8a7574ba16b6-c530f919, #w-node-8c6ead2857d3-c530f919, #w-node-8cd9860e2414-c530f919, #w-node-a4bcdc45b434-c530f919, #w-node-bd92ac3b2be4-c530f919, #w-node-daa17aab5b93-c530f919, #w-node-e3c9653ffcec-c530f919 {
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -ms-grid-row-align: auto;
        align-self: auto;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-3ca5873ec5c1-1417f64f, #w-node-3ca5873ec5c3-1417f64f, #w-node-74d4ee9bc855-5cb00601 {
        justify-self: stretch;
        -ms-grid-column-align: stretch
    }

    #w-node-787161e3b336-5cb00601 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1
    }

    #w-node-74d4ee9bc855-5cb00601 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1
    }

    #w-node-0456a383d3e5-abc16c15, #w-node-1119ec05be74-abc16c15, #w-node-215bf1a77890-5cb00601, #w-node-2e93488f68e0-abc16c15, #w-node-a7d8b16d34cf-5cb00601, #w-node-c499228ed8de-5cb00601 {
        -ms-grid-row-align: center;
        align-self: center;
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-0a4a96f9aabe-59bd449c, #w-node-118b8f17c282-e7acebb4, #w-node-392ae874d29d-60a261cc, #w-node-61553412c5a0-60038a07, #w-node-718b3a833679-842bd412, #w-node-7fbe7e0fc03c-08c11f78, #w-node-7fbe7e0fc03c-10535eee, #w-node-7fbe7e0fc03c-1bacf594, #w-node-7fbe7e0fc03c-2da26772, #w-node-7fbe7e0fc03c-34a268b4, #w-node-7fbe7e0fc03c-59bd449c, #w-node-7fbe7e0fc03c-60038a07, #w-node-7fbe7e0fc03c-60a261cc, #w-node-7fbe7e0fc03c-79bf5ddf, #w-node-7fbe7e0fc03c-842bd412, #w-node-7fbe7e0fc03c-a07321ac, #w-node-7fbe7e0fc03c-e7acebb4, #w-node-7fbe7e0fc03c-f003983a, #w-node-7fbe7e0fc03c-f3df298d, #w-node-7fbe7e0fc03c-f8535eff, #w-node-9b43028a7466-08c11f78, #w-node-d9363c4ad047-f3df298d, #w-node-ec22c0563ccf-a07321ac {
        -ms-grid-row-align: center;
        align-self: center;
        -ms-grid-column-align: stretch;
        justify-self: stretch;
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-2e3b2c9ea1f9-811bc103, #w-node-76a1d7127ebf-c4ee97b2, #w-node-7fbe7e0fc03e-08c11f78, #w-node-7fbe7e0fc03e-10535eee, #w-node-7fbe7e0fc03e-1bacf594, #w-node-7fbe7e0fc03e-2da26772, #w-node-7fbe7e0fc03e-34a268b4, #w-node-7fbe7e0fc03e-59bd449c, #w-node-7fbe7e0fc03e-60038a07, #w-node-7fbe7e0fc03e-60a261cc, #w-node-7fbe7e0fc03e-79bf5ddf, #w-node-7fbe7e0fc03e-842bd412, #w-node-7fbe7e0fc03e-a07321ac, #w-node-7fbe7e0fc03e-e7acebb4, #w-node-7fbe7e0fc03e-f003983a, #w-node-7fbe7e0fc03e-f8535eff, #w-node-7fbe7e0fc03e-fb5354be, #w-node-8984a112b767-a112b767 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        justify-self: stretch;
        -ms-grid-column-align: stretch;
        -ms-grid-row-span: 1
    }

    #w-node-0247ed717407-1bacf594, #w-node-110dfdac84f9-60038a07, #w-node-19ce87df7b54-f8535eff, #w-node-1a7492c56f1a-e7acebb4, #w-node-2e02325603da-1bacf594, #w-node-2e3d1663e84b-08c11f78, #w-node-45562ae24cac-60a261cc, #w-node-489362a988a1-842bd412, #w-node-494dfaa22135-08c11f78, #w-node-4e3ae8b3d585-10535eee, #w-node-5994e397a0b1-842bd412, #w-node-5a1ad1accd76-79bf5ddf, #w-node-6ab14a5175d0-60a261cc, #w-node-6d2d9385a930-fb5354be, #w-node-7a7dcc26a6d8-a07321ac, #w-node-7a8d4aaaf8eb-f3df298d, #w-node-7d9286cee308-2da26772, #w-node-a7ced82f2c26-79bf5ddf, #w-node-aa81fe68afcc-08c11f78, #w-node-b95386915711-fb5354be, #w-node-b9bdcddee638-f8535eff, #w-node-c8c4ab7a5a2c-fb5354be, #w-node-d802af87ea7d-60038a07, #w-node-dc8ace0e456f-1bacf594, #w-node-e3ae4511130b-59bd449c, #w-node-e8023d6ae8fb-f3df298d, #w-node-ef0f850f442b-e7acebb4, #w-node-f1e1e58e8a1d-842bd412, #w-node-fba00eaf0f9e-c4ee97b2 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 2;
        grid-column-start: span 2;
        -ms-grid-column-span: 2;
        grid-column-end: span 2
    }

    #w-node-03d8b9e8b5ae-c4ee97b2, #w-node-03d8b9e8b5b0-c4ee97b2, #w-node-c9f22efbfd39-c4ee97b2, #w-node-c9f22efbfd3b-c4ee97b2, #w-node-de32b88f9e8e-b88f9e8d, #w-node-de32b88f9e90-b88f9e8d {
        -ms-grid-column-align: stretch;
        justify-self: stretch
    }

    #w-node-606643cb4983-50573173, #w-node-606643cb4983-8984ef48, #w-node-606643cb4983-ed699b1c {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }

    #w-node-f58204dcaa31-8984ef48, #w-node-f58204dcaa31-ed699b1c {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 2
    }

    #upcoming.w-node-8e049a8f033b-811bc103, #webinar.w-node-b3f36d05ead9-811bc103 {
        -ms-grid-column-span: 2;
        grid-column-end: 3;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1
    }

    #webinar.w-node-b3f36d05ead9-811bc103 {
        -ms-grid-row: 2;
        grid-row-start: 2;
        grid-row-end: 3
    }

    #upcoming.w-node-8e049a8f033b-811bc103 {
        grid-row-end: 4;
        -ms-grid-row: 3;
        grid-row-start: 3
    }

    #w-node-f58204dcaa31-4aee28c3, #w-node-f58204dcaa31-cd538340 {
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row: 1;
        grid-row-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 2
    }

    #w-node-606643cb4983-4aee28c3, #w-node-606643cb4983-cd538340 {
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-column: 1;
        grid-column-start: 1;
        -ms-grid-row-span: 1;
        grid-row-end: 3;
        -ms-grid-row: 2;
        grid-row-start: 2
    }
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-Basic.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Flama';
    src: url('../fonts/Flama-BasicItalic.woff') format('woff');
    font-weight: 400;
    font-style: italic;
}
ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border:2px solid #0038e3;
    left: 18px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.strong{
    font-weight: 600;
}

.fsSubmitButton{
    padding: 10px 10px;
    border: 0px solid transparent;
    -moz-border-radius: 3px !important;
    -webkit-border-radius: 3px;
    border-radius: 3px !important;
    font-size: 15px !important;
    font-weight: 400;
    font-style: normal;
    line-height: 15px;
    color: #ffffff !important;
    background-color: #0070ba !important;
}
.fsField{
    border: 1px solid #cfd4d8;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    -moz-transition-property: color, background-color, border, border-color, box-shadow;
    -o-transition-property: color, background-color, border, border-color, box-shadow;
    -webkit-transition-property: color, background-color, border, border-color, box-shadow;
    transition-property: color, background-color, border, border-color, box-shadow;
    -moz-transition-duration: 0s;
    -o-transition-duration: 0s;
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -moz-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
    padding: 6px;
    font-size: 12px;
    font-weight: 300;
    font-style: normal;
    line-height: 12px;
    flex: 1 1 auto;
    width: 100%;
    color: #595d64;
}
.fsSpan100{
    width: 100%;
}
.fsLabelVertical .fsLabel {
    margin-bottom: 10px;
    margin-top: 10px;
}


.fsBody .fsLabel, .fsBody label {
    font-size: 1rem;
    color: #595d64;
}
.btn-block {
    display: block;
    width: 100%;
}
.fsRequiredMarker{
    margin-left: 2.5px !important;
    font-size: 18px;
    color: #0070ba;
}
.fsLabel{
    font-size: 18px;
    color: #595d64;
}
.presentation{width: 50%;}
.form-control{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: calc(2.25rem + 2px);
}
.fsRowBody{
    margin-bottom: 15px;
}
.black_theme{
    background: #000000;
    color: #ffffff;
    border: none;
}
.black_theme h4{
    color: #ffffff;
}
.text-light{
    color:#ffffff;
}
.text-center{
    text-align: center;
}
.radio-inline{
    margin-right: 100px;
    margin-left: 10px;
}
.form-group{
    margin-bottom: 15px;
}
.alert{
    padding: 25px;
    background: #f3f3f3;
    border-bottom: 2px solid #429999;
}
.help-block{
    color: #F44336;
}
.alert-danger{
    border-bottom: 2px solid #F44336;
    background: #ff1100;
    color: #ffffff;
    font-size: 22px;
}
.partner{
    max-height: 100px;
}
.textarea-form {
    resize: none;
    height: 200px;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    background-color: #f9f9f9;
    min-width: 260px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 9999;
}

.dropdown:hover .dropdown-content {
    display: block;
}
.mobHeader {
    display: none;
}
@media screen and (max-width: 991px){
    .dropdown-content {
        position: relative;
    }
    .mysticky , .secondNav , .desktopHeader{
        display: none;
    }
    .mobHeader {
        display: block;
    }
    .mobHeaderSticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9999999;
    }
    .d_navrightside {
        .profileIcon {
            width: 35px;
        }        
    }

}

@media screen and (min-width: 992px){
    .dropdown-content {
        position: absolute;
    }
}
.img_navlogo{
    height: 55px;
}
.primary{
    color: #0038e3;
}
.flag{
    width:25px;
    max-width: 25px;
}
.flag:hover{
    cursor: pointer;
}
.w-slider-dot {
    flex-grow: 1;
    height: 1px;
    background-color: #D3DCE5;
}

.w-slider-dot.w-active {
    background-color: #3B5266;
}

.w_healthy_arch_rotated_text {
    writing-mode: vertical-lr;
}

.d_rotated_word_home_hero,
.d_sideways_text_wrapper {
    writing-mode: vertical-lr;
}

.h2_alternating_card {
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

@media only screen and (max-width: 600px) {
    .d_rotated_word_home_hero,
    .d_sideways_text_wrapper {
        writing-mode: horizontal-tb;
    }
}

.a_stickynavlink.w--current:before {
    width: 100px;
}

.a_stickynavlink:before {
    content: "";
    background-color: #0038e3;
    width: 0px;
    height: 2px;
    position: absolute;
    left: -110px;
    top: 8px;
    transform-origin: 100% 50%;
    transition: width 1s ease;
}
@media only screen and (max-width: 992px) {
    .tl_nav_link.w--current:before {
        width: 100px;
    }

    /*.tl_nav_link:before {
        content: "";
        background-color: #26bbcd;
        width: 0px;
        height: 2px;
        position: absolute;
        left: -110px;
        top: 50%;
        transform: translateY (-50%);
        transform-origin: 100% 50%;
        transition: width 1s ease;
    }*/
}
.invalid-feedback{
    display: block; 
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #e55353;
}
.PhoneInputInput{
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: calc(2.25rem + 2px);
    
}
.ck-editor__editable {min-height: 200px;}
.c-sidebar-nav-dropdown-toggle:hover, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover,.c-header-nav-link:hover{
    border: none;
}


.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
.price{
    font-weight: 700;
}
.card-course{
    height: 100%;
    background: #fafafa;
}
.card-footer {
    padding: 0.5rem 1rem;
    color: var(--cui-card-cap-color, unset);
     background-color: none; 
     border-top: none;
     position: relative;
}
.badge-basket{
    position: absolute;
    top: 50%;
    margin-top: -4px;
    padding: 3px;
    margin-left: -2px;
    background: #00000087;
    color: white;
    border-radius: 50%;
    font-size: 11px;
    font-weight: 400;
}
.heading{
    position: relative;
    display: block;
    margin-bottom: 80px;
}
.heading::after{
    display: block;
    content: "";
    width: 3rem;
    background-color: #26bbcd;
    height: .3rem;
    position: absolute;
    bottom: -1.4rem;
    left: calc(50% - 1.5rem);
}
.basket-item{
    /* border-top: 1px solid #0038e3; */
    /* border-bottom: 1px solid #0038e3; */
    padding: 0.5em 1em;
    line-height: 40px;
    /* margin-top: 60px;*/
}
.basket-item .row .coll h3, h4, h6 {
    margin-bottom: 0px !important;
}


.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size:calc(1.275rem + .3vw);
    font-weight: 700;
}
[data-kd-id="036"] .basket-view .products .row:first-child .close {
    top: 1.5rem
}
[data-kd-id="036"] .basket-view .products .close {
    position: absolute;
    top: 1rem;
    right: 1rem
}
.searchWrapper {
    position:relative;
  }
  
  .searchWrapper .searchIcon {
    height: 1.3rem;
    width: 1.3rem;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 36%;
    left: 7px;
    transform: translateY(-50%);
  }
  
  .searchWrapper .input {
    height: 35px;
    border: none;
    background-color: #f6f6f6;
    box-shadow: 0px 11px 11px 0px rgb(0 0 0 / 10%);
    padding-left: 2rem;
    border-radius: 3px;
    width: 195px;
  }
.importantLink{
    font-weight: 600;
    font-size: 18px;
}
.secondNav{
    margin-top: 5px;
    float: none;
}
.secondNav a{
    border-right: solid 1px black;
    padding-right: 15px;
    font-weight: 400;
}
.secondNav .w_navleftsidelinks a:last-child{
border-right: none;
}
.homeFirstSection{
    padding-top: 2rem;
}
.homeFirstSection h1{
    font-size: 4rem;
}
.registerLink a{
    background-color: #26bbcd;
    padding: 6px 30px;
    font-size: 18px;
    color: white;
    border-radius: 3px;
    box-shadow: 0px 13px 11px 0px rgb(0 0 0 / 10%);
    width: 190px;
    display: inline-block;
    text-align: center;
}
.registerLink {
    margin-top: 15px;
}
.firstCol {
    width: 50% !important;
    display: inline-block !important;
}
.secondCol {
    width: 30%;
    display: inline-block;
    vertical-align: middle;
}
.thirdCol {
    width: 20%;
    display: inline-block;
    vertical-align: middle;
}
.sectionTitle  p {
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    margin-top: 30px;
    margin-bottom: 30px;
}
.itemCard {
    border: 1px solid #d8dbe0a4;
    border-radius: 5px;
    box-shadow: 0px 0px 13px -4px rgba(0,0,0,.3);
    margin: 10px 0;
    min-height: 200px;
    background-color: white;
}
.itemCard .sessionRate img {
    width: 15px;
}
.cardBottomRow {
    display: inline-flex;
}
.cardBottomRow div {
    margin-right: 8px;
    border-right: solid 1px black;
    padding-right: 8px;
}
.cardBottomRow div:last-child {
    margin-right: 0px;
    border-right: none;
    padding-right: 0px;
}
.itemCardLink:hover{
    text-decoration: none; 
    color: #222 !important;
 }
 .courseDateCard {
    padding-bottom: 9px;
    padding-top: 11px;
}
 .pb-4{
    padding: 1rem !important;
    padding-top: 1rem !important;
 }
 .cardsWraper {
    padding-right: 20px;
    padding-left: 20px;
}
.row.itemsRow {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 25px;
    padding-left: 25px;
}
.itemCardTitle {
    text-transform: capitalize;
}
.formateursSection .sectionTitle{
    margin-bottom: 50px;
    margin-top: 50px;
}
.cardField  {
    text-transform: capitalize;
    font-size: 1rem;
}
.formateurCard .itemCardTitle , .cardField{
    text-align: center;
    margin-bottom: 5px !important;
}
.formateurCard .cardBottomRow div:last-child {
    text-align: right;
}
.formateurCard .cardBottomRow {
    width: 100%;
    padding-top: 30px;
}
.formateurCard .cardBottomRow div {
display: inline-block;
width: 50%;
margin-right: 0px;
}
.formateursRow{
    position: relative;
}
.formateursRow::before {
    content: '';
    background-color: #c4dcdb;
    width: 100%;
    height: 88%;
    position: absolute;
    margin-top: -21px;
    z-index: -1;
}
.partnersSection {
    margin-top: 4rem;
    margin-bottom: 4rem;
}
.partnersSection img {
    padding: 0.5rem 2rem;
}
.partnersSection a {
    line-height: 130px;
}
.newsletterFrame{
    text-align: center;

}
.newsletterInner {
    background-color: #0e756c;
    width: fit-content;
    margin: auto;
    padding: 30px 50px;
    border-radius: 5px;
    color: white;
}
.newsletterRight , .newsletterLeft {
    display: inline-block;
}
.newsletterRight {
    margin-right: 10rem;
    letter-spacing: 1px;
}
.newsletterRight input{
    height: 30px;
    border-radius: 5px 0px 0px 5px;
    width: 70%;
    border: none;
}
.newsletterRight button {
    width: 30%;
    height: 30px;
    border-radius: 0px 5px 5px 0px;
    background-color: #f6936e;
    font-size: 16px;
}
.f_mainfooter ul {
    list-style: none;
    padding-left: 0px;
}
.f_mainfooter {
padding-top: 50px;
}
.newsletterSection{
    position: relative;
}
.newsletterSection::before {
    content: '';
    background-color: #c4dcdb;
    width: 100%;
    height: 70px;
    position: absolute;
    z-index: -1;  
    bottom: 0px;
}
.newsletterLeft img {
    width: 150px;
}
.footerRow .col-md-4{text-align: center;}
.footerRow h4 {    font-size: 2em;
    font-weight: 500;}
    .footerRow ul li {
        padding-bottom: 10px;
        font-size: 1.2em;
        font-weight: 400;
    }
    /* .bottomRow {
        bottom: 10px;
        position: absolute;
    } */
    .upIcon {
        width: fit-content;
    background-color: black;
    border-radius: 40px;
    padding: 10px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 99;
    }
    .upIcon svg {
        fill: white;
        font-weight: bold;
        font-size: 47px;
        width: 35px;
    }
    .slide-container button {
display: none !important;
    }
.presentation_page {
    padding: 75px 50px;
}
.fullBanner2{
    margin-bottom: 3rem;
}
.coursesPageWrapper{
    margin-top: 2.5% !important;
}
/* .medicalLogin form div {
    width: 31%;
    margin-right: 3.5%;
    display:inline-block;
}
.medicalLogin form .fsSubmit {
    margin-right: 0%;
} */
.medicalNavButton {
    margin: auto;
    margin-bottom: 10px;
    width: max-content;
    padding: 5px 15px;
    color: white !important;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.medicalImagesWrapper{
    display: flex;
    width:100%
}
.medicalImagesWrapper div{
    padding: 5%;
}
.medical_3banners{
    background-image: none !important;
}
.img_heroframe .cc-f5 .medical_3banners{
    margin-top: 20px !important;
}
.medical_training_3banners {
    display: flex;
 justify-content: space-between;   
}
.medical_training_3banners div {
    padding-right: 30px;
}
.medical_training_3banners div:last-child {
    padding-right: 0px;
}
.medical_training_3banners1{
    margin-top: 160px;
}
.medical_training_3banners2{
    margin-top: 80px;
}
.medical_training_3banners1 img {
    width: 517px;
}
.medical_training_3banners2 img{
width: 285px;   
}
.medical_training_3banners3 img {
    width: 270px;
}
.presentation_page_medicalNarrow {
    width: 60%;
    display: block;
    margin: auto;
}
.courseCardTraining h3 {
    font-size: 1.3rem;
    margin-bottom: 5px;
}
.courseCardTraining label{
font-size: 13px;
}
.courseCardTraining .row {
    font-size: 12px;
    margin-bottom: 12px;
}
.courseCardTraining .text-center {
    margin-bottom: 0px;
}
video {
    aspect-ratio: 1.78/1;
}

.readMoreButton { 
    border-radius: 10px;
    display: inline-block;
    width: fit-content;
    margin-left: 15px;
    padding: 8px 12px;
}
    .sharerSection {
        opacity: 0;
        transform: translateY(-20px);
        transition: opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease-in-out;
        max-height: 0;
        overflow: hidden;
        height: 75px;
        border-radius: 5px;
        padding: 0px 12px;
        position: absolute;
        right: 28px;
        z-index: 99;
        bottom: 1px;
        color: white;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .sharerSection.show {
    opacity: 1;
  transform: translateY(0);
  max-height: 200px; /* Adjust this value to fit your content */
  }
  .sharerSection2.show { width: 70%;}
  
  .sharerSection.hide {
    opacity: 0;
    transform: translateY(-20px);
    max-height: 0;
  }
  .sharerSection .lb_socialicon {
    filter: none;
  }
  .sharerSection .wtsap {
    filter: invert(1);
  }
    .sectionTitleCoursePage{
        margin-bottom: 15px;
        display: block;
    }
    .courseCardTraining {
        position: relative;
        padding: 1rem 0.5rem;
    }
   .courseCardTraining .col-md-4 {
        padding: 4px;
    }
    .courseDetails p {
        margin-bottom: 10px;
    }
    .courseDetails h6 {
        margin-bottom: 25px;
    }
    .cartSectionCoursePage li {
        text-align: left;
        margin-bottom: 2px;
    }
    .cartSectionCoursePage li:last-child {
      margin-top: -1px;
    }
    .coursePageCartfooter div {
        display: inline-block;
    }
    .coursePageCartfooter1 {
        float: left;
    }
    .coursePageCartfooter{margin-top: 25px;}
    .cartSectionCoursePage .btn-block{
margin-bottom: 10px;
border-radius: 5px;
font-size: 18px;
    }
.courseDetails h6 {
    font-size: 14px;
}
.sharingButton {
    display: inline-block;
    vertical-align: super;
    margin-left: 20px;
}
.closeShare {
    cursor: pointer;
    text-align: right;
    margin-right: -4px;
    margin-bottom: 10px;
    font-size: 16px;
}
.bg-light {
    background: none !important;
}
.mx-4 {
    border: none;
}
.mx-4 form label{
    display: inline-block !important;
}
.mx-4 form .col-md-12 input, .mx-4 form .col-md-12 select {
    display: inline-block !important;
    width: 70% !important;
    float: right !important;
    margin-bottom: 30px !important;
    border-radius: 15px !important;
}
.ck.ck-voice-label {
    display: none !important;
}
.container-lg{
    padding-left: 30px;
    padding-right: 30px;
}
.d-flex .mb-4 .header-divider , .d-flex .mb-4 div:last-child {
    display: none !important;
}

.sidebar-nav .nav-group.show , .sidebar, .d-flex .header,.flex-grow-1 button{
    background: #3bbbca !important;
}
.flex-grow-1 button:hover{
    background: #31a1ae  !important;
}
.sidebar-brand , .sidebar-toggler{
    background: none !important;
}
.container-fluid ul li a,.flex-grow-1 button {
color: white !important;
}
.card-body form h2:first-child {   
     /* background: #3aa2af;
    width: fit-content;
    padding: 5px 15px;
    color: white;
    border-radius: 15px; */
    display: block;
    margin: auto;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.5rem;
}
.removeIcon{
    position: absolute;
    top: -5px;
    right: -3px;
    background: #f51616;
    border-radius: 7px;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 12px;
    padding: 0px 5px 2px;
    color: white;
    font-weight: 900;
    cursor: pointer;
}
.updateSessionSection{
    border: solid 1px #ced4da;
    padding: 15px;
    border-radius: 15px;
}
.updateSessionSection label{
    display: inline-block !important;
    font-size: 16px;
    font-weight: 500;
    vertical-align: sub;
}
.sessionsList p{    font-size: 17px;
    font-weight: 500;
    background: #3bbbca;
    color: white;
    width: 92%;
    padding: 8px;
    border-radius: 15px;
    margin-bottom: 0px;
    text-align: center;
}

.sessionmoduleselect select{
    text-align: center;
    background: #3bbbca;
    color: white;
    font-weight: 600;
    appearance: auto;
    width: 92%;
    border-radius: 15px;
}
 .checkIcon path{fill:green}
 .checkIcon{
    width: 1.6rem !important;
    height: 1.6rem !important;
    vertical-align: text-bottom;
}

.loading-bar-fill{    
  /*  height: -webkit-fill-available;*/
    height: 100%;
    background: #3aa2af;
    border-radius: 4px;
    color: white;
    text-align: center;
}
.loading-bar{
    border: solid 1px #d6d6d6;
    border-radius: 4px;
    height: 18px;
}
.profileimg{
border-radius: 50%;
padding: 15px;
}
.trainerHeadInfo {
    text-align: left;
    padding: 15px;
    padding-left: 45px;
    text-transform: capitalize;
}
.trainerPageWrapper{
    width: 75%;
    margin: auto;
    margin-top: 60px;
}
.addToCartInAccordion{
    border-radius: 5px;
    width: 24%;
    text-align: center;
    font-size: 17px;
    letter-spacing: 1px;
    display: inline-block;
}
.accordionTitleCart{

    display: inline-block;
    font-size: 20px;
    color: black; 
    text-transform: capitalize;
}
.accordionTitleCart::after {
    margin-left: 15px !important;
    position: absolute;
}
.seperatorLine{
    height: 1px;
    background: #ced4da;
    width: 100%;
    display: block;
}
.sectionTitleCoursePage2 {
    margin-bottom: 5px;
    margin-top: 5px;
}
.startCourseLink{
    color: white;
    background: #26bbcd;
    width: auto;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 23px;
    margin: auto;
}
.takeCourseWrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    background: #26bbcd1a;
}
.contentCurrentModule {
    width: -webkit-fill-available;
    width: 100%;
    position: relative;
}
.contentSideBar{
    width: 25%;
    background: #26bbcd26;
}
.contentSideBar ul {
    list-style: none;
    padding-left: 30px;
    margin-top: 10px;
}
.sessionSectionHeading {
    background: #26bbcd42;
    font-size: 1.3em;
    font-weight: 400;
    line-height: initial;
    padding: 8px 15px;
}
.sessionSection label{
    font-size: 1em;
    font-weight: 400;
}
.courseTitle {
    font-size: 1.5em;
    font-weight: 500; 
    line-height: initial;
    padding:15px;
}
.contentVideo video {
    border-radius: 5px;
    margin: auto;
    display: block;
    width: 73%;

}
.takeCourseWrapper svg , .takeCourseWrapper img {
    width: 18px;
    margin-right: 8px;
    vertical-align: bottom;
}
.contentVideoHeading {
    width: 95%;
    margin: auto;
    display: block;

}
.contentVideoHeading h3 {
    margin-bottom: 3px;
}
.contentVideoHeading h5 p {
    margin-bottom: 10px;
}

.courseSlides{
    padding-top: 0px;
    height: 350px;

}
span.carousel-control-prev-icon , span.carousel-control-next-icon     {
    filter: invert(1);
}
.carousel-indicators{
    margin-bottom: 0.2rem;
}
.carousel-indicators [data-coreui-target] {
    background-color: black;
}
.courseSlides img{
border-radius: 5px;
max-height: 330px;
    object-fit: contain;
}
.courseDetailsCol6 {
        text-align: left;
        border-radius: 5px;
        padding-top: 10px;
        border-bottom:1px solid var(--cui-card-border-color, rgba(0, 0, 21, 0.125));
    }
.courseDetailsCol6 svg , .courseDetailsCol6 img{
        width: 19px;
        margin-right: 8px;
        vertical-align: sub;
    }
.cartSectionCoursePage ul img , .cartSectionCoursePage ul svg {
    width: 19px;
    margin-right: 6px;
}
.cartSectionCoursePage ul{
    list-style: none;
    padding-left: 16px;
}
.startCourseLink:hover{
    background: #23a1b0;
    color: white;
}
.carousel-control-prev, .carousel-control-next {
    width: 35px;
    padding: 0px 5px;
}
.courseSlides button:hover {
    background-color: #26bbcd !important;
}
.profileIcon {
    width: 45px;
    margin-right: 10px;
}
.removeIconCart svg{
    fill: white;
}
.removeIconCart:hover svg{
    fill: black;
}
.checkoutButton {
    border-radius:5px;
    font-size:22px;
    line-height:25px;
    width: fit-content;
    padding-left: 40px;
    padding-right: 40px;
    float: right;
}

button.accordion-button.accordionTitleCart {
    background: none;
}
.accordion-button:focus  {
    border-color:transparent !important ;
    background-color: #26bbcd26 !important;
    box-shadow: none;
}
.currentDemoBadge {
    width: fit-content;
    background: #fafafa;
    padding: 12px;
    margin-top: 10px;
    border-radius: 4px;
    position: relative;
}
.currentDemoBadge a {
    display: block;
}
/* src/Slider.css */


.sliderX {
    position: relative;
    overflow: hidden;
  }
  
  .slider-containerX {
    display: flex;
    transition: transform 1s ease-in-out;
  }
  
  .slider-imageX {
    min-width: 100%;
    height: 100%;
  }
  

/* src/CourseStructure.css */

.course-structure , .dashboardPage .card-body {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.session {
    background: #fdfdfdbf;
    border: solid 1px #cccccc;
    margin-bottom: 25px;
    padding: 5px 15px;
    border-radius: 8px;
}
.course-title, .session-header {
  display: flex;
  align-items: center;
  margin-bottom: 23px;
}

label {
  margin-right: 10px;
  font-weight: bold;
}

input[type="text"], textarea {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 8px 12px;
  margin-left: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.modules, .sessions {
  margin-bottom: 20px;
}

.module {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 20px;
}

.module input[type="text"] {
  flex: 1;
  margin-right: 10px;
}

.submit-button {
  display: block;
  margin: 20px 0;
  width: 100%;
}

button.remove {
  background-color: #dc3545;
}

button.remove:hover {
  background-color: #c82333;
}




/* src/CourseDetails.css */

.course-details {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  .courseDetails .col-md-12 ,  .courseDetails .col-md-6 {
    padding: 0px;
  }
  
  .timeline-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .timeline-bar {
    display: flex;
    overflow-x: auto;
    margin: 0 10px;
    flex-grow: 1;
  }
  
  .timeline-item {
    padding: 5px 20px 0px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #e9ecef;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #ccc;
  }
  
  .timeline-item.updated {
    background-color: #007bff;
    color: #fff;
  }
  
  .timeline-item:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  .scroll-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .scroll-button.left {
    margin-right: 5px;
  }
  
  .scroll-button.right {
    margin-left: 5px;
  }
  
  .scroll-button:hover {
    background-color: #0056b3;
  }
.timeline-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.timeline-bar {
  display: flex;
  overflow-x: auto;
  margin: 0 10px;
  flex-grow: 1;
}

.timeline-item {
  padding: 5px 20px 0px;
  margin-right: 10px;
  cursor: pointer;
  background-color: #e9ecef;
  border-radius: 4px;
  white-space: nowrap;
  border: 1px solid #ccc;
}

.timeline-item.updated {
  background-color: #007bff;
  color: #fff;
}

.timeline-item:hover {
  background-color: #0056b3;
  color: #fff;
}

.scroll-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px; /* Adjust the height to match the height of timeline items */
}

.scroll-button.left {
  margin-right: 5px;
}

.scroll-button.right {
  margin-left: 5px;
}

.scroll-button:hover {
  background-color: #0056b3;
}
  
.timeline-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .timeline-bar {
    display: flex;
    overflow-x: auto;
    margin: 0 10px;
    flex-grow: 1;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .timeline-bar::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  
  .timeline-item {
    padding: 5px 20px 0px;
    margin-right: 10px;
    cursor: pointer;
    background-color: #e9ecef;
    border-radius: 4px;
    white-space: nowrap;
    border: 1px solid #ccc;
  }
  
  .timeline-item.updated {
    background-color: #007bff;
    color: #fff;
  }
  
  .timeline-item:hover {
    background-color: #0056b3;
    color: #fff;
  }
  
  .scroll-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px; 
  }
  
  .scroll-button.left {
    margin-right: 5px;
  }
  
  .scroll-button.right {
    margin-left: 5px;
  }
  
  .scroll-button:hover {
    background-color: #0056b3;
  }
  

  .timeline-item-type {
    font-size: 10px;
    color: #666;
    margin-top: 5px;
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
  }
  .form-container  .col-md-12 {
    margin-bottom: 25px;
}
  .formInputs {
    padding-bottom: 15px;
  }
  .teaxtareas {
   border-radius: 15px;
   height: 100px; 
  }
  .thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
    padding: 20px;
  }
.thumb{
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
  }

  .thumbInner  {
    display: flex;
    min-width: 0px;
    overflow: hidden;
  }
  .btn-outline-primary.btn {
    padding: 3px 5px;
    font-size: 13px;
    border-color: #8bb2b7;
}
a.btn-outline-primary {
    background: #3bbbca;
    color: white;

}
.modifyButtons a.btn-outline-primary {
    width: 140px;    
    font-size: 16px !important;
}
a.btn-outline-primary:hover {
    background: #31a1ae;
}
.modifyButtons {
    text-align: center;
}
.header-nav .nav-item {
    color: white;
}
  label {
    margin-top: 10px;
    font-weight: bold;
  }
  
  input[type="text"], textarea {
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  button {
    padding: 8px 8px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-end;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .loadingMain {
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: 400px;
  }
  .loadingMain .spinner-border-sm {
    width: 3rem;
    height: 3rem;
    border-width: 0.5em;
    color: #3bbbca;
}
.course-title label {
    width: 125px;
    min-width: 125px;
}
 .imgContainer img{
    height: 100%;
    object-fit: contain;
    margin: auto;
    display: block;
} 
.imgContainer .defaultImg {    padding: 0px 20px;}
.imgContainer {
    aspect-ratio: 1.78 / 1;
    background: black;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.courseDetails .col-md-6 {
    display: block;
    margin: auto;
}

.sidebar-nav {
  padding-top: 35px;
  padding-left: 25px;
}
.sidebar-nav .nav-link {
    border-bottom: 1px solid #c0dcdf;
    display: inline-block;
    width: 88%;
    margin-bottom: 14px;
    color: white;
    font-size: 0.92rem;
}
.sidebar-nav .nav-icon {
    margin-right: 5px;
    vertical-align: bottom;
  }
img.nav-icon , img.section-icon {
    filter: invert(1);
}
  .dashboardPage .card.mx-4 ,   .course-structure {
    margin-top: 35px;
    margin-bottom: 35px;
}
  .course-structure h2 {
    text-align: center;
    font-size: 1.5rem;
  }
  .course-details h2 {
    font-size: 1.3rem;
    margin-bottom: 25px;
    display: inline-block;
  }
.timeline-item.completed {
  background: #3bbbca;
  color: white;
}
  .timeline-item.active {
    background: #0056b3;
    color: white;
  }
.active .timeline-item-type , .completed .timeline-item-type {
color: white;
}
button.removeButton2 {
    font-size: 13px;
    padding: 4px 4px;
}
.removeButton3 {
float: right;
}
button.removeButton {
    background: #df0707 !important;
}
button.removeButton:hover {
    background: #b10909 !important;
}
.btn-primary {
    background: #3bbbca !important;
    border-color: #8bb2b7;
}
.btn-primary:hover {
    background: #31a1ae !important;
    border-color: #8bb2b7;
}
.favIconClass {
    position: absolute;
    right: 15px;
    top: 35%;
}
.favIconClass svg , .favIconClass2 svg ,.iconInCourseCard svg {
    width: 22px;
}
.shareButton:hover {
cursor: pointer;
}
.favIconClass svg:hover , .favIconClass2 svg:hover {
    cursor: pointer;
}
.favIconClass2 {
    vertical-align: text-top;
    margin-left: 3px;
}
    @keyframes beatAnimation {
        to {
          transform: scale(1.2);
        }
      }
    .beating {
        cursor: default !important;
        animation: beatAnimation 0.5s infinite alternate;
      }
     .oneLineOnly {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        height: 1rem;
        line-height: 1.06em;
        overflow: hidden;
    }
    .oneLineOnly2 {
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        height: 1.7rem;
        line-height: 1.04em;
        overflow: hidden;
    }
    .oneLineOnly3 {
        line-height: 1.4em;  
    }
   .deleteFav {
        width: 1.6rem !important;
        height: 1.6rem !important;
        color: #f10621;
    }
    .favPage .favIconClass  { display: none; }
    .remove_button {
        position: absolute;
        top: 1px;
        right: 17px;
        z-index: 9;
        padding: 1px;
    }
    .remove_button2 {
        background: #0000006b !important;
        border: none;
    }
    .remove_buttonSpinner {
        top: 8px;
        right: 20px;
    }
    .favPage {
        position: relative;
    }


    .row.profilePageAll {
        margin-top: 35px;
    }
    .profileSideBar .profile-avatar {
    display: block;
    margin: 18px auto 8px;
    }
    .profileSideBar {
        min-height: 500px;
        background: #26bbcd26;
        padding-right: 0px;
        padding-left: 5px;
        border-radius: 10px;
    margin-bottom: 25px;
    }
    .profileSideBarName{
        display: block;
        text-align: center;
        text-transform: capitalize;
        color: black;
        font-size: 17px;
    }
    
    .profileSideBar li , .profileSideBar a{
    border: none !important;
    color: black;
    margin-bottom: 10px;
    background: none;
    }
    a.mainTabWithSub {
        padding: 0.75rem ;
        margin: 0px 0px 10px;
        border-bottom: solid #d4d4d4 1px !important;
        width: 100%;
        display: block;
    }
    .profileSideBar li:hover , .profileSideBar a:hover{
        width: auto;
        border-bottom: none !important;
        text-decoration: none;
        background-color: white;
        color: black;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        }
     
        .list-group-item {
            padding: 0.75rem ;
        }
    .profileSideBar li {
        border-bottom: solid #d4d4d4 1px !important;
        } 
    .profileSideBarLogout {
        border-bottom: none !important;
        margin-top: 20px;
    }
    .profileSideBar .subTab {
        border: none !important;
        display: block;
        padding: 0.75rem 1.25rem;
         width: 140px; 
        /* width: auto; */
        padding-left: 5px;
        margin-left: 30px;
        border-bottom: solid #d4d4d4  1px !important;
        color: black;
    }
    .profilePageAll .card {
        border:none;
    }
    .profilePageAll thead {
        display: none;
    }
    
    .profilePageAll .table-outline, .profilePageAll .table-outline th, .profilePageAll .table-outline td {
        border: none;
    }
    .profilePageAll .table-outline tr .vehicle-thumbnail {
        width: 150px !important;
        height: 150px !important;
    }
    .profilePageAll .table-outline tr td:last-child {
        vertical-align: middle;
    }
    .profilePageAll .table-outline tr td:last-child svg,.profilePageAll .table-outline tr td:last-child img {
        width: 1.3rem;
        height: 1.3rem;
        color: black;
      }
      .profilePageAll .table-outline tr td:last-child button {
        display: block;
      }
      .profileSideBarSelected {
        background-color: white !important;
        width: auto !important;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    .profile-avatar{
        width: 70px;
        height: auto;
    }
    .profileIconLink {
        width: 45px;
    }

    .myItemsLink {
        font-size: 14px;
        width: max-content;
        margin-right: 4px !important;
      }

    .starIcon{
        width: 22px;
        height: 22px;
        cursor: pointer;

    }
    .starIconFilled {
        fill: #ffcd1d;
    }
    .loadingGif {
        text-align: center;
    }
    .loadingGif img {
        width: 85px;
    }
   .fullBannerHome img {
        height: 444px;
        width: 100%;
    }
    .deleteFromCart {
        vertical-align: -webkit-baseline-middle;
        cursor: pointer;
    }
    .deleteFromCart  svg {
        fill: #e20606;
    }
    .deleteFromCart svg:hover {
        fill: #ff0d0d;
    }
    .rdt_TableHead , .rdt_TableRow{
        font-size: 14px !important;
    }
   .btn-outline-primary img {
    filter: invert(1);
        vertical-align: top;
        width: 22px;
    }
    .rdt_Pagination {
        /* margin-top: 27px; */
        padding-top: 25px;
        padding-bottom: 15px;
    }
    .sliderThumb {
        padding: 5px 0px;
        max-height: 60px;
        width: auto;
    }

    .slide-containerL img{
        max-height: 270px;
        margin: auto;
        object-fit: fill;
    }

    .hover-link-container {
        position: relative;

      }
      
      .hover-link {
        text-decoration: none;
        position: relative;
        display: inline-block;
        vertical-align: super;
      }
      
      .hover-text {
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s;
        position: absolute;
        top: -20px; /* Adjust the position as needed */
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: white;
        padding: 5px;
        border-radius: 3px;
        white-space: nowrap;
        z-index: 99;
      }
      
      .hover-link:hover .hover-text {
        visibility: visible;
        opacity: 1;
      }
      .modules .module label {
        width: 100px;
      }
      .modules .module button {
        width: 115px;
      }
      .questionSection{
        border: solid 1px #cccccc;
        padding: 3px 9px;
        border-radius: 6px;
        margin-bottom: 20px;
    }
    .optionSection {
        width: 50%;
        display: inline-block;
        /* display: flex; */
        padding: 4px 14px;
    }
    .optionSection input[type="text"]  {
        width: 72%;
        display: inline-block;
    }
    .optionSection label {
        width: 25%;
        display: inline-block;
        font-size: 12px;
        padding-left: 4px;
    }

    .radio-group {
        display: flex;
      }
      
      .radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        user-select: none;
      }
      
      .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      .checkmark {
        position: absolute;
        top: -3px;
        left: 2px;
        height: 20px;
        width: 20px;
        background-color: #adabab;
        border-radius: 50%;
      }
      
      .radio-container:hover input ~ .checkmark {
        background-color: #ccc;
      }
      
      .radio-container input:checked ~ .checkmark {
        background-color: #2196F3;
      }
      
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }
      
      .radio-container input:checked ~ .checkmark:after {
        display: block;
      }
      
      .radio-container .checkmark:after {
        left: 8px;
        top: 4px;
        width: 6px;
        height: 11px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
      }
      .moduleTestFormDiv p {
        margin-bottom: 0px;
        font-weight: 400;
    }
    .moduleTestFormDiv {
        margin-bottom: 20px;
        border-bottom: 1px solid #b8d8dc;
        padding-bottom: 5px;
    }
    .moduleTestFormDiv label {
        font-weight: 400;
        color: black;
        text-transform: capitalize;
    }
    .moduleTestFormDiv  input{
        margin-right: 2px;
    }
    .moduleTestFormDivAns1 {
        display: inline-block;
        width: 100%;
    }
    .moduleTestFormDivAns1 span {
        font-weight: 500;
    }
    .contentVideoHeading1 {
        text-align: center;
    }
    .videoHeadingButtons{
        position: absolute;
        right: 0px;
        bottom: 15px;
     }
     .videoHeadingButtons span , .submitForm{
         color: white;
         background: #26bbcd;
         width: auto;
         padding: 10px 10px;
         border-radius: 4px;
         font-size: 16px;
         cursor: pointer;
     }
     .submitForm{
        width: 150px;
        font-size: 18px;
        letter-spacing: 0.5px;        
        margin: auto;
        margin-top: 15px;
        display: block;
    }
     .videoHeadingButtons span:hover , .submitForm:hover {
         background: #23a1b0 ;
     }
     .testResults{
        margin-top: 15px;
        margin-bottom: 25px;
     }
     .testResults p {
text-align: center;
font-size: 17px;
font-weight: 400;
margin-bottom: 12px;
     }
     .testResults svg{
        width: 40px;
        vertical-align: middle;
    }
     .testPassed {
        color: green;
     }
     .testFailed {
        color : red;
     }
     a.submitForm {
        width: fit-content;
    }
    a.submitForm:hover {
        color: white;
    }
    .sticky-timer {
        position: -webkit-sticky; /* Safari */
        position: sticky;
        width: fit-content;
        float: right;
        top: 60px;
        right: 5%;
        background-color: #26bbcd87;
        padding: 15px 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        z-index: 1000;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
      }
      .sticky-timer p {
        margin-bottom: 0px;
        font-weight: 500;
      }

    .testTitleDashboard span {
        position: absolute;
        left: 0;
        top: 25px;
        font-size: 11px;
        line-height: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; /* Ensures single-line truncation */
        max-width: -webkit-fill-available;
        max-width: -moz-available;
        padding-right: 10px;
        transition: all 0.3s ease; /* Optional: for a smooth expansion */
      }
      
      /* On hover, expand to show full text */
      .testTitleDashboard span:hover {
        overflow: visible;
        white-space: normal;
        max-width: none;
        z-index: 10;
        background-color: rgb(232, 253, 255);
        padding: 5px;
        border-radius: 6px;           
      }
      

   .testsListDashboard .rdt_TableCell div:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 4px;
        height: 100%;
    }
    .rdt_TableCell{
        height: 55px;
        line-height: 55px !important;
      }
      .testTitleDashboard {
        line-height: normal;
    }
    .testsListDashboard .badge {
        font-size: 0.9em;
        width: 40px;
    }
    .orderCardHeading {
        background: #def5f8;
        padding: 8px 10px;
      }
      .orderCardBody {
        background: #def5f842;
        padding: 20px;
      }
     .nav-iconPng {
        filter: invert(100%) contrast(132%) !important;
}
.rdt_TableCol , .rdt_TableCell{padding-left: 6px !important;
    padding-right: 6px !important;}
 
    .changeLang{
        position: absolute;
        right: -12px;
        top: -18px;
    }
    .hideLangFlag {
        display: none;
    }

    .sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
       /* background: #26bbcd;*/
        padding: 6px;
        z-index: 999;
      }
      .mysticky .l-navbar__top{
        background: none !important;
        box-shadow: none !important;
        padding: 0px !important;
        height: fit-content !important;
      }
     .mysticky nav ul.dropdown {
        margin-top: 1px !important;
      }
      @media (max-width: 768px) {
       .contentVideo video {
        width: 95%;   
    }
    }
    .l-navbar__topics .w-nav-menu{
        float: none;
    }
    .stickyFloatRight{
        color: white;
        text-transform: capitalize;
        float: right;
        margin-top: -30px;
    }
    .stickyFloatRight a {
        display: inline-block;
    }
    .c-nav__item , header li {
        list-style:none !important;
    }
    .mysticky {
        transition: opacity 0.3s ease, visibility 0.3s ease;
        opacity: 0;
        visibility: hidden;
      }
      
      .mysticky.hidden {
        opacity: 0;
        visibility: hidden;
      }
      
      .mysticky:not(.hidden) {
        opacity: 1;
        visibility: visible;
      }
      
   .mysticky .img_navlogo {
    height: 45px;
   }
   .mysticky .w_navleftsidelinks {
    height: 40px;
    line-height: 50px;
   }
   .mysticky .w_navleftsidelinks a {
    color: white;
   }
   .mysticky .hover-link-container {
    display: inline-block;
  }
  .changeLangM {
    display: inline-block;
    vertical-align: super;
  }
  .gmtcLogoWrapper{
    background: white;
    border-radius: 50%;
}
span.showLangFlag{
    cursor: pointer;
}
.expandableComponent{
    background: #3bbbca4a;
    padding: 7px;
    border-radius: 5px;
  }
  .sc-ftvSup button {
    margin-left: 0px;
  }
  .eKwPae svg {
    width: 33px;
    height: 33px;
  }

 .course-details textarea {
    height: 150px;
  }
  .sessionSection.disabled {
    color: gray;
  }
  .moduleList.disabled {
    color: gray;
  }
  .attendingList {
    list-style: none;
  }
  .attendingList li label{
    display: inline-block;
  color: black;
  font-size: 14px;
  }  
  .studentTestList {
    border-top: 1px solid white;
    padding-top: 10px;
    margin-top: 15px;
  }
  .attendingList li {
    padding-bottom: 8px;
  }
  .colWithSectionButton {
    display: flex;
    flex-direction: column;
  }
  .registerForm .form-check-label {
margin-top: 0px;
  }
  .successIconGreen {
    max-width: 130px;
  }
 .applyDetails p strong {
    width: 85px;
    display: inline-block;
}
.applyDetails {text-transform: capitalize;}
.applyDetails a {text-decoration: underline;}
.applyDetails .btn {
    font-size: 16px;
    text-transform: capitalize;
    padding: 0.375rem 0.75rem;
}
.applyDetails .btn-danger{
    background: red !important;
}
.applyDetails .btn-danger:hover {background: #cb0505 !important;
}
.sideBarDashboard li {
    margin-left: 25px;
}
.sideBarDashboardWrapper .sidebar-nav {
    padding-left: 15px;
}
.section-icon {  
    flex: 0 0 4rem;
    height: 1.25rem;
    font-size: 1.25rem;
    vertical-align: bottom;
}
img.section-icon {
    filter: invert(1);
}
.sidebar-nav .nav-icon {
    height: 1rem;
}
.sidebar-nav .section-icon {
    color: var(--cui-sidebar-nav-link-icon-color, rgba(255, 255, 255, 0.6));
    text-align: center;
    fill: currentColor;
    transition: inherit;
}
.categoriesTable  .rdt_TableRow {
    height: 100px;
}
.categoriesTable .rdt_TableCell {
    height: 100%;
}
.header-nav li a svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
    font-size: 1.5rem !important;
    vertical-align: middle ;
}
.myItemsLinkWrapper {
    position: relative;  /* Establishes a positioning context for the tooltip */
    display: inline-block;  /* Adjust as needed for layout */
  }
  
  /* Base styles for the tooltip - hidden by default */
  .myItemsLinkWrapper .tooltip {
    position: absolute;
    bottom: 100%;            /* Position above the link */
    left: 50%;               /* Center horizontally relative to the link */
    transform: translateX(-50%);
    visibility: hidden;      /* Hide by default */
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    white-space: nowrap;
    font-size: 12px;
    transition: opacity 0.2s ease-in-out;
    z-index: 10;
    pointer-events: none;    /* Ensure tooltip doesn't interfere with hover */
  }
  
  /* Show tooltip on hover of the wrapper */
  .myItemsLinkWrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  .myItemsLinkWrapper .profileIcon {
    width: 28px;
  }

.contentVideoHeadingH5 {
max-width: 55%;
text-align: justify;
  }
  @media only screen and (max-width: 1200px)  {
.coursesPageWrapper .col-md-3 {
    flex: 0 0 auto;
    width: 33.33333%;
}
  }
  @media only screen and (min-width: 992px){
   .medicalPageMainWrapper .coursesSection {
    margin-top: 40px;
    margin-bottom: 40px;
}
  }
@media only screen and (max-width: 992px) {
    html {
        overflow-x: hidden;
    }
    .s_homeheroMedicalCourses .w_headerimages {
        top: -35px;
    }
    .coursesPageWrapper .col-md-3 {
        flex: 0 0 auto;
        width: 33.33333%;
        padding-left: 5px;
        padding-right: 5px;
    }
    .w_commercial_alternating_cards.cc_text_group_7.coursesPageWrapper {
        margin-right: 0px;
        margin-left: 0px;
        padding-right: 20px;
        padding-left: 20px;
    }
    .fullBanner2 {
        padding: 0px;
    }
    .tl_nav_link.w--current {
        border-bottom: solid 2px #26bbcd !important;
        font-weight: 500 !important;
    }
    .s_homehero {
        height: 1275px;
    }
    .fullBannerHome img {
        height: auto;
    }
    .homeFirstSection {
        height: max-content;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    .homeFirstSection h1 {
        font-size: 3rem;
    }
    .contentVideoHeadingH5 {
        max-width: 90%;
          }
    .takeCourseWrapper {
        margin-top: 0px;
    }
    .d_navrightside {
        margin: auto;
    }
   #nav .d_navrightside {
        margin: auto;
        justify-content: center;
        margin-bottom: 5px;
    }   
    .searchWrapper {
        width: 100%;
        text-align: center;
    }
    .searchWrapper .input {
        width: 95%;
    }
    .searchWrapper .input {
        padding-left: 1.3rem;
    }
    .profileIconLink {
        width: auto;
    }
    .favIconClass2 {
        margin-left: 0px;
    }
    .profileIcon {
        margin-right: 0px;
    }
    .staticLinksMob ul {
        list-style: none;
        column-count: 3;
        padding-left: 0px;
    }
   .staticLinksMob1    {padding-top: 15px;        
    display: flex ;

            justify-content: space-around;
}
.d_navrightside.d_navrightside1 {
    justify-content: space-between !important;
    margin-bottom: 12px !important;
}
.d_navrightside12 .tl_nav_link {
    font-weight: 400;
    padding: 3px 5px;
        border-radius: 5px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        margin-right: 0px; 
}
.staticLinksMob {
    bottom: 5px;
    position: absolute;
}

    .staticLinksMob  .tl_nav_link {font-size: 12px; margin: 0px;font-weight: 400;}
    .nav_menu_contentMob {
        padding-left: 10px;
        padding-top: 15px;
    }
   .nav_menu_contentMob .w_navleftsidelinks1 .tl_nav_link, .nav_menu_contentMob .w_navleftsidelinks1 .tl_nav_link.w--current {
        padding-bottom: 5px;
        padding-top: 5px;
        border-bottom: solid 1px #ceeff3;
        font-weight: 400;
    }
    .s_homeheroMedical .w_homeherotext {
        height: fit-content;
        max-width: 55%;
    }
    .s_homeheroMedical .h_xlarge {
        font-size: 4em;
    }
    .s_homeheroMedical .m_scrollline {
        height: 35px;
        margin-top: 0px;
    }
    .s_homeheroMedical .w_scrollline {
        top: -25px;
    }
    /*.s_homeheroMedical .w_headerimages {
        position: initial;
    }
    #w-node-8226eb520370-3a368f7a {
        align-self: auto;
    }
    .img_heroframe.cc-f5, .img_heroframe.cc-f5Med {
        margin-top: 0px;
    }*/

   /* .s_homeheroMedical .grid-8 {
    margin-top: 0px;
}*/
.s_homeheroMedicalCourses .medicalPageMainWrapper {
    margin: 0px;
}
.presentation_page_medicalNarrow {
    width: 95%;
}
.img_heroframe.cc-f5 {
    margin-top: 2px;
}
.s_homeheroMedical .grid-8 {
    margin-top: 225px;
}
.s_homeheroMedical .w_headerimages {
    margin-top: 100px;
}
#w-node-8226eb520370-3a368f7a {
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2;
    align-self: end;
}
#w-node-e630e9f9e3b4-3a368f7a {
    -ms-grid-row: span 2;
    grid-row-start: span 2;
    -ms-grid-row-span: 2;
    grid-row-end: span 2;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}
.s_homeheroMedicalCourses  .h_xlarge {
    font-size: 3em !important;
}
.s_homeheroMedicalCourses  {
    height: 1150px;
}
}
@media (min-width: 768px) and (max-width: 991px){
    .secondCol {
        vertical-align: 100px;
    }
    .thirdCol {
        vertical-align: top;
    }
    .partnersSection img {
        padding: 0.5rem 1rem;
    }
    .cardsWraper {
        padding-right: 10px;
        padding-left: 10px;
    }
    .coursesSection .col-6 {
        padding: 0px;
    }
}
@media (max-width: 768px) {
    .sharerSection2.show {
        width: fit-content;
        right: auto;
        left: 100px;
    }
    .w_commercial_alternating_cards.cc_text_group_7.registerForm {
        width: 100% !important;
    }
    .registerForm .fsLabel {
        font-size: 16px;
    }
    .container {
        max-width: 730px;
    }
    .courseDetails .accordion-body {
        padding: 0px;
    }
    .courseDetails  .accordion-item:first-of-type .accordion-button {
        padding-left: 10px;
        margin-left: 0px;
    }
    .col-md-6.courseDetailsAccorUl {
        padding-left: 0px !important;
    }
    .col-md-6.courseDetailsMedia {
        padding-left: 0px !important;
        margin-top: 12px;
    }
    .col-md-6.courseDetailsMediaAccor {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .coursePage .col-md-9 , .coursePage .col-md-3{
        padding: 0px;
    }
    .row.coursePage {
        margin: auto;
        width: 100%;
    }
    .row.col-md-12.courseDetails {
        width: 100%;
    }

   .courseCardTraining .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }
    .w_commercial_alternating_cards.cc_text_group_7.coursesPageWrapper {
        display: block;
    }
    .coursesPageWrapper .col-md-3 {
        width: 50%;
    }
    .coursesPageWrapper .row1 {
        width: 100%;
    }
    .newsletterInner {
        padding: 20px 15px;
        width: 90%;
        position: relative;
    }
    .newsletterFrame {
        text-align: initial;
    }
    .newsletterRight {
        margin-right: 10px;
    }
    .newsletterLeft img {
        width: 120px;
        position: absolute;
        top: 0px;
        right: 0px;
    }
     .newsletterRight h5 {
        margin-bottom: 5px;
    } 
    .newsletterRight P {
        margin-bottom: 10px;
    }    
    .newsletterRight button {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
        border-radius:5px;
    }
    .partnersSection img {
        padding: 0.2rem 0.2rem;
    }
    .partnersSection {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
  .coursesSection  .col-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .sectionTitle p {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .firstCol {
        width: 100% !important;
        margin-bottom: 10px;
    }
    .secondCol {
        width: 50%;
    }
    .thirdCol {
        width: 50%;
    }

    .homeFirstSection h1 {
        font-size: 2.4rem;
    }
    .takeCourseWrapper {
        display: block;
    }
    .contentSideBar {
        width: 100%;
        display: flex ;
        overflow-x: scroll;
        margin-bottom: 15px;
    }
    .contentVideo {
        margin-bottom: 30px;
    }
    .videoHeadingButtons {
        display: block;
        text-align: center;
        height: 45px;
        position: initial;
    }
    .sessionSection{
        display: flex;
    }
    .sessionSectionHeading {
        width: 150px;
        padding: 8px;
    }
    .contentSideBar ul {
        display: flex ;
        padding-left: 5px;
        margin: 0px;
    }
    .contentSideBar ul li {
        padding-left: 8px;
        border-right: 1px solid #a5e2ea;
        width: 150px;
    }
    .img_navlogo {
        width: 100px;
    }
}
@media only screen and (max-width: 650px) {
    .courseDetails p {
        font-size: 14px;
    }
    .courseCardTraining {
        padding: 0.5rem 0.3rem;
    }
    .courseCardTraining .price {
        font-size: 12px;
    }

    .cardsWraper {
        padding: 0px;
    }
.myCourseIcon {
    width: 20px;
}
.profileIcon {
    width: 30px;
}
.tl_nav_link {
    margin-bottom: 0px !important;
}
.medicalNavButton {
    margin-bottom: 10px !important;
}
.w_nav {
    padding-top: 5px;
    padding-bottom: 5px;
}
.c_navbar_mobile {
    padding-right: 10px;
    padding-left: 10px;
}
}
@media (max-width: 576px) {
    .s_homehero {
        height: 1200px;
    }
    .s_homeheroMedicalCourses  {
        height: 1120px;
    }
    .s_homeheroMedical .w_headerimages {
        margin-top: 65px;
    }
    .s_homeheroMedical .grid-8 {
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }
    .s_homeheroMedical .grid-6 ,.s_homeheroMedical .grid-7 {
        grid-column-gap: 10px;
        grid-row-gap: 5px;
    }
    .w_headerimages {
        padding-right: 0;
        padding-left: 0
    }
    .profileSideBar {
        min-height: auto;
        margin-bottom: 10px;
    }
    .profileSideBar .profile-avatar {
        margin: 5px auto;
    }
    .profileSideBarName {
        margin-bottom: 0px;
    }
    .profilePageAll .col-sm-10 {
padding-left: 0px;
padding-right: 0px !important;
    }
    .profilePageAll .w_commercial_alternating_cards.cc_text_group_7.coursesPageWrapper {
        padding: 5px;
    }
}
@media only screen and (max-width: 500px) {
    .newsletterFrame {
        margin-top: 50px;
    }
    .newsletterLeft img {
        top: -60px;
        right: -10px;
    }
    .newsletterRight {
        width: 80%;
        z-index: 9;
        position: relative;
    }
    .newsletterRight h3 {
        font-size: 1.5em;
        max-width: 90%;
    }

}
@media only screen and (max-width: 479px) {
    .s_homehero {
        height: 792px;
    }
    .s_homeheroMedicalCourses {
        height: 720px;
    }
    .s_homeheroMedicalCourses .h_xlarge {
        font-size: 2.3em !important;
    }
    .s_homeheroMedical .grid-8 {
        margin-top: 165px;
    }
    .s_homeheroMedical .w_headerimages {
        margin-top: 110px;
    }
    .registerForm .fsLabel , .registerForm .form-check-label  {
        font-size: 14px;
    }
    .registerForm .fsLabel .sp_beige_text {
        font-size: 12px;
    }
    
}
@media only screen and (min-width: 430px) {
    .partnersSection .col-6 {
         flex: 0 0 auto;
         width: 16.66666667%;
     }
    }
@media only screen and (max-width: 430px) {
    .partnersSection .col-6 {
        flex: 0 0 auto;
        width: 25%;
        margin: auto;
    }
    .coursesSection .row {
        padding-right: 15px;
        padding-left: 15px;
    }
    .itemsRow .col-6 {
        padding-right: 5px;
        padding-left: 5px;
    }
    .cardBottomRow div {
        margin-right: 5px;
        padding-right: 5px;
    }
    .cardBottomRow12 {
        padding-right: 5px;
        padding-left: 5px;
        text-align: center;
    }    
    .cardBottomRow {
        font-size: 11px;
    }
    .itemsRow .pb-4 {
        padding: 0.5rem !important;
         padding-top: 0.5rem !important; 
    }
    .videoHeadingButtons span, .submitForm {
        padding: 8px 5px;
        font-size: 14px;
    }
    .img_navlogo {
        width: 75px;
    }
    .profileIcon {
        width: 25px !important;
    }
    .d_navrightsideMob svg {
        width: 18px;
    }
    .flag {
        width: 22px;
        max-width: 22px;
    }
    }
    @media (min-width: 390px) and (max-width: 650px) {
        .courseCardTraining .row {
            display: contents;
        }
        .courseCardTraining .col-md-4 {
            width: 50%;
            display: inline-block;
        }
    }
 @media only screen and (max-width: 390px) {
    .coursesPageWrapper .col-md-3 {
        width: 100%;
    }
    }
    @media only screen and (max-width: 350px)  {
    .registerForm .fsLabel {
        font-size: 13px;
    }
    .registerForm .fsLabel .sp_beige_text {
        font-size: 11px;
    }
}
.hideMenuMob {
    display: none;
}